/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import {
  CompanyType,
  Fuel,
  Insurance,
  LegacyDateTimeRangeInput,
  Maybe,
  PostIssuesOrder,
  PostIssueStatus,
  ProductQualityLevel,
  ReactionType,
  TimeRange,
} from './client';
import { isContact, isPhoneNumber } from './validator';

require('dayjs/locale/ko');
dayjs.locale('ko');
dayjs.extend(relativeTime);

export type Badge = {
  text: string;
  color: string | undefined;
};

export const statusToBadgeInfo = (status?: PostIssueStatus) => {
  if (status === PostIssueStatus.ToDo) {
    return {
      text: '미답변',
      color: 'red',
    };
  } else if (status === PostIssueStatus.InProgress) {
    return {
      text: '답변필요',
      color: 'magenta',
    };
  } else {
    return {
      text: '답변완료',
      color: 'blue',
    };
  }
};

export const reactionHandler = (reactionType: ReactionType) => {
  if (reactionType === ReactionType.Afraid) {
    return '😱';
  } else if (reactionType === ReactionType.Like) {
    return '😍';
  } else if (reactionType === ReactionType.Sad) {
    return '😭';
  } else {
    return '💪';
  }
};

export const tabToStatus = (tab: string | (string | null)[] | null) => {
  if (!tab) {
    return PostIssueStatus.ToDo;
  }

  if (tab === 'todo') {
    return PostIssueStatus.ToDo;
  } else if (tab === 'in-progress') {
    return PostIssueStatus.InProgress;
  } else {
    return PostIssueStatus.Done;
  }
};

export const postsFilterToQueryString = (filter: {
  orderBy: PostIssuesOrder;
  categoryId?: string;
  createdAtRange?: Maybe<LegacyDateTimeRangeInput>;
  status?: PostIssueStatus;
  term?: string;
  nickname?: boolean;
  onlyReplied?: boolean;
  chatOpened?: boolean;
}) => {
  const queryParams: any = {};

  if (filter.createdAtRange) {
    queryParams.from = String(filter.createdAtRange.from);
    queryParams.to = String(filter.createdAtRange.to);
  }

  if (filter.term && filter.term !== '') {
    queryParams.term = filter.term;
  }

  if (filter.categoryId) {
    queryParams.categoryId = filter.categoryId;
  }

  if (filter.orderBy) {
    queryParams.sort = filter.orderBy;
  }

  if (filter.nickname) {
    queryParams.nickname = filter.nickname;
  }

  if (filter.onlyReplied) {
    queryParams.onlyReplied = filter.onlyReplied;
  }

  if (filter.chatOpened) {
    queryParams.chatOpened = filter.chatOpened;
  }

  if (filter.status) {
    if (filter.status === PostIssueStatus.ToDo) {
      queryParams.tab = 'todo';
    } else if (filter.status === PostIssueStatus.InProgress) {
      queryParams.tab = 'in-progress';
    } else if (filter.status === PostIssueStatus.Done) {
      queryParams.tab = 'done';
    }
  }

  return new URLSearchParams(queryParams).toString();
};

export const fromNow = (time: number) => {
  return dayjs(time).fromNow();
};

export const inquiryTypeFormatter = (name: string): Badge | undefined => {
  switch (name) {
    case '사고수리':
      return {
        text: name,
        color: 'red',
      };
    case '정비/부품':
      return {
        text: name,
        color: 'blue',
      };
    case '타이어':
      return {
        text: name,
        color: 'orange',
      };
    case '배터리':
      return {
        text: name,
        color: 'volcano',
      };
    case '견적서 조언받기':
      return {
        text: name,
        color: 'magenta',
      };
    case '기타':
      return {
        text: name,
        color: 'cyan',
      };
    default:
      return undefined;
  }
};

export const fuelTypeFormatter = (fuelType: {
  base: Fuel;
  hybrid: boolean;
}) => {
  let string;

  switch (fuelType.base) {
    case Fuel.Diesel:
      string = '디젤';
      break;
    case Fuel.Electricity:
      string = '전기';
      break;
    case Fuel.Gasoline:
      string = '휘발유';
      break;
    case Fuel.Hydrogen:
      string = '수소 전지';
      break;
    case Fuel.Lpg:
      string = 'LPG';
      break;
    case Fuel.Extra:
      string = '기타';
      break;
  }

  if (fuelType.hybrid) {
    string += ` 하이브리드`;
  }

  return string;
};

export const insuranceFormatter = (insurance: Insurance) => {
  switch (insurance) {
    case Insurance.None:
      return '자비처리';
    case Insurance.Cdw:
      return '자차보험';
    case Insurance.Lp:
      return '대물보험';
  }
};

export const companyTypeFormatter = (companyType: CompanyType) => {
  switch (companyType) {
    case CompanyType.CarCenter:
      return '정비소';
    case CompanyType.RepairFactory:
      return '정비공장';
    case CompanyType.RepairFactoryGeneral:
      return '정비공장/일반정비';
    case CompanyType.ServiceCenter:
      return '서비스센터';
    case CompanyType.PartsDistributor:
      return '부품 대리점';
    case CompanyType.Etc:
      return '기타 업체';
    default:
      return companyType;
  }
};

export const companyTypenameFormatter = (
  companyType:
    | 'CarCenter'
    | 'RepairFactory'
    | 'RepairFactoryGeneral'
    | 'ServiceCenter'
    | 'Etc'
) => {
  switch (companyType) {
    case 'CarCenter':
      return '정비소';
    case 'RepairFactory':
      return '정비공장';
    case 'RepairFactoryGeneral':
      return '정비공장/일반정비';
    case 'ServiceCenter':
      return '서비스센터';
    case 'Etc':
      return '기타 업체';
  }
};

export const contactFormatter = (contact: string): string => {
  if (isPhoneNumber(contact)) {
    const handled = contact
      .replace(/[^0-9]/g, '')
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
      .replace(/(-{1,2})$/g, '');
    return handled;
  } else if (isContact(contact)) {
    return contact
      .replace(/[^0-9]/g, '')
      .replace(
        /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
        '$1-$2-$3'
      )
      .replace('--', '-');
  } else {
    return contact;
  }
};

export const timeFormatter = (time: string) => {
  if (time.length !== 4) {
    return time;
  }

  let hour = time.substring(0, 2);
  if (hour.startsWith('0')) {
    hour = hour[1];
  }
  const min = time.substring(2, 4);
  return `${hour}:${min}`;
};

export const timeRangeToMomentRange = (
  timeRange: TimeRange
): [Dayjs, Dayjs] => {
  const from = timeRange.from;
  const to = timeRange.to;

  const fromHour = from.hour.toString().padStart(2, '0');
  const fromMin = from.minute.toString().padStart(2, '0');

  const toHour = to.hour.toString().padStart(2, '0');
  const toMin = to.minute.toString().padStart(2, '0');

  return [
    dayjs(`${fromHour}:${fromMin}`, 'HH:mm'),
    dayjs(`${toHour}:${toMin}`, 'HH:mm'),
  ];
};

export const momentRangeToTimeRange = ([fromDayjs, toDayjs]: [
  Dayjs,
  Dayjs
]): TimeRange => {
  const from = {
    hour: fromDayjs.hour(),
    minute: fromDayjs.minute(),
    second: fromDayjs.second(),
  };

  const to = {
    hour: toDayjs.hour(),
    minute: toDayjs.minute(),
    second: toDayjs.second(),
  };

  return {
    from,
    to,
  };
};

export const timeRangeFormatter = (timeRange: TimeRange) => {
  const from = timeRange.from;
  const to = timeRange.to;

  const fromHour = from.hour.toString();
  const fromMin = from.minute.toString().padEnd(2, '0');

  const toHour = to.hour.toString();
  const toMin = to.minute.toString().padEnd(2, '0');

  return `${fromHour}:${fromMin} ~ ${toHour}:${toMin}`;
};

export const dateFormatter = (milisecond: number) => {
  const date = new Date(milisecond);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year - 2000}. ${month}. ${day}`;
};

export const businessNumberFormatter = (businessNumber: string) => {
  return businessNumber
    .replace(/[^0-9]/g, '')
    .replace(/^(\d{0,3})(\d{0,2})(\d{0,6})$/g, '$1-$2-$3')
    .replace(/(-{1,2})$/g, '');
};

export const tagsFormatter = (name: string): Badge | undefined => {
  switch (name) {
    case '일반 부품 구매':
      return {
        text: name,
        color: 'gold',
      };
    case '자비처리':
      return {
        text: '자비처리',
        color: 'cyan',
      };
    case '자차보험':
      return {
        text: '자차보험',
        color: 'cyan',
      };
    case '대물보험':
      return {
        text: '대물보험',
        color: 'cyan',
      };
    case '외장':
    case '사고수리':
      return {
        text: name,
        color: 'purple',
      };
    case '정비/부품':
      return {
        text: name,
        color: 'blue',
      };
    case '타이어':
      return {
        text: name,
        color: 'green',
      };
    case '배터리':
      return {
        text: name,
        color: 'volcano',
      };
    case '소모품':
    case '소모품구매':
    case '소모품 구매':
    case '엔진오일':
    case '엔진오일세트':
    case '엔진오일필터':
    case '에어필터':
    case '오일필터':
    case '미션오일':
    case '미션오일필터':
    case '브레이크오일':
    case '브레이크오일필터':
    case '브레이크패드':
    case '브레이크디스크':
    case '앞바퀴':
    case '뒷바퀴':
    case '에어컨필터':
    case '연료필터':
    case '외부벨트':
    case '베어링세트':
    case '워터펌프':
    case '오일펌프':
    case '타이밍벨트':
    case '엔진마운트':
    case '미션마운트':
    case 'HID전구':
    case '점화플러그':
    case '점화코일':
      return {
        text: name,
        color: 'red',
      };
    default:
      return {
        text: name,
        color: 'default',
      };
  }
};

export const productQualityLevelFormatter = (
  quality?: ProductQualityLevel | null
): Badge | undefined => {
  switch (quality) {
    case ProductQualityLevel.Best:
      return { text: '최고품질', color: 'green' };
    case ProductQualityLevel.Practical:
      return { text: '실속', color: 'gold' };
    default:
      return { text: '정보없음', color: 'default' };
  }
};
