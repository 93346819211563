import { Select, Input, Button, notification, Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';

type ReportModalPresenterProps = {
  target?: 'post' | 'user' | 'comment';
  id?: string;
  loading: boolean;
  reportPost: (postId: string, reason: string) => Promise<void>;
  reportUser: (userId: string, reason: string) => Promise<void>;
  reportComment: (commentId: string, reason: string) => Promise<void>;
};

const ReportModalPresenter: React.FC<ReportModalPresenterProps> = ({
  target,
  id,
  reportPost,
  reportUser,
  loading,
  reportComment,
}) => {
  const [reason, setReason] = React.useState<string | undefined>(undefined);
  const [openTextarea, setOepnTextarea] = React.useState(false);
  const [textAreaValue, setTextAreaValue] = React.useState<string | undefined>(
    undefined
  );

  React.useEffect(() => {
    setReason('사용자를 비방하거나 배제하는 글이에요');
  }, []);
  const handleChangeReason = (value: string) => {
    if (value === 'openTextarea') {
      setReason(value);
      setOepnTextarea(true);
    } else {
      setReason(value);
      setOepnTextarea(false);
    }
  };

  const handleClickReport = () => {
    let reasonValue = reason;
    if (!id || !target) {
      notification.error({ message: '신고 대상 지정에 오류가 있습니다.' });
      return;
    }

    if (reason === 'openTextarea') {
      reasonValue = textAreaValue;
    }

    if (!reasonValue) {
      notification.error({ message: '신고이유 선택해주세요.' });
      return;
    }

    if (target === 'user') {
      reportUser(id, reasonValue);
    } else if (target === 'post') {
      reportPost(id, reasonValue);
    } else if (target === 'comment') {
      reportComment(id, reasonValue);
    }
  };

  return (
    <Container>
      <p>신고 이유(필수)</p>
      <Select
        onChange={handleChangeReason}
        style={{ marginBottom: 16 }}
        value={reason}
      >
        <Select.Option value={'사용자를 비방하거나 배제하는 글이에요'}>
          사용자를 비방하거나 배제하는 글이에요
        </Select.Option>
        <Select.Option value={'차별/혐오 표현이 사용되었어요'}>
          차별/혐오 표현이 사용되었어요
        </Select.Option>
        <Select.Option value={'성적 행위와 관련된 글이에요'}>
          성적 행위와 관련된 글이에요
        </Select.Option>
        <Select.Option value={'거짓 정보에요'}>거짓 정보에요</Select.Option>
        <Select.Option value={'openTextarea'}>
          신고 내용 직접 작성하기
        </Select.Option>
      </Select>
      {openTextarea && (
        <>
          <p>직접 작성</p>
          <Input.TextArea
            placeholder="신고 이유를 작성해 주세요."
            style={{ height: 164 }}
            value={textAreaValue}
            onChange={(e) => setTextAreaValue(e.target.value)}
          />
        </>
      )}
      <Footer>
        {loading ? (
          <Spin />
        ) : (
          <Button type="primary" onClick={handleClickReport}>
            신고하기
          </Button>
        )}
      </Footer>
    </Container>
  );
};

export default ReportModalPresenter;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Footer = styled.div`
  height: 32px;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;
