import { gql } from '@apollo/client';
import { notification } from 'antd';
import React from 'react';

import { useMeQuery } from 'src/utils/client';

gql`
  query me {
    me {
      id
      avatar {
        url
      }
      email
      nickname
      role
    }
  }
`;

export const useMe = () => {
  const token = localStorage.getItem('token');
  const { data, loading, refetch } = useMeQuery({
    skip: !token,
    onError: (error) => {
      notification.error({
        message: '유저 정보 확인 중 문제가 발생했습니다.',
        description: error.message,
      });
    },
  });

  const refetchWithReload = () => {
    refetch();
    location.reload();
  };

  const clearMe = () => {
    localStorage.removeItem('token');
    location.reload();
  };

  return {
    me: data?.me || null,
    clearMe,
    loading,
    refetch: refetchWithReload,
  };
};
