import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Button,
  Divider,
  Layout,
  Menu,
  Popconfirm,
  Space,
  Tag,
  theme,
  Tooltip,
  Typography,
} from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import packageJson from '../../../package.json';

import { useMe } from 'src/hooks/useMe';
import { RoleEnum } from 'src/utils/client';
import { useDarkMode } from 'src/utils/providers';

export const NAVIGATION_WIDTH = 208;

const roleTag = (role: RoleEnum) => {
  switch (role) {
    case RoleEnum.Admin:
      return <Tag color="purple">관리자</Tag>;
    case RoleEnum.Counselor:
      return <Tag color="blue">메카닉</Tag>;
    case RoleEnum.User:
      return <Tag color="default">사용자</Tag>;
    default:
      return <Tag color="red">???</Tag>;
  }
};

const { Text, Title } = Typography;

const items: ItemType[] = [
  {
    label: '게시물 이슈 목록',
    key: '/community/posts',
  },
  {
    label: '북마크',
    key: '/community/bookmarks',
  },
  {
    label: '업체목록',
    key: '/company/list',
  },
  {
    label: '업체 신규 등록',
    key: '/company/create',
  },
];

const Navigation: React.FC = () => {
  const { me, clearMe } = useMe();
  const navigate = useNavigate();
  const location = useLocation();

  const { dark, toggleTheme } = useDarkMode();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  // check items key and location.pathname and set selectedKeys if it contains
  const selectedKeys = items
    .filter(
      (item) => item?.key && location.pathname.startsWith(item.key as string)
    )
    .map((item) => item?.key) as string[];

  return (
    <Layout.Sider
      style={{
        backgroundColor: colorBgContainer,
        height: '100vh',
      }}
    >
      <Profile>
        <Title level={3}>
          커뮤니티
          <br />
          관리 콘솔
        </Title>
        <Typography.Text>{'v' + packageJson.version}</Typography.Text>
      </Profile>
      <BottomProfile>
        {me && (
          <ProfileWrapper>
            <ProfileContainer>
              <Avatar size={44} icon={<UserOutlined />} src={me.avatar?.url} />
              <ProfileHeader>
                <Tooltip title="유저 권한" placement="right">
                  {roleTag(me.role)}
                </Tooltip>
                <Text strong>{me.nickname}</Text>
              </ProfileHeader>
            </ProfileContainer>
            <Space>
              <Button
                onClick={toggleTheme}
                icon={
                  dark ? (
                    <FontAwesomeIcon icon={faMoon} />
                  ) : (
                    <FontAwesomeIcon icon={faSun} />
                  )
                }
              />
              <Popconfirm onConfirm={clearMe} title="정말 로그아웃하시겠어요?">
                <Button danger icon={<LogoutOutlined />} style={{ flex: 1 }}>
                  로그아웃
                </Button>
              </Popconfirm>
            </Space>
          </ProfileWrapper>
        )}
      </BottomProfile>
      <Divider />
      <div style={{ flex: 1 }}>
        <Menu
          selectedKeys={selectedKeys}
          mode={'inline'}
          onClick={(e) => {
            navigate(e.key);
          }}
          items={items}
        />
      </div>
    </Layout.Sider>
  );
};

const Profile = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const BottomProfile = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 6px;
  margin-bottom: 16px;
`;

const ProfileHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
`;

const ProfileWrapper = styled.div``;

export default Navigation;
