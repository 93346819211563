let env: Env | undefined = undefined;
class Env {
  public API_URL = '';
  public WS_URL = '';
  public SENTRY_URL = '';
  public COUNSEL_CONSOLE_URL: string | undefined;
  public MAP_CONSOLE_URL: string | undefined;
  public WEB_URL: string | undefined;
  private constructor() {
    const env = process.env;
    if (!env.REACT_APP_API_HTTP_ENDPOINT || !env.REACT_APP_API_WS_ENDPOINT) {
      throw new Error('Enviromnents is not setted!');
    }
    this.API_URL = env.REACT_APP_API_HTTP_ENDPOINT;
    this.WS_URL = env.REACT_APP_API_WS_ENDPOINT;
    this.COUNSEL_CONSOLE_URL = env.REACT_APP_COUNSEL_CONSOLE_URL;
    this.MAP_CONSOLE_URL = env.REACT_APP_MAP_CONSOLE_URL;
    this.WEB_URL = env.REACT_APP_WEB_URL;

    if (env.REACT_APP_SENTRY_URL) {
      this.SENTRY_URL = env.REACT_APP_SENTRY_URL;
    }
  }

  public static getInstance() {
    if (env) {
      return env;
    }
    env = new Env();
    return env;
  }
}

export default Env.getInstance();
