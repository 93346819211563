import {
  CloseOutlined,
  GiftOutlined,
  PaperClipOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  Image,
  Input,
  notification,
  Popconfirm,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import React from 'react';
import styled from 'styled-components';

import CompanyCard from '../cards/CompanyCard';
import ProductCard from '../cards/ProductCard';
import CompanySearchModal from '../modals/CompanySearchModal';
import ProductSearchModal from '../modals/ProductSearchModal';

import FilePreview from './FilePreview';

import Enter from 'src/assets/icons/enter.svg';

type CommentInputProps = {
  replyTargetComment: { id: string; nickname: string; body: string } | null;
  clearReplyAnswerTarget: () => void;
  value?: string;
  onChange: (value: string) => void;
  onClickSend: (
    files: FileWithPreview[],
    companyIds: string[],
    productIds: string[]
  ) => Promise<boolean>;
  sending: boolean;
  postId: string;
};

export type FileWithPreview = {
  file: File;
  preview: string;
};

const { Text } = Typography;

const CommentInput: React.FC<CommentInputProps> = ({
  replyTargetComment,
  clearReplyAnswerTarget,
  onChange,
  value,
  onClickSend,
  sending,
  postId,
}) => {
  const inputFileRef = React.useRef<HTMLInputElement | null>(null);
  const textAreaRef = React.useRef<HTMLTextAreaElement | null>(null);
  const [pastedFile, setPastedFile] = React.useState<File | null>(null);
  const [filePreviews, setFilePreviews] = React.useState<FileWithPreview[]>([]);
  const [selectedCompanyIds, setSelectedCompanyIds] = React.useState<string[]>(
    []
  );
  const [selectedProductIds, setSelectedProductIds] = React.useState<string[]>(
    []
  );
  const [replyAnswerVisible, setReplyAnswerVisible] = React.useState(false);
  const [openShopSearchModal, setOpenShopSearchModal] = React.useState(false);
  const [openProductSearchModal, setOpenProductSearchModal] =
    React.useState(false);

  React.useEffect(() => {
    if (!replyTargetComment) {
      if (replyAnswerVisible) {
        setReplyAnswerVisible(false);
      }
    } else {
      if (!replyAnswerVisible) {
        setReplyAnswerVisible(true);
        textAreaRef.current?.focus();
      }
    }
  }, [replyTargetComment, replyAnswerVisible]);

  const handleChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const files = e.target.files;

    if (!files) {
      return;
    }

    const handleFilesAsync = Array.from(files).map((file) => {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onload = () => resolve({ preview: reader.result, file: file });
        reader.readAsDataURL(file);
      });
    });

    const result = (await Promise.all(handleFilesAsync)) as FileWithPreview[];
    setFilePreviews([...filePreviews, ...result]);
  };

  const handleClosePreview = (selected: string) => {
    const filtered = filePreviews.filter((file) => file.preview !== selected);
    setFilePreviews(filtered);
  };

  const handleCloseReplyAnswer = () => {
    clearReplyAnswerTarget();
  };

  const handleClickSend = async () => {
    const result = await onClickSend(
      filePreviews,
      selectedCompanyIds,
      selectedProductIds
    );
    if (result) {
      setFilePreviews([]);
      setSelectedCompanyIds([]);
      setSelectedProductIds([]);
    }
  };

  const handlePaste: React.ClipboardEventHandler<HTMLTextAreaElement> = async (
    event
  ) => {
    if (event.clipboardData.files.length) {
      const file = event.clipboardData.files[0];
      const type = file.type.split('/')[0];
      if (!['image', 'video'].includes(type)) {
        notification.error({
          message: `'${file.type}'은 지원하지 않는 형식의 파일입니다.`,
        });
        return;
      }

      setPastedFile(file);
      event.stopPropagation();
    }
  };

  const cancelSendPastedContent = () => {
    setPastedFile(null);
  };

  const sendPastedContent = async () => {
    if (pastedFile) {
      const reader = new FileReader();
      const handleFile = new Promise((resolve) => {
        reader.onload = () =>
          resolve({ preview: reader.result, file: pastedFile });
        reader.readAsDataURL(pastedFile);
      });

      const result = (await handleFile) as FileWithPreview;
      setFilePreviews([...filePreviews, result]);
      setPastedFile(null);
    }
  };

  return (
    <Container>
      <InputBox>
        {replyAnswerVisible && (
          <>
            <ReplyAnswerTopBox>
              <Text>{replyTargetComment?.nickname}</Text>
              <Button
                type="text"
                onClick={handleCloseReplyAnswer}
                icon={<CloseOutlined />}
              />
            </ReplyAnswerTopBox>
            <Text ellipsis type="secondary">
              {replyTargetComment?.body}
            </Text>
            <Divider />
          </>
        )}
        <Popconfirm
          title={
            <Space direction="vertical">
              <div>이 사진/비디오를 전송하시겠습니까?</div>
              {pastedFile && (
                <Image
                  width={200}
                  height={200}
                  src={URL.createObjectURL(pastedFile)}
                />
              )}
            </Space>
          }
          open={!!pastedFile}
          onConfirm={sendPastedContent}
          onCancel={cancelSendPastedContent}
          placement="topLeft"
          okText="전송"
          cancelText="취소"
        >
          <TextAreaBox>
            {replyAnswerVisible && (
              <ReplyAnswerBox>
                <EnterIcon src={Enter} />
              </ReplyAnswerBox>
            )}
            <Input.TextArea
              size="large"
              placeholder="댓글을 입력하세요."
              autoSize={{ maxRows: 10 }}
              onChange={handleChangeComment}
              onPaste={handlePaste}
              value={value}
              ref={textAreaRef}
              allowClear
            />
          </TextAreaBox>
        </Popconfirm>
        {filePreviews.length > 0 && (
          <FileBox>
            {filePreviews.map((file, index) => (
              <FilePreview
                key={index}
                src={file.preview}
                type={file.file.type.startsWith('image') ? 'image' : 'video'}
                onClose={handleClosePreview}
              />
            ))}
          </FileBox>
        )}
        {selectedCompanyIds.length > 0 && (
          <ShopCardsBox>
            {selectedCompanyIds.map((companyId) => (
              <CompanyCard
                key={companyId}
                companyId={companyId}
                onClose={() => {
                  setSelectedCompanyIds(
                    selectedCompanyIds.filter(
                      (selectedCompanyId) => selectedCompanyId !== companyId
                    )
                  );
                }}
              />
            ))}
          </ShopCardsBox>
        )}
        {selectedProductIds.length > 0 && (
          <ProductCardBox>
            {selectedProductIds.map((productId) => (
              <ProductCard
                key={productId}
                productId={productId}
                onClose={() => {
                  setSelectedProductIds(
                    selectedProductIds.filter(
                      (selectedProductId) => selectedProductId !== productId
                    )
                  );
                }}
                style={{ width: 400 }}
              />
            ))}
          </ProductCardBox>
        )}
        <ButtonBox>
          <Button.Group>
            <input
              multiple
              onChange={handleChangeFile}
              ref={inputFileRef}
              type="file"
              style={{ display: 'none' }}
            />
            <Button
              size="large"
              icon={<PaperClipOutlined />}
              onClick={() => {
                inputFileRef.current?.click();
              }}
            />
            <Tooltip title="업체 검색">
              <Button
                size="large"
                icon={<ShopOutlined />}
                onClick={() => {
                  setOpenShopSearchModal(true);
                }}
              />
            </Tooltip>
            <Tooltip title="상품 검색">
              <Button
                size="large"
                icon={<GiftOutlined />}
                onClick={() => {
                  setOpenProductSearchModal(true);
                }}
              />
            </Tooltip>
          </Button.Group>
          <Button
            size="large"
            type="primary"
            loading={sending}
            onClick={handleClickSend}
          >
            전송
          </Button>
        </ButtonBox>
      </InputBox>
      <CompanySearchModal
        postId={postId}
        open={openShopSearchModal}
        onClose={() => setOpenShopSearchModal(false)}
        onSelectCompanies={(companyIds) => {
          setSelectedCompanyIds([...selectedCompanyIds, ...companyIds]);
          setOpenShopSearchModal(false);
        }}
      />
      <ProductSearchModal
        open={openProductSearchModal}
        onCancel={() => setOpenProductSearchModal(false)}
        onSelectProducts={(productIds) => {
          setSelectedProductIds(productIds);
          setOpenProductSearchModal(false);
        }}
      />
    </Container>
  );
};

export default CommentInput;

const Container = styled.div`
  padding: 8px;
`;

const ReplyAnswerTopBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InputBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 12px;
`;

const ReplyAnswerBox = styled.div`
  width: 20px;
  margin-right: 8px;
`;

const EnterIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const TextAreaBox = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const FileBox = styled.div`
  height: 90px;
  flex: 0 0 90px;
  overflow-x: auto;
  display: flex;
  gap: 22px;
  margin-bottom: 8px;
`;

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ShopCardsBox = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ProductCardBox = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
