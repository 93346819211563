import { gql } from '@apollo/client';
import { message, notification } from 'antd';
import React from 'react';

import PostCardPresenter from './PostCardPresenter';

import { useMe } from 'src/hooks/useMe';
import {
  useBookmarkPostIssueMutation,
  useChangePostIssueToDoneMutation,
  useCreateCommentMutation,
  useCreateCounselMutation,
  useRequestStoreReviewForPostMutation,
  useUnbookmarkPostIssueMutation,
  CreateCounselRefetchDocument,
  useAddUserToCounselMutation,
  AreaInput,
  useUpdatePostIssueMutation,
} from 'src/utils/client';
import { openCounselWindow } from 'src/utils/providers';

gql`
  mutation bookmarkPostIssue($postIssueId: ID!) {
    bookmarkPostIssue(postIssueId: $postIssueId) {
      ... on BookmarkPostIssueSuccess {
        postIssue {
          id
          bookmarked
        }
      }
    }
  }
`;

gql`
  mutation unbookmarkPostIssue($postIssueId: ID!) {
    unbookmarkPostIssue(postIssueId: $postIssueId) {
      ... on UnbookmarkPostIssueSuccess {
        postIssue {
          id
          bookmarked
        }
      }
    }
  }
`;

gql`
  mutation updatePostIssue($postIssueId: ID!, $data: UpdatePostIssueData!) {
    updatePostIssue(postIssueId: $postIssueId, data: $data) {
      ... on UpdatePostIssueSuccess {
        postIssue {
          id
          memo
        }
      }
      ... on UpdatePostIssueFail {
        error {
          message
        }
      }
    }
  }
`;

gql`
  mutation requestStoreReviewForPost($postId: ID!) {
    requestStoreReviewForPost(postId: $postId)
  }
`;

gql`
  mutation changePostIssueToDone($postIssueId: ID!) {
    changePostIssueToDone(postIssueId: $postIssueId) {
      ... on ChangePostIssueToDoneSuccess {
        postIssue {
          id
          status
        }
      }

      ... on ChangePostIssueToDoneFail {
        error {
          message
        }
      }
    }
  }
`;

gql`
  mutation createCounsel($data: CreateCounselData!) {
    createCounsel(data: $data) {
      ... on CreateCounselFail {
        error {
          message
        }
      }
      ... on CreateCounselSuccess {
        counsel {
          id
          counselee {
            id
          }
        }
      }
    }
  }
`;

gql`
  mutation addUserToCounsel($counselId: ID!, $userId: ID!) {
    addUserToCounsel(counselId: $counselId, userId: $userId) {
      ... on AddUserToCounselSuccess {
        counsel {
          id
        }
        user {
          id
        }
      }
    }
  }
`;

gql`
  query createCounselRefetch($postId: ID!) {
    post(postId: $postId) {
      id
      issue {
        id
        status
        bookmarked
      }
      counsels {
        id
      }
      commentConnection {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

export type PostCardContainerProps = {
  postIssueId: string;
  opened: boolean;
  onClick?: () => void;
  border?: boolean;
  cardRef?: React.RefObject<HTMLDivElement>;
  onClickOpenReportModal: (reportTarget: {
    target: 'post' | 'user';
    id: string;
  }) => void;
  onClickOpenUserPostHistoryModal: (userId: string, nickname: string) => void;
  lastCommentShow?: boolean;
  commentManager?: boolean;
};

const PostCardContainer: React.FC<PostCardContainerProps> = (props) => {
  const [bookmarkMutation] = useBookmarkPostIssueMutation();
  const [unbookmarkMutation] = useUnbookmarkPostIssueMutation();
  const [requestStoreReviewMutation] = useRequestStoreReviewForPostMutation();
  const [changePostIssueToDoneMutation] = useChangePostIssueToDoneMutation();
  const [updatePostIssueMutation] = useUpdatePostIssueMutation();
  const [createCounselMutation] = useCreateCounselMutation();
  const [createCommentMutation] = useCreateCommentMutation();
  const [addUserToCounselMutation] = useAddUserToCounselMutation();

  const { me } = useMe();

  const bookmark = (postIssueId: string) => {
    bookmarkMutation({
      variables: {
        postIssueId,
      },
      onCompleted: (data) => {
        if (
          data?.bookmarkPostIssue?.__typename === 'BookmarkPostIssueSuccess'
        ) {
          message.success('북마크가 추가되었습니다.');
        } else {
          message.error('북마크 추가를 실패했습니다.');
        }
      },
      onError: (error) => {
        message.error('북마크 추가 도중 오류가 발생했습니다: ' + error.message);
      },
    });
  };

  const unbookmark = (postIssueId: string) => {
    unbookmarkMutation({
      variables: {
        postIssueId,
      },
      onCompleted: (data) => {
        if (
          data?.unbookmarkPostIssue?.__typename === 'UnbookmarkPostIssueSuccess'
        ) {
          message.success('북마크가 해제되었습니다.');
        } else {
          message.error('북마크 해제를 실패했습니다.');
        }
      },
      onError: (error) => {
        message.error('북마크 해제 도중 오류가 발생했습니다: ' + error.message);
      },
    });
  };

  const requestStoreReview = async (postId: string) => {
    try {
      const result = await requestStoreReviewMutation({
        variables: {
          postId,
        },
      });
      if (result) {
        notification.success({
          message: '앱스토어 리뷰요청이 정상적으로 처리되었습니다.',
        });
      } else {
        notification.error({
          message: '앱스토어 리뷰 요청중 오류가 발생했습니다.',
        });
      }
    } catch (err) {
      console.error(err);
      notification.error({
        message: '앱스토어 리뷰 요청중 오류가 발생했습니다.',
      });
    }
  };

  const createCounselwithComment = async (
    postId: string,
    vehicleId: string,
    body: string,
    areas: AreaInput[]
  ) => {
    try {
      if (!me) {
        new Error('로그인이 필요합니다.');
      } else {
        const { data } = await createCounselMutation({
          variables: {
            data: {
              postId,
              vehicleId,
              areas,
            },
          },
        });
        if (
          data?.createCounsel.__typename === 'CreateCounselSuccess' &&
          data.createCounsel.counsel?.counselee?.id
        ) {
          await addUserToCounselMutation({
            variables: {
              counselId: data.createCounsel.counsel.id,
              userId: data.createCounsel.counsel.counselee.id,
            },
          });
          await createCommentMutation({
            variables: {
              postId,
              data: {
                body,
                counselId: data.createCounsel.counsel.id,
              },
            },
            refetchQueries: [
              { query: CreateCounselRefetchDocument, variables: { postId } },
            ],
          });
          openCounselWindow(data.createCounsel.counsel.id);
          notification.success({
            message: '상담이 정상적으로 등록되었습니다.',
          });
        } else if (data?.createCounsel.__typename === 'CreateCounselFail') {
          notification.error({
            message: data.createCounsel.error.message,
          });
        } else {
          notification.error({
            message: '상담 등록중 오류가 발생했습니다.',
          });
        }
      }
    } catch (err) {
      console.error(err);
      notification.error({
        message: '상담 생성을 실패했습니다.\n' + JSON.stringify(err),
      });
    }
  };

  const changePostIssueToDone = async (postIssueId: string) => {
    await changePostIssueToDoneMutation({
      variables: {
        postIssueId,
      },
    });
  };

  const updatePostIssue = async (postIssueId: string, memo: string | null) => {
    await updatePostIssueMutation({
      variables: {
        postIssueId,
        data: {
          memo,
        },
      },
    });
  };

  return (
    <PostCardPresenter
      {...props}
      key={props.postIssueId}
      bookmark={bookmark}
      unbookmark={unbookmark}
      requestStoreReview={requestStoreReview}
      changePostIssueToDone={changePostIssueToDone}
      updatePostIssue={updatePostIssue}
      createCounselwithComment={createCounselwithComment}
    />
  );
};

export default PostCardContainer;
