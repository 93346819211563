import { Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Loading: React.FC = () => {
  return (
    <SContainer>
      <Spin size="large" style={{ margin: 40 }} />
      <span>로딩중...</span>
    </SContainer>
  );
};

const SContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default Loading;
