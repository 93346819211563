import { CloseOutlined, ShopOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Avatar, Button, Card, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { useCompanyCardQuery } from 'src/utils/client';

gql`
  query CompanyCard($companyId: ID!) {
    company(companyId: $companyId) {
      id
      name
      images {
        url
      }
      specialties {
        id
        name
      }
    }
  }
`;

type ShopCardProps = {
  companyId: string;
  onClose?: (companyId: string) => void;
  onClick?: (companyId: string) => void;
};

const { Text } = Typography;

const ShopCard: React.FC<ShopCardProps> = ({ companyId, onClose, onClick }) => {
  const { data, loading } = useCompanyCardQuery({
    variables: {
      companyId,
    },
  });

  const companySpecialtiesAsString = data?.company?.specialties
    .map((specialty) => specialty.name)
    .join(', ');

  return (
    <Card
      style={{ width: 400 }}
      size="small"
      loading={loading}
      hoverable
      onClick={() => {
        if (onClick) {
          onClick(companyId);
        } else {
          window.open(`/company/list/detail/${companyId}`);
        }
      }}
    >
      {data?.company && (
        <div style={{ display: 'flex' }}>
          {data.company.images.length > 0 ? (
            <ShopImage src={data.company.images[0].url} />
          ) : (
            <Avatar size={50} icon={<ShopOutlined />} />
          )}
          <ContentBox>
            <Text strong ellipsis>
              {data.company.name}
            </Text>
            <br />
            <Text ellipsis>{companySpecialtiesAsString}</Text>
          </ContentBox>
        </div>
      )}
      {onClose && (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onClose(companyId);
          }}
          type="text"
          icon={<CloseOutlined />}
        />
      )}
    </Card>
  );
};

export default ShopCard;

const ShopImage = styled.div<{ src: string }>`
  width: 50px;
  flex: 0 0 50px;
  height: 50px;
  border-radius: 8px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const ContentBox = styled.div`
  padding-left: 12px;
  flex: 1;
  width: 100px;
`;
