import { InMemoryCache, gql, makeVar } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

import { TypedTypePolicies } from './apollo-helpers';
// import { MessageStatus } from './client';
// import { USER_ID_QUERY } from './sharedQueries';

export const typeDefs = gql`
  extend type Query {
    loggedIn: Boolean!
    searchedCounsels(status: CounselStatus!): [Counsel!]!
    counselMeta(status: CounselStatus!): CounselMeta!
  }
  type CounselMeta {
    total: Int!
    unreads: Int!
  }

  type PressedMessageColumn {
    id: String!
    avatar: String
    text: String
    sendedAt: Int
  }
`;

export const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      loggedIn: {
        read() {
          return loggedInVar();
        },
      },
      companies: {
        read(companies) {
          return companies;
        },
        merge(_, incoming) {
          return incoming;
        },
      },
    },
  },
  Post: {
    fields: {
      commentConnection: relayStylePagination(),
    },
  },
  CommentBase: {
    fields: {
      replyConnection: relayStylePagination(),
    },
  },
};

export const possibleTypes = {
  File: ['Image', 'Video'],
  Message: [
    'AppReviewMessage',
    'AreaInputMessage',
    'BookingMessage',
    'DeletedMessage',
    'EndMessage',
    'SuspendedMessage',
    'EnterMessage',
    'EstimateMessage',
    'FileMessage',
    'InquiryToPostMessage',
    'InvoiceMessage',
    'LeaveMessage',
    'PlateNumberInputMessage',
    'QueueMessage',
    'RestartMessage',
    'ShopMessage',
    'TextMessage',
    'VinInputMessage',
  ],
};

export const loggedInVar = makeVar<boolean>(!!localStorage.getItem('token'));

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies,
});

export default cache;
export type Cache = typeof cache;

export const generateCache = () => {
  return new InMemoryCache({ possibleTypes, typePolicies });
};
