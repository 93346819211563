import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import CommentManager from 'src/components/shared/CommentManager';
import PostIssues from 'src/components/tabs/PostIssues';
import { PostIssuesOrder, usePostIssuesQuery } from 'src/utils/client';
import { tabToStatus, postsFilterToQueryString } from 'src/utils/formatter';

gql`
  query postIssues(
    $filter: PostIssuesFilter!
    $limit: Int!
    $offset: Int!
    $orderBy: PostIssuesOrder!
  ) {
    postIssues(
      filter: $filter
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      id
    }
  }
`;

const LEFT_NAVIGATION_WIDTH = 208;

const PostIssuesTabs = () => {
  const { page } = useParams<{ page: string | undefined }>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const term = searchParams.get('term');
  const categoryId = searchParams.get('categoryId');
  const from = searchParams.get('from');
  const to = searchParams.get('to');
  const sort = searchParams.get('sort');
  const nickname = searchParams.get('nickname');
  const onlyReplied = searchParams.get('onlyReplied');
  const chatOpened = searchParams.get('chatOpened');
  const status = tabToStatus(tab);
  const [opendPostIssueId, setOpenedPostIssueId] = React.useState<
    string | null
  >(null);
  const [pageLimit, setPageLimit] = React.useState(10);
  const [onDrag, setOndrag] = React.useState(false);
  const [listSizeRate, setListSizeRate] = React.useState(50);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const offset = page ? (Number(page) - 1) * pageLimit : 0;

  const fromDayjs = dayjs(Number(from));
  const convertedFrom = fromDayjs
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .valueOf();

  const toDayjs = dayjs(Number(to));
  const convertedTo = toDayjs
    .set('hour', 23)
    .set('minute', 59)
    .set('second', 59)
    .valueOf();
  const { data } = usePostIssuesQuery({
    variables: {
      limit: pageLimit,
      filter: {
        status,
        term: term ? (term as string) : undefined,
        nickname: nickname === 'true',
        onlyIReplied: onlyReplied === 'true',
        categoryIds: categoryId ? ([categoryId] as string[]) : undefined,
        chatOpened: chatOpened === 'true',
        createdAtRange:
          from && to
            ? {
                from: convertedFrom,
                to: convertedTo,
              }
            : undefined,
      },
      offset,
      orderBy: sort ? (sort as PostIssuesOrder) : PostIssuesOrder.CreatedAtDesc,
    },
    skip: !page || !tab,
  });

  React.useEffect(() => {
    if (!page || !tab) {
      navigate('/community/posts/1?tab=todo');
    }
  }, [navigate, page, tab]);

  const handleClickCard = (clickedPostIssueId: string) => {
    if (clickedPostIssueId === opendPostIssueId) {
      setOpenedPostIssueId(null);
    } else {
      setOpenedPostIssueId(clickedPostIssueId);
    }
  };

  const handleChangePage = (page: number) => {
    let url = `/community/posts/${page}`;

    const updatedFilter = {
      term: term ? (term as string) : undefined,
      categoryId: categoryId ? (categoryId as string) : undefined,
      createdAtRange:
        from && to
          ? {
              from: Number(from),
              to: Number(to),
            }
          : undefined,
      orderBy: sort as PostIssuesOrder,
      status,
      nickname: nickname === 'true',
      onlyReplied: onlyReplied === 'true',
      chatOpened: chatOpened === 'true',
    };

    url += `?${postsFilterToQueryString(updatedFilter)}`;

    // new URLSearchParams(a)
    navigate(url);
  };

  const handleSearchTerm = (termInput: string) => {
    const updatedFilter = {
      term: termInput,
      categoryId: categoryId ? (categoryId as string) : undefined,
      createdAtRange:
        from && to
          ? {
              from: Number(from),
              to: Number(to),
            }
          : undefined,
      orderBy: sort as PostIssuesOrder,
      nickname: nickname === 'true',
      onlyReplied: onlyReplied === 'true',
      status,
    };

    navigate(`/community/posts/1?${postsFilterToQueryString(updatedFilter)}`);
  };

  const handleDragResizer = (e: React.DragEvent<HTMLDivElement>) => {
    const clientX = e.clientX;
    const boxClientX = clientX - LEFT_NAVIGATION_WIDTH;

    if (boxClientX <= 0) {
      return;
    }

    if (!containerRef.current) {
      return;
    }

    const containerWidth = containerRef.current.clientWidth;

    const updatedListSizeRatio = (boxClientX / containerWidth) * 100;
    setListSizeRate(updatedListSizeRatio);
  };

  const postIssueIds = data?.postIssues.map((postIssue) => postIssue.id) || [];

  const handleClose = () => {
    setOpenedPostIssueId(null);
  };
  return (
    <Container ref={containerRef}>
      <ListBox
        replyOpened={opendPostIssueId !== null}
        width={listSizeRate}
        dragging={onDrag}
      >
        <PostIssues
          key={'postIssues'}
          postIssueIds={postIssueIds}
          openedPostIssueId={opendPostIssueId}
          onClickCard={handleClickCard}
          onPageChange={handleChangePage}
          onSearch={handleSearchTerm}
          onChangePageLimit={setPageLimit}
          onClose={handleClose}
        />
      </ListBox>
      {opendPostIssueId && (
        <>
          <Resizer
            onDrag={handleDragResizer}
            draggable
            onDragStart={() => setOndrag(true)}
            onDragEnd={() => setOndrag(false)}
          />
          <ReplyBox width={100 - listSizeRate}>
            <CommentManager
              key={opendPostIssueId}
              onClose={handleClose}
              postIssueId={opendPostIssueId}
            />
          </ReplyBox>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  overflow-y: hidden;
  display: flex;
`;

const ListBox = styled.div<{
  replyOpened: boolean;
  width: number;
  dragging: boolean;
}>`
  flex: 0 0 ${(props) => (props.replyOpened ? `${props.width}%` : '100%')};
  ${(props) => (props.dragging ? '' : 'transition: flex 500ms;')}
  display: flex;
`;

const ReplyBox = styled.div<{ width: number }>`
  flex: 0 0 calc(${(props) => props.width}% - 2px);
  z-index: 99;
`;

const Resizer = styled.div`
  flex: 0 0 2px;
  border-left: 2px solid rgba(0, 0, 0, 0.06);
  height: 100%;
  cursor: ew-resize;

  :active {
    cursor: grabbing;
  }
`;

export default PostIssuesTabs;
