import 'antd/dist/reset.css';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import Router from './Router';

import Loading from 'src/components/Loading';
import { useMe } from 'src/hooks/useMe';
import Auth from 'src/pages/Auth';

const App: React.FC = () => {
  const { me, loading } = useMe();

  if (loading) {
    return <Loading />;
  }

  if (me) {
    return (
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    );
  }

  return <Auth />;
};

export default App;
