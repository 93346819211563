import { SaveOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import {
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Switch,
  TimePicker,
  Typography,
  UploadFile,
} from 'antd';
import Title from 'antd/es/typography/Title';
import Upload, { RcFile } from 'antd/lib/upload';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useFileUpload } from 'src/hooks/useFileUpload';
import {
  CreateCompanyInput,
  OperatingTime,
  useCreateCompanyMutation,
  useCompanyRecommendationsQuery,
  useCompanyFacilitiesQuery,
  useCompanyTypesQuery,
  useCompanySpecialtiesQuery,
  HandleNationality,
  usePlacesLazyQuery,
  TimeRange,
} from 'src/utils/client';
import { getBase64 } from 'src/utils/file';
import {
  businessNumberFormatter,
  companyTypeFormatter,
  contactFormatter,
  momentRangeToTimeRange,
  timeRangeToMomentRange,
} from 'src/utils/formatter';
import {
  isContact,
  isKoreanOrAlphabet,
  isPhoneNumber,
  isValidBusinessNumber,
} from 'src/utils/validator';

gql`
  mutation createCompany($data: CreateCompanyInput!) {
    createCompany(data: $data) {
      ... on CreateCompanySuccess {
        company {
          id
        }
      }

      ... on CreateCompanyFail {
        error {
          message
        }
      }
    }
  }
`;

export const DEFAULT_OPERATING_TIME_TIME_RANGE: TimeRange = {
  from: {
    hour: 9,
    minute: 0,
    second: 0,
  },
  to: {
    hour: 18,
    minute: 0,
    second: 0,
  },
};

export const DEFAULT_OPERATING_TIME: OperatingTime = {
  closesOnHoliday: true,
  hoursByDay: {
    SUN: null,
    MON: DEFAULT_OPERATING_TIME_TIME_RANGE,
    TUE: DEFAULT_OPERATING_TIME_TIME_RANGE,
    WED: DEFAULT_OPERATING_TIME_TIME_RANGE,
    TUR: DEFAULT_OPERATING_TIME_TIME_RANGE,
    FRI: DEFAULT_OPERATING_TIME_TIME_RANGE,
    SAT: null,
  },
  remark: undefined,
};

const { Text } = Typography;

const CreateCompanyPage: React.FC = () => {
  const [createCompanyMutation, { loading }] = useCreateCompanyMutation();
  const [placeQuery, { data: placeData }] = usePlacesLazyQuery();
  const { data: companyTypesData } = useCompanyTypesQuery();
  const { data: recommendationsData } = useCompanyRecommendationsQuery();
  const { data: specialtiesData } = useCompanySpecialtiesQuery();
  const { data: facilitiesData } = useCompanyFacilitiesQuery();
  const { upload } = useFileUpload();
  const navigate = useNavigate();

  const [operatingTime, setOperatingTime] = React.useState<OperatingTime>(
    DEFAULT_OPERATING_TIME
  );
  const [previewImage, setPreviewImage] = React.useState('');
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [recommendationIds, setRecommendationIds] = React.useState<string[]>(
    []
  );
  const [facilityIds, setFacilityIds] = React.useState<string[]>([]);

  const [form] = Form.useForm();

  const handleClickPreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const uploadShopPhoto = ({
    file,
    onSuccess,
    onError,
  }: UploadRequestOption) => {
    if (file instanceof File) {
      upload(file)
        .then((res) => {
          onSuccess?.(res, new XMLHttpRequest());
        })
        .catch(onError);
    }
  };

  const handleClickSave = async () => {
    const contact = form.getFieldValue('contact')?.replace(/\D/g, '');
    const extraContact = form.getFieldValue('extraContact')?.replace(/\D/g, '');
    const registrationNumber = form
      .getFieldValue('registrationNumber')
      ?.replace(/\D/g, '');

    const images = form.getFieldValue('images') as UploadFile[];
    const formData: CreateCompanyInput = {
      name: form.getFieldValue('name'),
      isPartner: !!form.getFieldValue('isPartner'),
      certificated: !!form.getFieldValue('certificated'),
      address: form.getFieldValue('address'),
      detailAddress: form.getFieldValue('detailAddress'),
      type: form.getFieldValue('type'),
      bio: form.getFieldValue('bio') || null,
      representativeName: form.getFieldValue('representativeName') || null,
      registrationNumber: registrationNumber || null,
      memo: form.getFieldValue('memo') || null,
      contact: contact,
      extraContact:
        extraContact && extraContact.length > 0 ? extraContact : undefined,
      specialtyIds: form.getFieldValue('specialties'),
      operatingTime: operatingTime,
      imageUrls: images
        ? images
            .map((image) => image.response.url)
            .filter((url): url is string => !!url)
        : [],
      recommendationIds,
      facilityIds,
      handleNationality: form.getFieldValue('handleNationality')
        ? form.getFieldValue('handleNationality')
        : HandleNationality.All,
    };

    if (!formData.name) {
      notification.error({ message: '업체 이름을 입력해주세요' });
      return;
    }

    if (!formData.address) {
      notification.error({ message: '업체 주소를 입력해주세요' });
      return;
    }

    if (!formData.type) {
      notification.error({ message: '업체 유형을 선택해주세요' });
      return;
    }

    if (!formData.contact || formData.contact.length === 0) {
      notification.error({ message: '업체 연락처를 입력해주세요' });
      return;
    }

    if (!formData.handleNationality) {
      notification.error({ message: '취급 차량 국적을 선택하세요.' });
    }

    if (!isContact(formData.contact)) {
      notification.error({ message: '유효하지 않은 연락처입니다.' });
      return;
    }

    if (
      formData.extraContact &&
      !isContact(formData.extraContact) &&
      !isPhoneNumber(formData.extraContact)
    ) {
      notification.error({ message: '추가연락처가 유효한 연락처가 아닙니다.' });
      return;
    }

    if (
      formData.representativeName &&
      !isKoreanOrAlphabet(formData.representativeName)
    ) {
      notification.error({
        message: '대표명은 한글만, 2~10자리까지 입력 가능합니다.',
      });
      return;
    }

    if (
      formData.registrationNumber &&
      !isValidBusinessNumber(formData.registrationNumber)
    ) {
      notification.error({ message: '유효하지 않은 사업자 등록번호입니다.' });
      return;
    }

    const { data } = await createCompanyMutation({
      variables: {
        data: formData,
      },
    });

    if (data?.createCompany.__typename === 'CreateCompanySuccess') {
      notification.success({ message: '정비소가 정상적으로 추가되었습니다.' });
      navigate('/company/list');
    } else if (data?.createCompany.__typename === 'CreateCompanyFail') {
      notification.error({ message: data.createCompany.error.message });
    }
  };
  const options = React.useMemo(() => {
    if (!placeData?.places) {
      return [];
    }

    return placeData?.places.map((place, index) => {
      return (
        <AutoComplete.Option key={`${place.name}_${index}`} value={place.name}>
          {`${place.name} (${
            place.roadAddress ? place.roadAddress : place.regionAddress
          })`}
        </AutoComplete.Option>
      );
    });
  }, [placeData?.places]);

  const specialtiesOptions = React.useMemo(() => {
    const sortingArray =
      specialtiesData &&
      [...specialtiesData.companySpecialties].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    return sortingArray?.map((specialty) => ({
      label: specialty.name,
      value: specialty.id,
    }));
  }, [specialtiesData]);

  return (
    <Container>
      <Title level={3}>업체 신규 등록</Title>
      <Form labelCol={{ xxl: 2, xl: 3, md: 4 }} layout="horizontal" form={form}>
        <Divider orientation="left">기본정보</Divider>
        <Form.Item name="name" label="업체명" required>
          <AutoComplete
            onChange={(value) => {
              if (value.length < 2) {
                return;
              }
              placeQuery({
                variables: {
                  term: value,
                  first: 5,
                },
              });
            }}
            onSelect={(selectedCompanyName: string) => {
              const selectedPlace = placeData?.places.find(
                (place) => place.name === selectedCompanyName
              );

              if (!selectedPlace) {
                return;
              }
              form.setFieldValue('name', selectedPlace.name);
              const address = selectedPlace.roadAddress
                ? selectedPlace.roadAddress
                : selectedPlace.regionAddress;

              if (address) {
                form.setFieldValue('address', address);
              }

              if (selectedPlace.contact) {
                form.setFieldValue(
                  'contact',
                  contactFormatter(selectedPlace.contact)
                );
              }
            }}
            placeholder="업체명을 입력해주세요"
          >
            {options}
          </AutoComplete>
        </Form.Item>
        <Form.Item name="isPartner" label="예약 가능(파트너)" required>
          <Switch
            onChange={(checked) => form.setFieldValue('isPartner', checked)}
            defaultChecked={false}
          />
        </Form.Item>
        <Form.Item name="certificated" label="닥터차 인증" required>
          <Switch
            onChange={(checked) => form.setFieldValue('certificated', checked)}
            defaultChecked={false}
          />
        </Form.Item>
        <Form.Item name="address" label="주소" required>
          <Input
            placeholder="업체명 검색 후 입력해주세요"
            onChange={(e) => form.setFieldValue('address', e.target.value)}
          />
        </Form.Item>
        <Form.Item name="detailAddress" label="상세주소">
          <Input
            placeholder="상세주소를 입력해주세요. (ex. 1층 101호)"
            onChange={(e) =>
              form.setFieldValue('detailAddress', e.target.value)
            }
          />
        </Form.Item>
        <Form.Item name="contact" label="연락처" required>
          <Input
            placeholder="업체의 연락처로, 숫자만 입력해주세요. (ex. 02-123-1234)"
            onChange={(e) => {
              const replaced = e.target.value.replace(/[^0-9]/g, '');
              form.setFieldValue('contact', contactFormatter(replaced));
            }}
          />
        </Form.Item>
        <Form.Item name="extraContact" label="추가 연락처">
          <Input
            placeholder="숫자만 입력해주세요. (ex. 01012345678)"
            onChange={(e) => {
              const replaced = e.target.value.replace(/[^0-9]/g, '');
              form.setFieldValue('extraContact', contactFormatter(replaced));
            }}
          />
        </Form.Item>
        <Form.Item name="handleNationality" label="취급 차량 국적" required>
          <Radio.Group
            name="handleNationality"
            onChange={(e) =>
              form.setFieldValue('handleNationality', e.target.value)
            }
            defaultValue={HandleNationality.All}
          >
            <Radio value={HandleNationality.OnlyDomestic}>국산차</Radio>
            <Radio value={HandleNationality.OnlyForeign}>수입차</Radio>
            <Radio value={HandleNationality.All}>국산차/수입차</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="type" label="업체 유형" required>
          <Select
            placeholder="업체 유형을 선택해주세요 (ex. 정비공장)"
            onChange={(type) => form.setFieldValue('type', type)}
            options={companyTypesData?.companyTypes.map((companyType) => ({
              value: companyType,
              label: companyTypeFormatter(companyType),
            }))}
          />
        </Form.Item>
        <Form.Item name="specialties" label="전문 분야">
          <Select
            placeholder="배치한 순서대로 고객쪽에서 보여집니다. (최대 5개)"
            allowClear
            maxLength={5}
            mode="multiple"
            filterOption={(inputValue, option) =>
              (option?.label
                ?.toLowerCase()
                ?.indexOf?.(inputValue.toLowerCase()) || 0) >= 0
            }
            options={specialtiesOptions}
          />
        </Form.Item>
        <Form.Item label="영업 시간">
          <TimeSelectorBox>
            <Card type="inner" title="시간 설정">
              <OperatingTimesBox>
                <OperatingTimeRow>
                  <span>월요일</span>
                  <TimePicker.RangePicker
                    format={'HH:mm'}
                    disabled={operatingTime.hoursByDay.MON === null}
                    value={
                      operatingTime.hoursByDay.MON
                        ? timeRangeToMomentRange(operatingTime.hoursByDay.MON)
                        : undefined
                    }
                    onChange={(changedTime) => {
                      if (changedTime) {
                        const from = changedTime[0];
                        const to = changedTime[1];

                        if (from && to) {
                          setOperatingTime({
                            ...operatingTime,
                            hoursByDay: {
                              ...operatingTime.hoursByDay,
                              MON: momentRangeToTimeRange([from, to]),
                            },
                          });
                        }
                      }
                    }}
                  />
                </OperatingTimeRow>
                <OperatingTimeRow>
                  <span>화요일</span>
                  <TimePicker.RangePicker
                    format={'HH:mm'}
                    disabled={operatingTime.hoursByDay.TUE === null}
                    value={
                      operatingTime.hoursByDay.TUE
                        ? timeRangeToMomentRange(operatingTime.hoursByDay.TUE)
                        : undefined
                    }
                    onChange={(changedTime) => {
                      if (changedTime) {
                        const from = changedTime[0];
                        const to = changedTime[1];

                        if (from && to) {
                          setOperatingTime({
                            ...operatingTime,
                            hoursByDay: {
                              ...operatingTime.hoursByDay,
                              TUE: momentRangeToTimeRange([from, to]),
                            },
                          });
                        }
                      }
                    }}
                  />
                </OperatingTimeRow>
                <OperatingTimeRow>
                  <span>수요일</span>
                  <TimePicker.RangePicker
                    format={'HH:mm'}
                    disabled={operatingTime.hoursByDay.WED === null}
                    value={
                      operatingTime.hoursByDay.WED
                        ? timeRangeToMomentRange(operatingTime.hoursByDay.WED)
                        : undefined
                    }
                    onChange={(changedTime) => {
                      if (changedTime) {
                        const from = changedTime[0];
                        const to = changedTime[1];

                        if (from && to) {
                          setOperatingTime({
                            ...operatingTime,
                            hoursByDay: {
                              ...operatingTime.hoursByDay,
                              WED: momentRangeToTimeRange([from, to]),
                            },
                          });
                        }
                      }
                    }}
                  />
                </OperatingTimeRow>
                <OperatingTimeRow>
                  <span>목요일</span>
                  <TimePicker.RangePicker
                    format={'HH:mm'}
                    disabled={operatingTime.hoursByDay.TUR === null}
                    value={
                      operatingTime.hoursByDay.TUR
                        ? timeRangeToMomentRange(operatingTime.hoursByDay.TUR)
                        : undefined
                    }
                    onChange={(changedTime) => {
                      if (changedTime) {
                        const from = changedTime[0];
                        const to = changedTime[1];

                        if (from && to) {
                          setOperatingTime({
                            ...operatingTime,
                            hoursByDay: {
                              ...operatingTime.hoursByDay,
                              TUR: momentRangeToTimeRange([from, to]),
                            },
                          });
                        }
                      }
                    }}
                  />
                </OperatingTimeRow>
                <OperatingTimeRow>
                  <span>금요일</span>
                  <TimePicker.RangePicker
                    format={'HH:mm'}
                    disabled={operatingTime.hoursByDay.FRI === null}
                    value={
                      operatingTime.hoursByDay.FRI
                        ? timeRangeToMomentRange(operatingTime.hoursByDay.FRI)
                        : undefined
                    }
                    onChange={(changedTime) => {
                      if (changedTime) {
                        const from = changedTime[0];
                        const to = changedTime[1];

                        if (from && to) {
                          setOperatingTime({
                            ...operatingTime,
                            hoursByDay: {
                              ...operatingTime.hoursByDay,
                              FRI: momentRangeToTimeRange([from, to]),
                            },
                          });
                        }
                      }
                    }}
                  />
                </OperatingTimeRow>
                <OperatingTimeRow>
                  <span>토요일</span>
                  <TimePicker.RangePicker
                    format={'HH:mm'}
                    disabled={operatingTime.hoursByDay.SAT === null}
                    value={
                      operatingTime.hoursByDay.SAT
                        ? timeRangeToMomentRange(operatingTime.hoursByDay.SAT)
                        : undefined
                    }
                    onChange={(changedTime) => {
                      if (changedTime) {
                        const from = changedTime[0];
                        const to = changedTime[1];

                        if (from && to) {
                          setOperatingTime({
                            ...operatingTime,
                            hoursByDay: {
                              ...operatingTime.hoursByDay,
                              SAT: momentRangeToTimeRange([from, to]),
                            },
                          });
                        }
                      }
                    }}
                  />
                </OperatingTimeRow>
                <OperatingTimeRow>
                  <span>일요일</span>
                  <TimePicker.RangePicker
                    format={'HH:mm'}
                    disabled={operatingTime.hoursByDay.SUN === null}
                    value={
                      operatingTime.hoursByDay.SUN
                        ? timeRangeToMomentRange(operatingTime.hoursByDay.SUN)
                        : undefined
                    }
                    onChange={(changedTime) => {
                      if (changedTime) {
                        const from = changedTime[0];
                        const to = changedTime[1];

                        if (from && to) {
                          setOperatingTime({
                            ...operatingTime,
                            hoursByDay: {
                              ...operatingTime.hoursByDay,
                              SUN: momentRangeToTimeRange([from, to]),
                            },
                          });
                        }
                      }
                    }}
                  />
                </OperatingTimeRow>
              </OperatingTimesBox>
            </Card>
            <Card type="inner" title="휴일 설정">
              <CardTitleCheckBox>
                <Checkbox
                  style={{ display: 'flex', alignItems: 'center' }}
                  checked={operatingTime.hoursByDay.MON === null}
                  onChange={(e) => {
                    setOperatingTime({
                      ...operatingTime,
                      hoursByDay: {
                        ...operatingTime.hoursByDay,
                        MON: e.target.checked
                          ? null
                          : DEFAULT_OPERATING_TIME_TIME_RANGE,
                      },
                    });
                  }}
                >
                  월
                </Checkbox>
                <Checkbox
                  style={{ display: 'flex', alignItems: 'center' }}
                  checked={operatingTime.hoursByDay.TUE === null}
                  onChange={(e) => {
                    setOperatingTime({
                      ...operatingTime,
                      hoursByDay: {
                        ...operatingTime.hoursByDay,
                        TUE: e.target.checked
                          ? null
                          : DEFAULT_OPERATING_TIME_TIME_RANGE,
                      },
                    });
                  }}
                >
                  화
                </Checkbox>
                <Checkbox
                  style={{ display: 'flex', alignItems: 'center' }}
                  checked={operatingTime.hoursByDay.WED === null}
                  onChange={(e) => {
                    setOperatingTime({
                      ...operatingTime,
                      hoursByDay: {
                        ...operatingTime.hoursByDay,
                        WED: e.target.checked
                          ? null
                          : DEFAULT_OPERATING_TIME_TIME_RANGE,
                      },
                    });
                  }}
                >
                  수
                </Checkbox>
                <Checkbox
                  style={{ display: 'flex', alignItems: 'center' }}
                  checked={operatingTime.hoursByDay.TUR === null}
                  onChange={(e) => {
                    setOperatingTime({
                      ...operatingTime,
                      hoursByDay: {
                        ...operatingTime.hoursByDay,
                        TUR: e.target.checked
                          ? null
                          : DEFAULT_OPERATING_TIME_TIME_RANGE,
                      },
                    });
                  }}
                >
                  목
                </Checkbox>
                <Checkbox
                  style={{ display: 'flex', alignItems: 'center' }}
                  checked={operatingTime.hoursByDay.FRI === null}
                  onChange={(e) => {
                    setOperatingTime({
                      ...operatingTime,
                      hoursByDay: {
                        ...operatingTime.hoursByDay,
                        FRI: e.target.checked
                          ? null
                          : DEFAULT_OPERATING_TIME_TIME_RANGE,
                      },
                    });
                  }}
                >
                  금
                </Checkbox>
                <Checkbox
                  style={{ display: 'flex', alignItems: 'center' }}
                  checked={operatingTime.hoursByDay.SAT === null}
                  onChange={(e) => {
                    setOperatingTime({
                      ...operatingTime,
                      hoursByDay: {
                        ...operatingTime.hoursByDay,
                        SAT: e.target.checked
                          ? null
                          : DEFAULT_OPERATING_TIME_TIME_RANGE,
                      },
                    });
                  }}
                >
                  토
                </Checkbox>
                <Checkbox
                  style={{ display: 'flex', alignItems: 'center' }}
                  checked={operatingTime.hoursByDay.SUN === null}
                  onChange={(e) => {
                    setOperatingTime({
                      ...operatingTime,
                      hoursByDay: {
                        ...operatingTime.hoursByDay,
                        SUN: e.target.checked
                          ? null
                          : DEFAULT_OPERATING_TIME_TIME_RANGE,
                      },
                    });
                  }}
                >
                  일
                </Checkbox>
                <Checkbox
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '52px',
                  }}
                  checked={operatingTime.closesOnHoliday}
                  onChange={(e) => {
                    setOperatingTime({
                      ...operatingTime,
                      closesOnHoliday: e.target.checked,
                    });
                  }}
                >
                  공휴일 휴무
                </Checkbox>
              </CardTitleCheckBox>
            </Card>
            <Form.Item name="remark" label="비고" style={{ marginTop: '20px' }}>
              <Input.TextArea
                placeholder="영업시간과 관련된 비고사항을 입력해주세요. (ex 둘째주, 넷째주 수요일 휴무)"
                onChange={(e) => {
                  setOperatingTime({
                    ...operatingTime,
                    remark: e.target.value,
                  });
                }}
                value={operatingTime.remark ? operatingTime.remark : ''}
                autoSize
                minLength={3}
              />
            </Form.Item>
          </TimeSelectorBox>
        </Form.Item>
        <Form.Item
          name="images"
          label="업체 사진"
          valuePropName="fileList"
          getValueFromEvent={(e: any) =>
            Array.isArray(e) ? e : e?.fileList || []
          }
        >
          <Upload
            multiple
            accept="image/*"
            listType="picture-card"
            onPreview={handleClickPreview}
            customRequest={uploadShopPhoto}
          >
            + 추가
          </Upload>
        </Form.Item>
        <Modal
          open={previewOpen}
          title={`업체사진 미리보기`}
          footer={null}
          onCancel={() => setPreviewOpen(false)}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
        <Divider orientation="left">상세정보</Divider>
        <Form.Item label="닥터차 추천 이유">
          <Checkbox.Group
            value={recommendationIds}
            onChange={(e) => {
              setRecommendationIds(
                e.map(
                  (recommendationId) => recommendationId.valueOf() as string
                )
              );
            }}
          >
            <Row gutter={[12, 12]}>
              {recommendationsData?.companyRecommendations.map(
                (recommendation, index) => {
                  return (
                    <Col key={index} span={12}>
                      <Checkbox key={index} value={recommendation.id}>
                        <Text>
                          {recommendation.title}
                          <blockquote>{recommendation.description}</blockquote>
                        </Text>
                      </Checkbox>
                    </Col>
                  );
                }
              )}
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item label="업체 소개" name="bio">
          <Input.TextArea
            rows={8}
            placeholder="앱에서 보여지는 업체 소개입니다."
            onChange={(e) => form.setFieldValue('bio', e.target.value)}
          />
        </Form.Item>
        <Form.Item label="편의시설 및 서비스">
          <FacilitiesBox>
            {facilitiesData?.companyFacilities.map((facility, index) => {
              return (
                <Checkbox
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '0px',
                  }}
                  checked={facilityIds.includes(facility.id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFacilityIds([...facilityIds, facility.id]);
                    } else {
                      setFacilityIds(
                        facilityIds.filter(
                          (facilityId) => facilityId !== facility.id
                        )
                      );
                    }
                  }}
                >
                  {facility.name}
                </Checkbox>
              );
            })}
          </FacilitiesBox>
        </Form.Item>
        <Form.Item label="대표자명" name="representativeName">
          <Input
            placeholder="홍길동"
            onChange={(e) =>
              form.setFieldValue('representativeName', e.target.value)
            }
          />
        </Form.Item>
        <Form.Item label="사업자등록번호" name="registrationNumber">
          <Input
            onChange={(e) =>
              form.setFieldValue(
                'registrationNumber',
                businessNumberFormatter(e.target.value)
              )
            }
          />
        </Form.Item>
        <Divider orientation="left">상담사 전용</Divider>
        <Form.Item label="업체 메모" name="memo">
          <Input.TextArea
            placeholder="내부인에게만 보여지는 업체메모 공간입니다."
            onChange={(e) => form.setFieldValue('memo', e.target.value)}
          />
        </Form.Item>
        <Button
          loading={loading}
          size="large"
          icon={<SaveOutlined />}
          type="primary"
          style={{ width: '100%' }}
          onClick={handleClickSave}
        >
          저장하기
        </Button>
      </Form>
    </Container>
  );
};

export default CreateCompanyPage;

const Container = styled.div`
  padding: 24px 24px 48px 24px;
  flex: 1;
  overflow-y: auto;
`;

const TimeSelectorBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CardTitleCheckBox = styled.div`
  display: flex;
  gap: 30px;
`;

const OperatingTimesBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const OperatingTimeRow = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
  gap: 10px;
`;

const FacilitiesBox = styled.div`
  display: flex;
  gap: 16px;
`;
