import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';

import Comment from './Comment';

type ExtendableCommentsProps = {
  lastCommentId: string;
  commentIds: string[];
};

const ExtendableComments: React.FC<ExtendableCommentsProps> = ({
  lastCommentId,
  commentIds,
}) => {
  const [extended, setExtended] = React.useState(false);
  if (!extended) {
    return (
      <Container>
        <Comment
          commentId={lastCommentId}
          setReplyTargetComment={() => undefined}
          last
          deleteComment={() => undefined}
          onClickOpenReportModal={() => undefined}
          readonly
        />
        {commentIds.length > 1 && (
          <Button icon={<DownOutlined />} onClick={() => setExtended(true)}>
            더보기
          </Button>
        )}
      </Container>
    );
  }
  return (
    <Container>
      {commentIds.map((commentId, index) => (
        <Comment
          key={index}
          commentId={commentId}
          setReplyTargetComment={() => undefined}
          last={index === commentIds.length - 1}
          deleteComment={() => undefined}
          onClickOpenReportModal={() => undefined}
          readonly
        />
      ))}
      <Button icon={<UpOutlined />} onClick={() => setExtended(false)}>
        접기
      </Button>
    </Container>
  );
};

export default ExtendableComments;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 500ms;
`;
