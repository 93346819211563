export const AREA_CODES = [
  '02',
  '031',
  '032',
  '033',
  '041',
  '042',
  '043',
  '044',
  '051',
  '052',
  '053',
  '054',
  '055',
  '061',
  '062',
  '063',
  '064',
  '070',
];

export const NATIONAL_REPRESENTATIVE_CODES = [
  '1588',
  '1577',
  '1899',
  '1544',
  '1644',
  '1661',
  '1566',
  '1600',
  '1670',
  '1688',
  '1666',
  '1599',
  '1877',
  '1855',
  '1800',
];

export const MOBILE_CODES = ['010', '011', '016', '017', '018', '019'];

export const isContact = (contact: string): boolean => {
  return (
    /^\d{8,11}$/.test(contact) &&
    (AREA_CODES.includes(contact.substring(0, 2)) ||
      AREA_CODES.includes(contact.substring(0, 3)) ||
      NATIONAL_REPRESENTATIVE_CODES.includes(contact.substring(0, 4)))
  );
};

export const isPhoneNumber = (phoneNumber: string): boolean => {
  return /^01[016789]{1}?([0-9]{3,4})?[0-9]{4}$/.test(phoneNumber);
};

export const isKoreanOrAlphabet = (name: string): boolean => {
  return /^[가-힣]{2,10}$/.test(name);
};

export const isValidBusinessNumber = (businessNumber: string): boolean => {
  if (businessNumber.length !== 10) {
    return false;
  }
  const registrationNumberArray = businessNumber
    .replace(/-/gi, '')
    .split('')
    .map((attribute) => parseInt(attribute, 10));
  const REGISTRATION_NUMBER_CERTIFICATE_KEY = [1, 3, 7, 1, 3, 7, 1, 3, 5];

  let sum = 0;

  for (let i = 0; i < REGISTRATION_NUMBER_CERTIFICATE_KEY.length; i++) {
    const registrationNumberCertificateKeyAttribute =
      REGISTRATION_NUMBER_CERTIFICATE_KEY[i];
    const registrationNumberAttribute = registrationNumberArray[i];
    sum +=
      registrationNumberCertificateKeyAttribute * registrationNumberAttribute;
  }

  sum += Math.floor(
    (REGISTRATION_NUMBER_CERTIFICATE_KEY[8] * registrationNumberArray[8]) / 10
  );
  return registrationNumberArray[9] === 10 - (sum % 10);
};
