import { gql } from '@apollo/client';
import { Col, Modal, Row, Spin, theme, Typography } from 'antd';
import React from 'react';

import ProductCard from '../cards/ProductCard';

import { useProductSearchModalQuery } from 'src/utils/client';

gql`
  query ProductSearchModal($limit: Int!, $offset: Int!) {
    products(limit: $limit, offset: $offset) {
      id
    }
  }
`;

type ProductSearchModalProps = {
  open: boolean;
  onCancel: () => void;
  onSelectProducts: (productIds: string[]) => void;
};

const { Paragraph } = Typography;

const ProductSearchModal = ({
  open,
  onCancel,
  onSelectProducts,
}: ProductSearchModalProps) => {
  const [selectedProductIds, setSelectedProductIds] = React.useState<string[]>(
    []
  );
  const { data, loading, error } = useProductSearchModalQuery({
    variables: {
      limit: 10,
      offset: 0,
    },
  });
  const products = data?.products;

  const {
    token: { colorPrimary, colorBorder },
  } = theme.useToken();

  return (
    <Modal
      open={open}
      onOk={() => {
        onSelectProducts(selectedProductIds);
        setSelectedProductIds([]);
      }}
      onCancel={onCancel}
      okButtonProps={{
        disabled: selectedProductIds.length === 0,
        children: '추가',
      }}
      title="상품 검색"
    >
      <Paragraph>상품을 선택한 후, 보낼 댓글에 추가할 수 있습니다.</Paragraph>
      <Row gutter={[0, 4]}>
        {loading ? <Spin /> : null}
        {error ? <div>{error.message}</div> : null}
        {products?.map((product) => {
          return (
            <Col span={24} key={product.id}>
              <ProductCard
                productId={product.id}
                onClick={() => {
                  setSelectedProductIds((prev) => {
                    if (prev.includes(product.id)) {
                      return prev.filter((id) => id !== product.id);
                    } else {
                      return [...prev, product.id];
                    }
                  });
                }}
                style={{
                  border: selectedProductIds.includes(product.id)
                    ? '2px solid ' + colorPrimary
                    : '2px solid ' + colorBorder,
                }}
              />
            </Col>
          );
        })}
      </Row>
    </Modal>
  );
};

export default ProductSearchModal;
