import {
  BookOutlined,
  CommentOutlined,
  DownOutlined,
  EditOutlined,
  EnvironmentOutlined,
  FormOutlined,
  ShareAltOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { gql, useApolloClient } from '@apollo/client';
import {
  Avatar,
  Divider,
  Tag,
  Image,
  Dropdown,
  Menu,
  notification,
  Modal,
  Button,
  Space,
  Spin,
  Input,
  Typography,
  Tooltip,
  message,
  Alert,
  Card,
  theme,
} from 'antd';
import React from 'react';
import styled from 'styled-components';

import Comment from 'src/components/shared/Comment';
import {
  AreaInput,
  CounselStatus,
  RecheckCounselsInPostDocument,
  RecheckCounselsInPostQuery,
  RecheckCounselsInPostQueryVariables,
  usePostCardQuery,
  RoleEnum,
  useRecheckPostIssueMemoLazyQuery,
} from 'src/utils/client';
import env from 'src/utils/env';
import {
  fromNow,
  fuelTypeFormatter,
  insuranceFormatter,
  statusToBadgeInfo,
  tagsFormatter,
} from 'src/utils/formatter';
import { openCounselWindow } from 'src/utils/providers';

const { Paragraph, Text } = Typography;

gql`
  query RecheckCounselsInPost($postId: ID!) {
    post(postId: $postId) {
      id
      counsels {
        id
        status
      }
    }
  }
`;

gql`
  query recheckPostIssueMemo($postIssueId: ID!) {
    postIssue(postIssueId: $postIssueId) {
      id
      memo
    }
  }
`;

gql`
  query PostCard($postIssueId: ID!) {
    postIssue(postIssueId: $postIssueId) {
      id
      memo
      lastComment {
        id
        createdAt
        author {
          id
          role
          nickname
          avatar {
            url
          }
        }
        body
        files {
          ... on Image {
            __typename
            url
          }
          ... on Video {
            __typename
            url
          }
        }
      }
      post {
        id
        title
        body
        areas {
          name
          zone {
            district
            neighborhood
            province
          }
          coordinates {
            latitude
            longitude
          }
          radius
        }
        insurance
        author {
          id
          nickname
          avatar {
            url
          }
        }
        createdAt
        vehicle {
          id
          plateNumber
          fullModelName
          mileage
          vin
          registeredYear
          fuelType {
            base
            hybrid
          }
        }
        counsels {
          id
          index
          status
          creator {
            id
            nickname
          }
        }
        files {
          ... on Image {
            __typename
            url
          }
          ... on Video {
            __typename
            url
          }
        }
        hashtags {
          id
          name
        }
        commentCount
      }
      status
      bookmarked
    }
  }
`;

export type PostCardPresenterProps = {
  postIssueId: string;
  opened: boolean;
  onClick?: () => void;
  border?: boolean;
  cardRef?: React.RefObject<HTMLDivElement>;
  bookmark: (postIssueId: string) => void;
  unbookmark: (postIssueId: string) => void;
  changePostIssueToDone: (postIssueId: string) => void;
  updatePostIssue: (postIssueId: string, memo: string | null) => Promise<void>;
  requestStoreReview: (postId: string) => Promise<void>;
  onClickOpenReportModal: (reportTarget: {
    target: 'post' | 'user';
    id: string;
  }) => void;
  onClickOpenUserPostHistoryModal: (userId: string, nickname: string) => void;
  createCounselwithComment: (
    postId: string,
    vehicleId: string,
    body: string,
    areas: AreaInput[]
  ) => Promise<void>;
  lastCommentShow?: boolean;
  commentManager?: boolean;
};

const PostCardPresenter: React.FC<PostCardPresenterProps> = ({
  opened,
  onClick,
  cardRef,
  postIssueId,
  bookmark,
  unbookmark,
  requestStoreReview,
  changePostIssueToDone,
  updatePostIssue,
  onClickOpenReportModal,
  onClickOpenUserPostHistoryModal,
  createCounselwithComment,
  lastCommentShow = false,
  commentManager = false,
}) => {
  const client = useApolloClient();
  const { data, loading } = usePostCardQuery({
    variables: {
      postIssueId,
    },
  });
  const [recheckPostIssueMemoQuery] = useRecheckPostIssueMemoLazyQuery({
    variables: {
      postIssueId,
    },
  });

  const {
    token: {
      colorSuccess,
      colorError,
      colorPrimary,
      colorTextDisabled,
      colorBgContainer,
      colorBorder,
    },
  } = theme.useToken();

  const [createCounselModal, setCreateCounselModal] = React.useState(false);
  const [counselReason, setCounselReason] = React.useState('');
  const [createCounselLoading, setCreateCounselLoading] = React.useState(false);
  const [memo, setMemo] = React.useState<string | null | undefined>(undefined);
  const [memoEdit, setMemoEdit] = React.useState(false);
  const [memoUpdateLoading, setMemoUpdateLoading] = React.useState(false);
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

  const postIssue = data?.postIssue;
  const vehicle = postIssue?.post.vehicle;

  React.useEffect(() => {
    if (postIssue?.post.author?.nickname) {
      setCounselReason(
        `${postIssue.post.author.nickname}님, 안녕하세요. 고객님의 문의는 추가 확인 작업이 필요하여 채팅으로 상세한 안내 도와드리겠습니다.`
      );
    }
    if (postIssue?.memo) {
      setMemo(postIssue.memo);
    }
  }, [postIssue]);

  React.useEffect(() => {
    const preload = async () => {
      if (memoEdit) {
        setMemoUpdateLoading(true);
        await recheckPostIssueMemoQuery();
        setMemoUpdateLoading(false);
      }
    };
    preload();
  }, [memoEdit, recheckPostIssueMemoQuery]);

  const focusTextArea = () => {
    setTimeout(() => {
      if (textAreaRef.current) {
        textAreaRef.current.focus();
      }
    }, 50);
  };

  const menu = React.useMemo(() => {
    return (
      <Menu>
        {postIssue && (
          <>
            <Menu.Item
              key={'moveToDone'}
              onClick={(e) => {
                Modal.confirm({
                  title: '정말 이 게시물을 답변 완료로 변경하시겠어요?',
                  content: '댓글/반응 없이도 강제로 답변 완료로 변경됩니다.',
                  onOk: () => {
                    changePostIssueToDone(postIssueId);
                  },
                });
                e.domEvent.stopPropagation();
              }}
            >
              답변 완료 탭으로 이동
            </Menu.Item>
            <Menu.Item
              key={'pastHistory'}
              onClick={(e) => {
                if (!postIssue.post.author) {
                  notification.error({
                    message: '존재하지 않는 사용자 입니다.',
                  });
                  return;
                }
                onClickOpenUserPostHistoryModal(
                  postIssue.post.author.id,
                  postIssue.post.author.nickname
                );
                e.domEvent.stopPropagation();
              }}
            >
              이 사용자의 지난 커뮤니티 문의
            </Menu.Item>
            <Menu.Item
              key={'requestStoreReview'}
              onClick={(e) => {
                if (!postIssue.post.author) {
                  notification.error({
                    message: '존재하지 않는 사용자 입니다.',
                  });
                  return;
                }
                Modal.confirm({
                  title: `${postIssue.post.author.nickname}님에게 앱스토어/플레이스토어 리뷰를 요청하시겠어요?`,
                  content:
                    '요청시 고객님의 알림으로 앱스토어/플레이스토어 리뷰를 작성하는 페이지로 이동하는 링크가 전달됩니다.',
                  onOk: () => {
                    requestStoreReview(postIssue.post.id);
                  },
                });
                e.domEvent.stopPropagation();
              }}
            >
              앱스토어 리뷰 요청
            </Menu.Item>
            {postIssue.post.counsels?.length ? (
              postIssue.post.counsels.map((counsel) => (
                <Menu.Item
                  key={counsel.id}
                  style={{ color: colorSuccess }}
                  onClick={(e) => {
                    openCounselWindow(counsel.id);
                    e.domEvent.stopPropagation();
                  }}
                >
                  채팅방 열기 (#{counsel.index})
                </Menu.Item>
              ))
            ) : (
              <Menu.Item
                key={'createCounsel'}
                disabled={
                  !postIssue.post.vehicle?.id || !postIssue.post.author?.id
                }
                style={
                  postIssue.post.vehicle?.id && postIssue.post.author?.id
                    ? { color: colorSuccess }
                    : {}
                }
                onClick={async (e) => {
                  try {
                    const { data: recheckedData } = await client.query<
                      RecheckCounselsInPostQuery,
                      RecheckCounselsInPostQueryVariables
                    >({
                      query: RecheckCounselsInPostDocument,
                      variables: {
                        postId: postIssue.post.id,
                      },
                      fetchPolicy: 'network-only',
                    });
                    if (recheckedData.post?.counsels?.length) {
                      notification.error({
                        message: '이미 채팅방이 존재합니다.',
                      });
                    } else {
                      setCreateCounselModal(true);
                    }
                  } catch (e) {
                    notification.error({
                      message: '게시물에 연관된 채팅방 조회에 실패했습니다.',
                    });
                  }
                  e.domEvent.stopPropagation();
                }}
              >
                <Tooltip
                  title={
                    postIssue.post.vehicle?.id
                      ? '게시글 작성자와 채팅을 생성합니다.'
                      : '작성자의 차량이 확인되지 않아 채팅개설이 불가능합니다.'
                  }
                >
                  채팅 시작
                </Tooltip>
              </Menu.Item>
            )}
            <Divider style={{ margin: '0.2rem 0rem' }} />
            <Menu.Item
              key={'report'}
              style={{ color: colorError }}
              onClick={(e) => {
                onClickOpenReportModal({
                  target: 'post',
                  id: postIssue.post.id,
                });
                e.domEvent.stopPropagation();
              }}
            >
              이 게시물 신고
            </Menu.Item>
            <Menu.Item
              key={'reportUser'}
              style={{ color: colorError }}
              onClick={(e) => {
                if (!postIssue.post.author) {
                  notification.error({
                    message: '존재하지 않는 사용자 입니다.',
                  });
                  return;
                }
                onClickOpenReportModal({
                  target: 'user',
                  id: postIssue.post.author.id,
                });
                e.domEvent.stopPropagation();
              }}
            >
              이 사용자 신고
            </Menu.Item>
          </>
        )}
      </Menu>
    );
  }, [
    postIssue,
    colorSuccess,
    colorError,
    changePostIssueToDone,
    postIssueId,
    onClickOpenUserPostHistoryModal,
    requestStoreReview,
    client,
    onClickOpenReportModal,
  ]);

  const counselorCommented =
    postIssue?.lastComment &&
    postIssue?.lastComment.author &&
    postIssue?.lastComment.author.role === RoleEnum.Counselor;
  const statusTagInfo = statusToBadgeInfo(postIssue?.status);

  return (
    <Spin spinning={loading}>
      <Card
        hoverable={!opened}
        onClick={() => {
          onClick?.();
        }}
        ref={cardRef}
        bodyStyle={{
          backgroundColor: opened ? colorBorder : colorBgContainer,
        }}
      >
        <SHeader>
          <SInfoBox>
            {postIssue?.post.author ? (
              <>
                <Avatar
                  size="small"
                  src={postIssue?.post.author.avatar?.url}
                  style={{ marginRight: 4 }}
                  icon={<UserOutlined />}
                />
                <Text strong>{postIssue?.post.author.nickname}</Text>
              </>
            ) : (
              <Text>(탈퇴한 유저)</Text>
            )}

            <Divider type="vertical" />
            {postIssue && (
              <Text type="danger">{fromNow(postIssue.post.createdAt)}</Text>
            )}
            <Tag
              color={statusTagInfo.color}
              style={{
                marginLeft: '6px',
              }}
            >
              {statusTagInfo.text}
            </Tag>
            {postIssue?.post.counsels?.map((counsel) => (
              <Tag
                onClick={(e) => {
                  e.stopPropagation();
                  openCounselWindow(counsel.id);
                }}
                style={{ cursor: 'pointer' }}
                key={counsel.id}
                icon={<CommentOutlined />}
                color={
                  counsel.status === CounselStatus.Finished
                    ? colorTextDisabled
                    : colorPrimary
                }
              >
                #{counsel.index} by {counsel.creator?.nickname}
              </Tag>
            ))}
          </SInfoBox>
          <Space>
            {!memoEdit && !postIssue?.memo && (
              <Button
                icon={<FormOutlined />}
                onClick={async (e) => {
                  e.stopPropagation();
                  await recheckPostIssueMemoQuery();
                  setMemoEdit(true);
                  focusTextArea();
                }}
              >
                메모 생성
              </Button>
            )}
            <Button.Group>
              <Button
                icon={<ShareAltOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  if (postIssue?.post.id) {
                    const url = `https://app.adjust.com/urf5hri?redirect=https%3A%2F%2Fdoctor-cha.com%2Fpost%2F${postIssue?.post.id}&deeplink=drcha%3A%2F%2Fposts%2F${postIssue?.post.id}`;
                    navigator.clipboard.writeText(url);
                    message.success({
                      content: `${postIssue.post.vehicle?.plateNumber}차량 게시물 링크가 복사되었습니다.`,
                    });
                  } else {
                    message.error('게시물 ID가 존재하지 않습니다.');
                  }
                }}
              />
              <Button
                icon={<BookOutlined />}
                type={postIssue?.bookmarked ? 'primary' : 'default'}
                onClick={(e) => {
                  e.stopPropagation();
                  if (postIssue) {
                    if (postIssue.bookmarked) {
                      unbookmark(postIssue.id);
                    } else {
                      bookmark(postIssue.id);
                    }
                  }
                }}
              />
              <Dropdown
                overlay={menu}
                trigger={['click']}
                placement="bottomRight"
              >
                <Button onClick={(e) => e.stopPropagation()}>
                  <Space>
                    더보기
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Button.Group>
          </Space>
        </SHeader>
        {memoEdit ? (
          <Space direction="vertical" style={{ width: '100%' }}>
            <Input.TextArea
              ref={textAreaRef}
              onClick={(e) => {
                e.stopPropagation();
              }}
              disabled={memoUpdateLoading}
              value={memo || undefined}
              onChange={(e) => {
                setMemo(e.target.value);
              }}
              autoSize={{ minRows: 2, maxRows: 6 }}
              maxLength={1024}
            />
            <Space>
              <Button
                type="primary"
                onClick={async (e) => {
                  e.stopPropagation();
                  setMemoUpdateLoading(true);
                  if (memo?.length || memo === null) {
                    await updatePostIssue(postIssueId, memo);
                  }
                  setMemoUpdateLoading(false);
                  setMemoEdit(false);
                }}
                disabled={memoUpdateLoading}
              >
                저장
              </Button>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setMemoEdit(false);
                  setMemo(postIssue?.memo);
                }}
              >
                취소
              </Button>
            </Space>
          </Space>
        ) : postIssue?.memo ? (
          <Alert
            onClick={(e) => {
              e.stopPropagation();
              setMemoEdit(true);
              focusTextArea();
            }}
            style={{ whiteSpace: 'pre-line', cursor: 'pointer' }}
            message={postIssue.memo}
            type="info"
            action={<Button type="ghost" icon={<EditOutlined />} />}
          />
        ) : null}
        {vehicle && (
          <div
            style={{
              padding: '0.8rem 0rem',
              fontSize: commentManager ? 18 : 16,
            }}
          >
            <Space
              split={
                <Divider style={{ margin: '0.2rem 0rem' }} type="vertical" />
              }
            >
              {vehicle.fullModelName && (
                <Typography.Text copyable>
                  {vehicle.fullModelName}
                </Typography.Text>
              )}
              {vehicle.plateNumber && (
                <Typography.Text copyable>
                  {vehicle.plateNumber}
                </Typography.Text>
              )}
              {vehicle.vin && (
                <Typography.Text copyable>{vehicle.vin}</Typography.Text>
              )}
            </Space>
            <Divider type="vertical" />
            <Space
              split={
                <Divider style={{ margin: '0.2rem 0rem' }} type="vertical" />
              }
            >
              {vehicle.fuelType && (
                <span>{fuelTypeFormatter(vehicle.fuelType)}</span>
              )}
              {vehicle.registeredYear && (
                <span>{vehicle.registeredYear}년식</span>
              )}
              {vehicle.mileage && (
                <span>{`${vehicle.mileage.toLocaleString()}km`}</span>
              )}
            </Space>
          </div>
        )}
        {postIssue?.post.areas.length ? (
          <SContentBox
            style={{ paddingBottom: 4 }}
            commentManager={commentManager}
          >
            <Space style={{ flexWrap: 'wrap' }}>
              {postIssue?.post.areas.map((area, index) => {
                return (
                  <Button.Group key={index}>
                    <Button size={commentManager ? 'middle' : 'small'}>
                      <Text copyable>
                        {area.zone?.province} {area.zone?.district}{' '}
                        {area.zone?.neighborhood}
                      </Text>
                    </Button>
                    <Button
                      size={commentManager ? 'middle' : 'small'}
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(
                          `${env.MAP_CONSOLE_URL}/?lat=${
                            area.coordinates.latitude
                          }&lon=${
                            area.coordinates.longitude
                          }&distance=${Math.round(area.radius / 1000)}&area=${
                            area.zone?.province +
                            ' ' +
                            area.zone?.district +
                            ' ' +
                            area.zone?.neighborhood
                          }`,
                          'doctor-cha-company-detail',
                          'width=1676, height=1207'
                        );
                      }}
                      icon={<EnvironmentOutlined />}
                    />
                  </Button.Group>
                );
              })}
            </Space>
          </SContentBox>
        ) : null}
        {postIssue?.post.title && (
          <STitleBox
            style={{ fontWeight: 'bold' }}
            commentManager={commentManager}
          >
            {postIssue?.post.title}
          </STitleBox>
        )}
        <SContentBox commentManager={commentManager}>
          {postIssue?.post.body}
        </SContentBox>

        <TagContainer>
          {postIssue?.post.insurance && (
            <Tag
              color="volcano"
              style={{
                cursor: 'pointer',
                fontSize: commentManager ? 16 : 14,
              }}
            >
              {insuranceFormatter(postIssue?.post.insurance)}
            </Tag>
          )}
          {postIssue?.post.hashtags.map((hashtag) => {
            const tag = tagsFormatter(hashtag.name);
            return (
              <Tag
                key={hashtag.id}
                color={tag?.color}
                style={{
                  cursor: 'pointer',
                  fontSize: commentManager ? 16 : 14,
                }}
              >
                #{hashtag.name}
              </Tag>
            );
          })}
        </TagContainer>
        <div
          style={{ display: 'flex', gap: 8, marginTop: 12, marginBottom: 8 }}
        >
          {postIssue?.post.files.length ? (
            <Image.PreviewGroup>
              {postIssue?.post.files
                .filter((file) => file.__typename === 'Image')
                .map((file, index) => {
                  return (
                    <Image
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      key={index}
                      src={file.url}
                      height={140}
                    />
                  );
                })}
            </Image.PreviewGroup>
          ) : null}
          <span>
            {postIssue?.post.files
              .filter((file) => file.__typename === 'Video')
              .map((file, index) => {
                return (
                  <span key={index}>
                    <video height={140} controls>
                      <source src={file.url} />
                    </video>
                  </span>
                );
              })}
          </span>
        </div>
        <div
          style={
            counselorCommented
              ? { color: colorPrimary, fontWeight: 'bold', marginBottom: 4 }
              : {}
          }
        >
          댓글 {postIssue?.post.commentCount}
        </div>
        {lastCommentShow && postIssue?.lastComment && (
          <Comment
            commentId={postIssue?.lastComment.id}
            setReplyTargetComment={() => undefined}
            deleteComment={() => undefined}
            last
            onClickOpenReportModal={() => undefined}
            readonly
          />
        )}
      </Card>
      <Modal
        width="800px"
        title="채팅 개설 이유"
        open={createCounselModal}
        okButtonProps={{
          disabled: !counselReason,
          loading: createCounselLoading,
          size: 'large',
        }}
        okText="시작"
        onOk={async () => {
          try {
            if (
              counselReason &&
              postIssue?.post.id &&
              postIssue.post.vehicle?.id
            ) {
              setCreateCounselLoading(true);
              await createCounselwithComment(
                postIssue.post.id,
                postIssue.post.vehicle.id,
                counselReason,
                postIssue.post.areas.map((area) => ({
                  coordinates: {
                    latitude: area.coordinates.latitude,
                    longitude: area.coordinates.longitude,
                  },
                  name: area.name,
                  radius: area.radius,
                }))
              );
              setCreateCounselModal(false);
            } else {
              notification.error({
                message: '채팅을 시작하기에 누락된 정보가 있습니다.',
              });
            }
          } catch (err) {
            console.log(err);
            setCreateCounselLoading(false);
          }
        }}
        cancelButtonProps={{ disabled: createCounselLoading, size: 'large' }}
        onCancel={() => setCreateCounselModal(false)}
      >
        <Paragraph>아래 내용과 함께 채팅생성 댓글이 등록됩니다.</Paragraph>
        <Input.TextArea
          size="large"
          value={counselReason}
          placeholder="댓글에 보일 채팅시작에 대한 보조 설명을 넣어주세요"
          onChange={(e) => setCounselReason(e.target.value)}
        />
      </Modal>
    </Spin>
  );
};

export default PostCardPresenter;

const SHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
`;

const SInfoBox = styled.div`
  display: flex;
  align-items: center;
`;

const STitleBox = styled.div<{ commentManager?: boolean }>`
  margin-top: 4px;
  font-size: ${(props) => (props.commentManager ? 20 : 16)}px;
  display: flex;
  align-items: center;
  flex: 0 0 22px;
`;

const SContentBox = styled.div<{ commentManager?: boolean }>`
  margin-top: 4px;
  font-size: ${(props) => (props.commentManager ? 20 : 14)}px;
  white-space: pre-line;
  overflow: auto;
  max-height: 350px;
  word-break: break-word;
`;

const TagContainer = styled.div`
  margin-top: 8px;
`;
