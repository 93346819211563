import {
  AimOutlined,
  EnvironmentOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { gql } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Image,
  Input,
  Modal,
  notification,
  Select,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import Table from 'antd/lib/table';
import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';

import CompanyCard from '../cards/CompanyCard';

// https://map.doctor-cha.com/?lat=37.7156679&lon=126.851875&distance=5&area=%EA%B2%BD%EA%B8%B0%EB%8F%84%20%EA%B3%A0%EC%96%91%EC%8B%9C%20%EB%8D%95%EC%96%91%EA%B5%AC%20%EA%B4%80%EC%82%B0%EB%8F%99
import {
  usePostQuery,
  Coordinates,
  useCompaniesSearchLazyQuery,
  useCompanySpecialtiesQuery,
  CompaniesFilter,
  Specialty,
  Image as ImageType,
  CompanySortOrderEnum,
  Address,
} from 'src/utils/client';
import { contactFormatter } from 'src/utils/formatter';
import { getDistanceBetweenCoordinates } from 'src/utils/location';

gql`
  query companiesSearch(
    $filter: CompaniesFilter!
    $offset: Int!
    $limit: Int!
    $sortOrder: CompanySortOrderEnum!
  ) {
    companies(
      filter: $filter
      offset: $offset
      limit: $limit
      sortOrder: $sortOrder
    ) {
      id
      name
      address {
        road
        region
        coordinates {
          latitude
          longitude
        }
      }
      contact
      specialties {
        id
        name
      }
      images {
        url
      }
      memo
      updatedAt
      mentionCount
    }
    companiesCount(filter: $filter)
  }
`;

gql`
  query post($postId: ID!) {
    post(postId: $postId) {
      id
      areas {
        zone {
          district
          neighborhood
        }
        coordinates {
          latitude
          longitude
        }
        radius
      }
    }
  }
`;

const { Text } = Typography;

const PAGE_SIZE = 8;
const DISTANCE_THRESHOLD = 50000;

type CompanySearchModalProps = {
  postId: string;
  open: boolean;
  onClose: () => void;
  onSelectCompanies: (companyIds: string[]) => void;
};

const CompanySearchModal: React.FC<CompanySearchModalProps> = ({
  postId,
  open,
  onClose,
  onSelectCompanies,
}) => {
  const [specialtyIds, setSpecialtyIds] = React.useState<string[]>([]);
  const [term, setTerm] = React.useState<string>('');
  const [selectedArea, setSelectedArea] = React.useState<{
    index: number;
    coordinates: Coordinates;
    radius: number;
  } | null>(null);
  const [pagination, setPagination] = React.useState<{
    page: number;
    pageSize: number;
  }>({ page: 0, pageSize: PAGE_SIZE });
  const [selectedCompanyIds, setSelectedCompanyIds] = React.useState<string[]>(
    []
  );
  const [companiesSearchQuery, { data, loading }] = useCompaniesSearchLazyQuery(
    {
      fetchPolicy: 'network-only',
    }
  );
  const { data: companySpecialtiesData } = useCompanySpecialtiesQuery();
  const { companies, count } = React.useMemo(() => {
    if (!data) {
      return {
        companies: [],
        count: 0,
      };
    }

    return {
      companies: data.companies,
      count: data.companiesCount,
    };
  }, [data]);

  const clearState = () => {
    setTerm('');
    setSpecialtyIds([]);
    setSelectedArea(null);
    setPagination({ page: 0, pageSize: PAGE_SIZE });
    setSelectedCompanyIds([]);
  };

  const { data: postData } = usePostQuery({
    variables: {
      postId,
    },
  });

  React.useEffect(() => {
    const filter: CompaniesFilter = {
      area: selectedArea
        ? {
            coordinates: selectedArea.coordinates,
            radius: DISTANCE_THRESHOLD,
          }
        : undefined,
      specialtyIds: specialtyIds.length > 0 ? specialtyIds : undefined,
      term: term || undefined,
    };
    companiesSearchQuery({
      variables: {
        filter,
        offset: pagination.page * pagination.pageSize,
        limit: pagination.pageSize,
        sortOrder: selectedArea
          ? CompanySortOrderEnum.DistnaceAsc
          : CompanySortOrderEnum.MentionCountDesc,
      },
    });
  }, [
    companiesSearchQuery,
    pagination.page,
    pagination.pageSize,
    selectedArea,
    specialtyIds,
    term,
  ]);

  const onPageChange = (page: number, pageSize?: number) => {
    setPagination((pagination) => {
      const newPage = page - 1;
      const newPageSize = pageSize || pagination.pageSize;
      return { page: newPage, pageSize: newPageSize };
    });
  };

  const companiesDataSource = React.useMemo(() => {
    const coordinates = selectedArea?.coordinates;
    let companiesData = companies.map((company) => ({
      id: company.id,
      name: company.name,
      distance: coordinates
        ? getDistanceBetweenCoordinates(
            coordinates,
            company.address.coordinates
          )
        : 0,
      address: company.address,
      specialties: company.specialties,
      contact: company.contact,
      images: company.images,
      memo: company.memo,
      updatedAt: company.updatedAt,
      mentionCount: company.mentionCount,
    }));

    if (coordinates) {
      companiesData = companiesData.sort((a, b) => a.distance - b.distance);
    }

    return companiesData;
  }, [companies, selectedArea]);

  const columns = React.useMemo(() => {
    return [
      {
        key: '',
        dataIndex: 'id',
        width: 36,
        title: '',
        render: (companyId: string) => {
          return (
            <Checkbox
              checked={selectedCompanyIds.includes(companyId)}
              onChange={(e) => {
                if (e.target.checked) {
                  if (selectedCompanyIds.length >= 3) {
                    notification.info({
                      message: '정비소 추천은 3개까지만 가능합니다.',
                    });
                    return;
                  }
                  setSelectedCompanyIds([...selectedCompanyIds, companyId]);
                } else {
                  setSelectedCompanyIds(
                    selectedCompanyIds.filter(
                      (selectedCompanyId) => selectedCompanyId !== companyId
                    )
                  );
                }
              }}
            />
          );
        },
      },
      ...(selectedArea
        ? [
            {
              key: 'distance',
              dataIndex: 'distance',
              title: '거리',
              width: 72,
              render: (distance: number) => {
                const isFar = distance > selectedArea.radius / 1000;
                return (
                  <Tag color={isFar ? 'red' : 'blue'}>{`${distance}km`}</Tag>
                );
              },
            },
          ]
        : []),
      {
        key: 'mentionCount',
        dataIndex: 'mentionCount',
        title: '추천수',
        width: 64,
        render: (mentionCount: number) => <Text>{mentionCount}</Text>,
      },
      {
        key: 'name',
        dataIndex: 'name',
        title: '업체명',
        width: 160,
        render: (name: string) => `${name}`,
      },
      {
        key: 'address',
        dataIndex: 'address',
        title: '주소',
        width: 220,
        ellipsis: true,
        render: (address: Address) => (
          <Tooltip
            placement="top"
            title={
              <Text copyable>
                {address.road
                  ? address.road
                  : address.region
                  ? address.region
                  : '정보 없음'}
              </Text>
            }
          >
            <Button
              size="small"
              style={{ marginRight: 8 }}
              onClick={(e) => {
                e.stopPropagation();
                window.open(
                  `https://map.doctor-cha.com/?lat=${
                    address.coordinates.latitude
                  }&lon=${address.coordinates.longitude}&distance=5&address=${
                    address.road || address.region
                  }`,
                  'doctor-cha-company-detail',
                  'width=1676, height=1207'
                );
              }}
              icon={<EnvironmentOutlined />}
            />
            <Text>
              {address.road
                ? address.road
                : address.region
                ? address.region
                : '정보 없음'}
            </Text>
          </Tooltip>
        ),
      },
      {
        key: 'contact',
        dataIndex: 'contact',
        title: '연락처',
        width: 128,
        render: (contact: string) => contactFormatter(contact),
      },
      {
        key: 'images',
        dataIndex: 'images',
        title: '이미지',
        width: 110,
        ellipsis: true,
        render: (images: ImageType[]) => (
          <Image.PreviewGroup>
            {images.map((image, index) => (
              <Image
                key={index}
                src={image.url}
                width={36}
                height={36}
                style={{ objectFit: 'cover' }}
              />
            ))}
          </Image.PreviewGroup>
        ),
      },
      {
        key: 'specialties',
        dataIndex: 'specialties',
        title: '전문분야',
        width: 180,
        render: (specialties: Specialty[]) => {
          return (
            <span>
              {specialties.map((specialty: Specialty) => (
                <Tag key={specialty.id}>{specialty.name}</Tag>
              ))}
            </span>
          );
        },
      },
      {
        key: 'memo',
        dataIndex: 'memo',
        title: '메모',
        render: (memo: string) => memo,
      },
      {
        key: 'updatedAt',
        dataIndex: 'updatedAt',
        title: '마지막 편집일',
        width: 110,
        render: (updatedAt: string) => (
          <Text disabled>{dayjs(updatedAt).fromNow()}</Text>
        ),
      },
    ];
  }, [selectedArea, selectedCompanyIds]);

  return (
    <Modal
      open={open}
      onCancel={(e) => {
        e.stopPropagation();
        onClose();
        clearState();
      }}
      onOk={(e) => {
        e.stopPropagation();
        onSelectCompanies(selectedCompanyIds);
        clearState();
      }}
      width={'90%'}
      title="업체 검색 및 추천"
    >
      {postData?.post && postData.post.areas.length > 0 && (
        <AreasBox>
          <Text>희망 지역:</Text>
          <AreaButtonsBox>
            <Button
              size="small"
              type={selectedArea === null ? 'primary' : 'default'}
              onClick={() => {
                setPagination({
                  ...pagination,
                  page: 0,
                });
                setSelectedArea(null);
              }}
            >
              없음
            </Button>
            {postData.post.areas.map((area, index) => {
              return (
                <Button
                  size="small"
                  type={
                    selectedArea && selectedArea.index === index
                      ? 'primary'
                      : 'default'
                  }
                  key={`area_${index}`}
                  onClick={() => {
                    setPagination({
                      ...pagination,
                      page: 0,
                    });
                    setSelectedArea({
                      coordinates: {
                        latitude: area.coordinates.latitude,
                        longitude: area.coordinates.longitude,
                      },
                      radius: area.radius,
                      index: index,
                    });
                  }}
                >
                  <AimOutlined />
                  {`${area.zone?.district} ${area.zone?.neighborhood} - ${
                    Math.round((area.radius / 1000) * 100) / 100
                  }km`}
                </Button>
              );
            })}
            <Divider type="vertical" />
            <Tooltip
              placement="right"
              title="지역 선택 시 최대 50km반경으로 검색합니다. 50km가 넘는 업체는 조회되지 않습니다."
            >
              <InfoCircleOutlined />
            </Tooltip>
          </AreaButtonsBox>
        </AreasBox>
      )}

      <FilterBox>
        <Select
          mode="multiple"
          allowClear
          placeholder="전문분야 선택"
          onChange={setSpecialtyIds}
          style={{ flex: '0 0 400px' }}
          options={
            companySpecialtiesData &&
            companySpecialtiesData.companySpecialties
              .map((specialty) => ({
                label: specialty.name,
                value: specialty.id,
              }))
              .sort((a, b) => a.label.localeCompare(b.label))
          }
          filterOption={(input, option) => !!option?.label.includes(input)}
        />
        <Input.Search
          allowClear
          placeholder="업체명, 메모, 주소로 검색"
          onSearch={(value) => setTerm(value)}
          style={{ flex: 1 }}
        />
      </FilterBox>
      <Table
        bordered
        columns={columns}
        dataSource={companiesDataSource}
        pagination={{
          total: count,
          current: pagination.page + 1,
          pageSize: pagination.pageSize,
          onChange: onPageChange,
          position: ['topLeft'],
        }}
        sticky
        loading={loading}
        size="small"
        rowKey={(company) => company.id}
      />
      {selectedCompanyIds.length > 0 && (
        <>
          <SelectedShopTitleBox>
            <Text strong>선택된 업체 :</Text>
            <Button onClick={() => setSelectedCompanyIds([])}>전체 해제</Button>
          </SelectedShopTitleBox>

          <SelectedShopCardsBox>
            {selectedCompanyIds.map((selectedCompanyId) => (
              <CompanyCard
                key={`company_${selectedCompanyId}`}
                companyId={selectedCompanyId}
                onClose={(companyId) => {
                  setSelectedCompanyIds(
                    selectedCompanyIds.filter(
                      (selectedCompanyId) => selectedCompanyId !== companyId
                    )
                  );
                }}
              />
            ))}
          </SelectedShopCardsBox>
        </>
      )}
    </Modal>
  );
};

const AreasBox = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
`;

const AreaButtonsBox = styled.div`
  margin-left: 14px;
  display: flex;
  height: 24px;
  flex: 1;
  gap: 6px;
  overflow-x: auto;
`;

const FilterBox = styled.div`
  margin: 12px 0px;
  display: flex;
  gap: 8px;
`;

const SelectedShopTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0px;
`;

const SelectedShopCardsBox = styled.div`
  display: flex;
  gap: 12px;
  overflow-x: auto;
`;

export default CompanySearchModal;
