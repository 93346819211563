import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';

type FilePreviewProps = {
  src: string;
  type: 'image' | 'video';
  onClose: (clicked: string) => void;
};

const FilePreview: React.FC<FilePreviewProps> = ({ src, type, onClose }) => {
  return (
    <Container>
      <Button
        size={'small'}
        style={{ position: 'absolute', right: -12 }}
        shape="circle"
        icon={<CloseOutlined />}
        onClick={() => onClose(src)}
      />
      {type === 'image' ? (
        <Image src={src} />
      ) : (
        <Video controls preload="metadata">
          <source src={src}></source>
        </Video>
      )}
    </Container>
  );
};

export default FilePreview;

const Container = styled.div`
  width: 80px;
  flex: 0 0 80px;
  height: 90px;
  position: relative;
`;

const Video = styled.video`
  width: 80px;
  height: 80px;
  margin-top: 10px;
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  margin-top: 10px;
`;
