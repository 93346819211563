import { SaveOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import {
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Switch,
  TimePicker,
  Typography,
  Upload,
  UploadFile,
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { DEFAULT_OPERATING_TIME_TIME_RANGE } from '../Create';

import { useFileUpload } from 'src/hooks/useFileUpload';
import {
  HandleNationality,
  OperatingTime,
  UpdateCompanyInput,
  useCompanyFacilitiesQuery,
  useCompanyQuery,
  useCompanyRecommendationsQuery,
  useCompanySpecialtiesQuery,
  useCompanyTypesQuery,
  usePlacesLazyQuery,
  useUpdateCompanyMutation,
} from 'src/utils/client';
import { getBase64 } from 'src/utils/file';
import {
  businessNumberFormatter,
  companyTypeFormatter,
  contactFormatter,
  momentRangeToTimeRange,
  timeRangeToMomentRange,
} from 'src/utils/formatter';
import {
  isContact,
  isKoreanOrAlphabet,
  isPhoneNumber,
  isValidBusinessNumber,
} from 'src/utils/validator';

export enum IndexedDayOfWeek {
  Mon = 'MON',
  Tue = 'TUE',
  Wed = 'WED',
  Thu = 'THU',
  Fri = 'FRI',
  Sat = 'SAT',
  Sun = 'SUN',
}

gql`
  mutation updateCompany($companyId: ID, $data: UpdateCompanyInput!) {
    updateCompany(companyId: $companyId, data: $data) {
      ... on UpdateCompanySuccess {
        company {
          id
        }
      }

      ... on UpdateCompanyFail {
        error {
          message
        }
      }
    }
  }
`;

const DEFAULT_OPERATING_TIME = {
  closesOnHoliday: false,
  hoursByDay: {
    MON: null,
    TUE: null,
    WED: null,
    TUR: null,
    FRI: null,
    SAT: null,
    SUN: null,
  },
  remark: null,
};

const { Text } = Typography;

const EditCompanyPage: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const navigate = useNavigate();
  const { data, refetch } = useCompanyQuery({
    variables: {
      // @ts-ignore apollo client bug
      companyId,
    },
    skip: !companyId,
  });

  const [placeQuery, { data: placeData }] = usePlacesLazyQuery();

  const { data: companyTypesData } = useCompanyTypesQuery();
  const { data: recommendationsData } = useCompanyRecommendationsQuery();
  const { data: specialtiesData } = useCompanySpecialtiesQuery();
  const { data: facilitiesData } = useCompanyFacilitiesQuery();
  const { upload } = useFileUpload();
  const [updateCompany] = useUpdateCompanyMutation();
  const [operatingTime, setOperatingTime] = React.useState<OperatingTime>(
    DEFAULT_OPERATING_TIME
  );

  const [previewImage, setPreviewImage] = React.useState('');
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [recommendationIds, setRecommendationIds] = React.useState<string[]>(
    []
  );
  const [facilityIds, setFacilityIds] = React.useState<string[]>([]);
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (!data?.company) {
      return;
    }

    if (data.company.operatingTime) {
      const queriesOperatingTime = data.company.operatingTime;
      setOperatingTime({
        remark: queriesOperatingTime.remark,
        closesOnHoliday: queriesOperatingTime.closesOnHoliday,
        hoursByDay: {
          MON: queriesOperatingTime.hoursByDay.MON
            ? {
                from: {
                  hour: queriesOperatingTime.hoursByDay.MON.from.hour,
                  minute: queriesOperatingTime.hoursByDay.MON.from.minute,
                  second: queriesOperatingTime.hoursByDay.MON.from.second,
                },
                to: {
                  hour: queriesOperatingTime.hoursByDay.MON.to.hour,
                  minute: queriesOperatingTime.hoursByDay.MON.to.minute,
                  second: queriesOperatingTime.hoursByDay.MON.to.second,
                },
              }
            : null,
          TUE: queriesOperatingTime.hoursByDay.TUE
            ? {
                from: {
                  hour: queriesOperatingTime.hoursByDay.TUE.from.hour,
                  minute: queriesOperatingTime.hoursByDay.TUE.from.minute,
                  second: queriesOperatingTime.hoursByDay.TUE.from.second,
                },
                to: {
                  hour: queriesOperatingTime.hoursByDay.TUE.to.hour,
                  minute: queriesOperatingTime.hoursByDay.TUE.to.minute,
                  second: queriesOperatingTime.hoursByDay.TUE.to.second,
                },
              }
            : null,
          WED: queriesOperatingTime.hoursByDay.WED
            ? {
                from: {
                  hour: queriesOperatingTime.hoursByDay.WED.from.hour,
                  minute: queriesOperatingTime.hoursByDay.WED.from.minute,
                  second: queriesOperatingTime.hoursByDay.WED.from.second,
                },
                to: {
                  hour: queriesOperatingTime.hoursByDay.WED.to.hour,
                  minute: queriesOperatingTime.hoursByDay.WED.to.minute,
                  second: queriesOperatingTime.hoursByDay.WED.to.second,
                },
              }
            : null,
          TUR: queriesOperatingTime.hoursByDay.TUR
            ? {
                from: {
                  hour: queriesOperatingTime.hoursByDay.TUR.from.hour,
                  minute: queriesOperatingTime.hoursByDay.TUR.from.minute,
                  second: queriesOperatingTime.hoursByDay.TUR.from.second,
                },
                to: {
                  hour: queriesOperatingTime.hoursByDay.TUR.to.hour,
                  minute: queriesOperatingTime.hoursByDay.TUR.to.minute,
                  second: queriesOperatingTime.hoursByDay.TUR.to.second,
                },
              }
            : null,
          FRI: queriesOperatingTime.hoursByDay.FRI
            ? {
                from: {
                  hour: queriesOperatingTime.hoursByDay.FRI.from.hour,
                  minute: queriesOperatingTime.hoursByDay.FRI.from.minute,
                  second: queriesOperatingTime.hoursByDay.FRI.from.second,
                },
                to: {
                  hour: queriesOperatingTime.hoursByDay.FRI.to.hour,
                  minute: queriesOperatingTime.hoursByDay.FRI.to.minute,
                  second: queriesOperatingTime.hoursByDay.FRI.to.second,
                },
              }
            : null,
          SAT: queriesOperatingTime.hoursByDay.SAT
            ? {
                from: {
                  hour: queriesOperatingTime.hoursByDay.SAT.from.hour,
                  minute: queriesOperatingTime.hoursByDay.SAT.from.minute,
                  second: queriesOperatingTime.hoursByDay.SAT.from.second,
                },
                to: {
                  hour: queriesOperatingTime.hoursByDay.SAT.to.hour,
                  minute: queriesOperatingTime.hoursByDay.SAT.to.minute,
                  second: queriesOperatingTime.hoursByDay.SAT.to.second,
                },
              }
            : null,
          SUN: queriesOperatingTime.hoursByDay.SUN
            ? {
                from: {
                  hour: queriesOperatingTime.hoursByDay.SUN.from.hour,
                  minute: queriesOperatingTime.hoursByDay.SUN.from.minute,
                  second: queriesOperatingTime.hoursByDay.SUN.from.second,
                },
                to: {
                  hour: queriesOperatingTime.hoursByDay.SUN.to.hour,
                  minute: queriesOperatingTime.hoursByDay.SUN.to.minute,
                  second: queriesOperatingTime.hoursByDay.SUN.to.second,
                },
              }
            : null,
        },
      });
    }

    if (data.company.recommendations.length > 0) {
      setRecommendationIds(
        data.company.recommendations.map((recommendation) => recommendation.id)
      );
    }

    if (data.company.facilities.length > 0) {
      setFacilityIds(data.company.facilities.map((facility) => facility.id));
    }

    form.setFields([
      {
        name: 'isPartner',
        value: data.company.isPartner,
      },
      {
        name: 'certificated',
        value: data.company.certificated,
      },
      {
        name: 'name',
        value: data.company.name,
      },
      {
        name: 'images',
        value: data.company.images,
      },
      {
        name: 'address',
        value: data.company.address.road
          ? data.company.address.road
          : data.company.address.region,
      },
      {
        name: 'detailAddress',
        value: data.company.address.detail,
      },
      {
        name: 'contact',
        value: contactFormatter(data.company.contact),
      },
      {
        name: 'extraContact',
        value: data.company.extraContact
          ? contactFormatter(data.company.extraContact)
          : '',
      },
      {
        name: 'type',
        value: data.company.type,
      },
      {
        name: 'bio',
        value: data.company.bio,
      },
      {
        name: 'representativeName',
        value: data.company.businessRepresentativeName,
      },
      {
        name: 'registrationNumber',
        value: data.company.businessRegistrationNumber,
      },
      {
        name: 'memo',
        value: data.company.memo,
      },
      {
        name: 'handleNationality',
        value: data.company.handleNationality,
      },
      {
        name: 'specialties',
        value: data.company.specialties.map((specialty) => ({
          value: specialty.id,
          label: specialty.name,
        })),
      },
    ]);
  }, [data, form]);

  const getImageFileList = (args: any) => {
    const removedFileUrl =
      args.file.status === 'removed'
        ? args.file.url || args.file.response.url
        : null;

    if (removedFileUrl) {
      const originFileList = form.getFieldValue('images');
      const filtered = originFileList.filter(
        (file: any) =>
          file.url !== removedFileUrl && file.response?.url !== removedFileUrl
      );

      return filtered;
    }

    return args.fileList;
  };

  const handleClickPreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const uploadShopPhoto = ({
    file,
    onSuccess,
    onError,
  }: UploadRequestOption) => {
    if (file instanceof File) {
      upload(file)
        .then((res) => {
          onSuccess?.(res, new XMLHttpRequest());
        })
        .catch(onError);
    }
  };

  const handleClickSave = async () => {
    if (!companyId) {
      return;
    }

    if (!data?.company) {
      return;
    }

    const contact = form.getFieldValue('contact')?.replace(/\D/g, '');
    if (!isContact(contact)) {
      notification.error({ message: '유효하지 않은 연락처입니다.' });
      return;
    }
    const extraContact = form.getFieldValue('extraContact')?.replace(/\D/g, '');
    const registrationNumber = form
      .getFieldValue('registrationNumber')
      ?.replace(/\D/g, '');

    const images = form.getFieldValue('images') as UploadFile[];
    const operatingTimeIsNull =
      !operatingTime.hoursByDay.MON &&
      !operatingTime.hoursByDay.TUE &&
      !operatingTime.hoursByDay.WED &&
      !operatingTime.hoursByDay.TUR &&
      !operatingTime.hoursByDay.FRI &&
      !operatingTime.hoursByDay.SAT &&
      !operatingTime.hoursByDay.SUN;

    const formData: UpdateCompanyInput = {
      name: form.getFieldValue('name'),
      isPartner: form.getFieldValue('isPartner'),
      certificated: form.getFieldValue('certificated'),
      address: form.getFieldValue('address'),
      detailAddress: form.getFieldValue('detailAddress'),
      type: form.getFieldValue('type'),
      bio: form.getFieldValue('bio') || null,
      representativeName: form.getFieldValue('representativeName') || null,
      registrationNumber: registrationNumber || null,
      memo: form.getFieldValue('memo'),
      contact: contact,
      extraContact:
        extraContact && extraContact.length > 0 ? extraContact : undefined,
      specialtyIds: form
        .getFieldValue('specialties')
        .map((specialty: { value: string; label: string }) => specialty.value),
      operatingTime: operatingTimeIsNull ? null : operatingTime,
      imageUrls: images
        .map((image) => (image.url ? image.url : image.response.url))
        .filter((url): url is string => !!url),
      recommendationIds,
      facilityIds,
      handleNationality:
        form.getFieldValue('handleNationality') ||
        data.company.handleNationality,
    };

    if (formData.name === data.company.name) {
      formData.name = undefined;
    }

    if (formData.isPartner === data.company.isPartner) {
      formData.isPartner = undefined;
    }

    if (formData.certificated === data.company.certificated) {
      formData.certificated = undefined;
    }

    if (
      formData.address === data.company.address.road ||
      formData.address === data.company.address.region
    ) {
      formData.address = undefined;
    }

    if (formData.detailAddress === data.company.address.detail) {
      formData.detailAddress = undefined;
    } else if (formData.detailAddress === '') {
      formData.detailAddress = null;
    }

    if (formData.type === data.company.type) {
      formData.type = undefined;
    }

    if (formData.bio === data.company.bio) {
      formData.bio = undefined;
    } else if (formData.bio === '') {
      formData.bio = null;
    }

    if (
      formData.representativeName === data.company.businessRepresentativeName
    ) {
      formData.representativeName = undefined;
    } else if (formData.representativeName === '') {
      formData.representativeName = null;
    } else if (
      formData.representativeName &&
      !isKoreanOrAlphabet(formData.representativeName)
    ) {
      notification.error({
        message: '대표명은 한글만, 2~10자리까지 입력 가능합니다.',
      });
      return;
    }

    if (
      formData.registrationNumber === data.company.businessRegistrationNumber
    ) {
      formData.registrationNumber = undefined;
    } else if (formData.registrationNumber === '') {
      formData.registrationNumber = null;
    } else if (
      formData.registrationNumber &&
      !isValidBusinessNumber(formData.registrationNumber)
    ) {
      notification.error({ message: '유효하지 않은 사업자 등록번호입니다.' });
      return;
    }

    if (formData.memo === data.company.memo) {
      formData.memo = undefined;
    } else if (formData.memo === '') {
      formData.memo = null;
    }

    if (formData.contact === data.company.contact) {
      formData.contact = undefined;
    } else if (formData.contact && !isContact(formData.contact)) {
      notification.error({ message: '유효하지 않은 연락처입니다.' });
      return;
    }

    if (formData.extraContact === data.company.extraContact) {
      formData.extraContact = undefined;
    } else if (formData.extraContact === '') {
      formData.extraContact = null;
    } else if (
      formData.extraContact &&
      !isContact(formData.extraContact) &&
      !isPhoneNumber(formData.extraContact)
    ) {
      notification.error({ message: '추가연락처가 유효한 연락처가 아닙니다.' });
      return;
    }

    const updateCompanyMutationResult = await updateCompany({
      variables: {
        companyId,
        data: formData,
      },
      onCompleted: () => {
        navigate(`/company/list/detail/${companyId}`);
      },
    });

    if (
      updateCompanyMutationResult.data?.updateCompany.__typename ===
      'UpdateCompanySuccess'
    ) {
      const updatedCompanyId =
        updateCompanyMutationResult.data.updateCompany.company.id;

      refetch({
        companyId: updatedCompanyId,
      });

      notification.success({
        message: '정비소가 정상적으로 업데이트 되었습니다.',
      });
    } else if (
      updateCompanyMutationResult.data?.updateCompany.__typename ===
      'UpdateCompanyFail'
    ) {
      notification.error({
        message: updateCompanyMutationResult.data.updateCompany.error.message,
      });
    }
  };

  const options = React.useMemo(() => {
    if (!placeData?.places) {
      return [];
    }

    return placeData?.places.map((place, index) => {
      return (
        <AutoComplete.Option key={`${place.name}_${index}`} value={place.name}>
          {`${place.name} (${
            place.roadAddress ? place.roadAddress : place.regionAddress
          })`}
        </AutoComplete.Option>
      );
    });
  }, [placeData?.places]);

  const specialtyOptions = React.useMemo(() => {
    const options =
      specialtiesData?.companySpecialties &&
      [...specialtiesData.companySpecialties].map((specialty) => ({
        value: specialty.id,
        label: specialty.name,
      }));
    return options?.sort((a, b) => a.label.localeCompare(b.label)) || [];
  }, [specialtiesData?.companySpecialties]);

  return (
    <Container>
      {data?.company && (
        <>
          <Form
            labelCol={{ xxl: 2, xl: 3, md: 4 }}
            layout="horizontal"
            form={form}
          >
            <Divider orientation="left">기본정보</Divider>
            <Form.Item name="name" label="업체명" required>
              <AutoComplete
                onChange={(value) => {
                  if (value.length < 2) {
                    return;
                  }
                  placeQuery({
                    variables: {
                      term: value,
                      first: 5,
                    },
                  });
                }}
                onSelect={(selectedName: string) => {
                  const selectedPlace = placeData?.places.find(
                    (place) => place.name === selectedName
                  );

                  if (!selectedPlace) {
                    return;
                  }

                  form.setFieldValue('name', selectedPlace.name);

                  const address = selectedPlace.roadAddress
                    ? selectedPlace.roadAddress
                    : selectedPlace.regionAddress;
                  if (address) {
                    form.setFieldValue('address', address);
                  }

                  if (selectedPlace.contact) {
                    form.setFieldValue(
                      'contact',
                      contactFormatter(selectedPlace.contact)
                    );
                  }
                }}
                placeholder="업체명을 입력해주세요"
              >
                {options}
              </AutoComplete>
            </Form.Item>
            <Form.Item name="isPartner" label="예약 가능(파트너)" required>
              <Switch
                onChange={(checked) => form.setFieldValue('isPartner', checked)}
                defaultChecked={!!data.company.isPartner}
              />
            </Form.Item>
            <Form.Item name="certificated" label="닥터차 인증" required>
              <Switch
                onChange={(checked) =>
                  form.setFieldValue('certificated', checked)
                }
                defaultChecked={data.company.certificated}
              />
            </Form.Item>
            <Form.Item name="address" label="주소" required>
              <Input
                placeholder="업체명 검색 후 입력해주세요"
                onChange={(e) => form.setFieldValue('address', e.target.value)}
              />
            </Form.Item>
            <Form.Item name="detailAddress" label="상세주소">
              <Input
                placeholder="상세주소를 입력해주세요. (ex. 1층 101호)"
                onChange={(e) =>
                  form.setFieldValue('detailAddress', e.target.value)
                }
              />
            </Form.Item>
            <Form.Item name="contact" label="연락처" required>
              <Input
                placeholder="업체의 연락처로, 숫자만 입력해주세요. (ex. 02-123-1234)"
                onChange={(e) => {
                  const replaced = e.target.value.replace(/[^0-9]/g, '');
                  form.setFieldValue('contact', contactFormatter(replaced));
                }}
              />
            </Form.Item>
            <Form.Item name="extraContact" label="추가 연락처">
              <Input
                placeholder="숫자만 입력해주세요. (ex. 01012345678)"
                onChange={(e) => {
                  const replaced = e.target.value.replace(/[^0-9]/g, '');
                  form.setFieldValue(
                    'extraContact',
                    contactFormatter(replaced)
                  );
                }}
              />
            </Form.Item>
            <Form.Item name="handleNationality" label="취급 차량 국적" required>
              <Radio.Group
                name="handleNationality"
                onChange={(e) =>
                  form.setFieldValue('handleNationality', e.target.value)
                }
                defaultValue={data.company.handleNationality}
              >
                <Radio value={HandleNationality.OnlyDomestic}>국산차</Radio>
                <Radio value={HandleNationality.OnlyForeign}>수입차</Radio>
                <Radio value={HandleNationality.All}>국산차/수입차</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="type" label="업체 유형" required>
              <Select
                onChange={(type) => form.setFieldValue('type', type)}
                placeholder="업체 유형을 선택해주세요 (ex. 정비공장)"
                options={companyTypesData?.companyTypes.map((companyType) => ({
                  label: companyTypeFormatter(companyType),
                  value: companyType,
                }))}
              />
            </Form.Item>
            <Form.Item name="specialties" label="전문 분야">
              <Select
                maxLength={5}
                mode="multiple"
                onChange={(_, option) => {
                  form.setFieldValue('specialties', option);
                }}
                filterOption={(inputValue, option) =>
                  (option?.label
                    ?.toLowerCase()
                    ?.indexOf?.(inputValue.toLowerCase()) || 0) >= 0
                }
                allowClear
                placeholder="배치한 순서대로 고객쪽에서 보여집니다. (최대 5개)"
                options={specialtyOptions}
              />
            </Form.Item>
            <Form.Item label="영업 시간">
              <TimeSelectorBox>
                <Card type="inner" title="시간 설정">
                  <OperatingTimesBox>
                    <OperatingTimeRow>
                      <span>월요일</span>
                      <TimePicker.RangePicker
                        format={'HH:mm'}
                        disabled={operatingTime.hoursByDay.MON === null}
                        value={
                          operatingTime.hoursByDay.MON
                            ? timeRangeToMomentRange(
                                operatingTime.hoursByDay.MON
                              )
                            : undefined
                        }
                        onChange={(changedTime) => {
                          if (changedTime) {
                            const from = changedTime[0];
                            const to = changedTime[1];

                            if (from && to) {
                              setOperatingTime({
                                ...operatingTime,
                                hoursByDay: {
                                  ...operatingTime.hoursByDay,
                                  MON: momentRangeToTimeRange([from, to]),
                                },
                              });
                            }
                          }
                        }}
                      />
                    </OperatingTimeRow>
                    <OperatingTimeRow>
                      <span>화요일</span>
                      <TimePicker.RangePicker
                        format={'HH:mm'}
                        disabled={operatingTime.hoursByDay.TUE === null}
                        value={
                          operatingTime.hoursByDay.TUE
                            ? timeRangeToMomentRange(
                                operatingTime.hoursByDay.TUE
                              )
                            : undefined
                        }
                        onChange={(changedTime) => {
                          if (changedTime) {
                            const from = changedTime[0];
                            const to = changedTime[1];

                            if (from && to) {
                              setOperatingTime({
                                ...operatingTime,
                                hoursByDay: {
                                  ...operatingTime.hoursByDay,
                                  TUE: momentRangeToTimeRange([from, to]),
                                },
                              });
                            }
                          }
                        }}
                      />
                    </OperatingTimeRow>
                    <OperatingTimeRow>
                      <span>수요일</span>
                      <TimePicker.RangePicker
                        format={'HH:mm'}
                        disabled={operatingTime.hoursByDay.WED === null}
                        value={
                          operatingTime.hoursByDay.WED
                            ? timeRangeToMomentRange(
                                operatingTime.hoursByDay.WED
                              )
                            : undefined
                        }
                        onChange={(changedTime) => {
                          if (changedTime) {
                            const from = changedTime[0];
                            const to = changedTime[1];

                            if (from && to) {
                              setOperatingTime({
                                ...operatingTime,
                                hoursByDay: {
                                  ...operatingTime.hoursByDay,
                                  WED: momentRangeToTimeRange([from, to]),
                                },
                              });
                            }
                          }
                        }}
                      />
                    </OperatingTimeRow>
                    <OperatingTimeRow>
                      <span>목요일</span>
                      <TimePicker.RangePicker
                        format={'HH:mm'}
                        disabled={operatingTime.hoursByDay.TUR === null}
                        value={
                          operatingTime.hoursByDay.TUR
                            ? timeRangeToMomentRange(
                                operatingTime.hoursByDay.TUR
                              )
                            : undefined
                        }
                        onChange={(changedTime) => {
                          if (changedTime) {
                            const from = changedTime[0];
                            const to = changedTime[1];

                            if (from && to) {
                              setOperatingTime({
                                ...operatingTime,
                                hoursByDay: {
                                  ...operatingTime.hoursByDay,
                                  TUR: momentRangeToTimeRange([from, to]),
                                },
                              });
                            }
                          }
                        }}
                      />
                    </OperatingTimeRow>
                    <OperatingTimeRow>
                      <span>금요일</span>
                      <TimePicker.RangePicker
                        format={'HH:mm'}
                        disabled={operatingTime.hoursByDay.FRI === null}
                        value={
                          operatingTime.hoursByDay.FRI
                            ? timeRangeToMomentRange(
                                operatingTime.hoursByDay.FRI
                              )
                            : undefined
                        }
                        onChange={(changedTime) => {
                          if (changedTime) {
                            const from = changedTime[0];
                            const to = changedTime[1];

                            if (from && to) {
                              setOperatingTime({
                                ...operatingTime,
                                hoursByDay: {
                                  ...operatingTime.hoursByDay,
                                  FRI: momentRangeToTimeRange([from, to]),
                                },
                              });
                            }
                          }
                        }}
                      />
                    </OperatingTimeRow>
                    <OperatingTimeRow>
                      <span>토요일</span>
                      <TimePicker.RangePicker
                        format={'HH:mm'}
                        disabled={operatingTime.hoursByDay.SAT === null}
                        value={
                          operatingTime.hoursByDay.SAT
                            ? timeRangeToMomentRange(
                                operatingTime.hoursByDay.SAT
                              )
                            : undefined
                        }
                        onChange={(changedTime) => {
                          if (changedTime) {
                            const from = changedTime[0];
                            const to = changedTime[1];

                            if (from && to) {
                              setOperatingTime({
                                ...operatingTime,
                                hoursByDay: {
                                  ...operatingTime.hoursByDay,
                                  SAT: momentRangeToTimeRange([from, to]),
                                },
                              });
                            }
                          }
                        }}
                      />
                    </OperatingTimeRow>
                    <OperatingTimeRow>
                      <span>일요일</span>
                      <TimePicker.RangePicker
                        format={'HH:mm'}
                        disabled={operatingTime.hoursByDay.SUN === null}
                        value={
                          operatingTime.hoursByDay.SUN
                            ? timeRangeToMomentRange(
                                operatingTime.hoursByDay.SUN
                              )
                            : undefined
                        }
                        onChange={(changedTime) => {
                          if (changedTime) {
                            const from = changedTime[0];
                            const to = changedTime[1];

                            if (from && to) {
                              setOperatingTime({
                                ...operatingTime,
                                hoursByDay: {
                                  ...operatingTime.hoursByDay,
                                  SUN: momentRangeToTimeRange([from, to]),
                                },
                              });
                            }
                          }
                        }}
                      />
                    </OperatingTimeRow>
                  </OperatingTimesBox>
                </Card>
                <Card type="inner" title="휴일 설정">
                  <CardTitleCheckBox>
                    <Checkbox
                      style={{ display: 'flex', alignItems: 'center' }}
                      checked={operatingTime.hoursByDay.MON === null}
                      onChange={(e) => {
                        setOperatingTime({
                          ...operatingTime,
                          hoursByDay: {
                            ...operatingTime.hoursByDay,
                            MON: e.target.checked
                              ? null
                              : data.company?.operatingTime?.hoursByDay.MON
                              ? data.company?.operatingTime?.hoursByDay.MON
                              : DEFAULT_OPERATING_TIME_TIME_RANGE,
                          },
                        });
                      }}
                    >
                      월
                    </Checkbox>
                    <Checkbox
                      style={{ display: 'flex', alignItems: 'center' }}
                      checked={operatingTime.hoursByDay.TUE === null}
                      onChange={(e) => {
                        setOperatingTime({
                          ...operatingTime,
                          hoursByDay: {
                            ...operatingTime.hoursByDay,
                            TUE: e.target.checked
                              ? null
                              : data.company?.operatingTime?.hoursByDay.TUE
                              ? data.company?.operatingTime?.hoursByDay.TUE
                              : DEFAULT_OPERATING_TIME_TIME_RANGE,
                          },
                        });
                      }}
                    >
                      화
                    </Checkbox>
                    <Checkbox
                      style={{ display: 'flex', alignItems: 'center' }}
                      checked={operatingTime.hoursByDay.WED === null}
                      onChange={(e) => {
                        setOperatingTime({
                          ...operatingTime,
                          hoursByDay: {
                            ...operatingTime.hoursByDay,
                            WED: e.target.checked
                              ? null
                              : data.company?.operatingTime?.hoursByDay.WED
                              ? data.company?.operatingTime?.hoursByDay.WED
                              : DEFAULT_OPERATING_TIME_TIME_RANGE,
                          },
                        });
                      }}
                    >
                      수
                    </Checkbox>
                    <Checkbox
                      style={{ display: 'flex', alignItems: 'center' }}
                      checked={operatingTime.hoursByDay.TUR === null}
                      onChange={(e) => {
                        setOperatingTime({
                          ...operatingTime,
                          hoursByDay: {
                            ...operatingTime.hoursByDay,
                            TUR: e.target.checked
                              ? null
                              : data.company?.operatingTime?.hoursByDay.TUR
                              ? data.company?.operatingTime?.hoursByDay.TUR
                              : DEFAULT_OPERATING_TIME_TIME_RANGE,
                          },
                        });
                      }}
                    >
                      목
                    </Checkbox>
                    <Checkbox
                      style={{ display: 'flex', alignItems: 'center' }}
                      checked={operatingTime.hoursByDay.FRI === null}
                      onChange={(e) => {
                        setOperatingTime({
                          ...operatingTime,
                          hoursByDay: {
                            ...operatingTime.hoursByDay,
                            FRI: e.target.checked
                              ? null
                              : data.company?.operatingTime?.hoursByDay.FRI
                              ? data.company?.operatingTime?.hoursByDay.FRI
                              : DEFAULT_OPERATING_TIME_TIME_RANGE,
                          },
                        });
                      }}
                    >
                      금
                    </Checkbox>
                    <Checkbox
                      style={{ display: 'flex', alignItems: 'center' }}
                      checked={operatingTime.hoursByDay.SAT === null}
                      onChange={(e) => {
                        setOperatingTime({
                          ...operatingTime,
                          hoursByDay: {
                            ...operatingTime.hoursByDay,
                            SAT: e.target.checked
                              ? null
                              : data.company?.operatingTime?.hoursByDay.SAT
                              ? data.company?.operatingTime?.hoursByDay.SAT
                              : DEFAULT_OPERATING_TIME_TIME_RANGE,
                          },
                        });
                      }}
                    >
                      토
                    </Checkbox>
                    <Checkbox
                      style={{ display: 'flex', alignItems: 'center' }}
                      checked={operatingTime.hoursByDay.SUN === null}
                      onChange={(e) => {
                        setOperatingTime({
                          ...operatingTime,
                          hoursByDay: {
                            ...operatingTime.hoursByDay,
                            SUN: e.target.checked
                              ? null
                              : data.company?.operatingTime?.hoursByDay.SUN
                              ? data.company?.operatingTime?.hoursByDay.SUN
                              : DEFAULT_OPERATING_TIME_TIME_RANGE,
                          },
                        });
                      }}
                    >
                      일
                    </Checkbox>
                    <Checkbox
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '52px',
                      }}
                      checked={operatingTime.closesOnHoliday}
                      onChange={(e) => {
                        setOperatingTime({
                          ...operatingTime,
                          closesOnHoliday: e.target.checked,
                        });
                      }}
                    >
                      공휴일 휴무
                    </Checkbox>
                  </CardTitleCheckBox>
                </Card>
                <Form.Item
                  name="remark"
                  label="비고"
                  style={{ marginTop: '20px' }}
                >
                  <span style={{ fontSize: 0 }}>{operatingTime.remark}</span>
                  <Input.TextArea
                    placeholder="영업시간과 관련된 비고사항을 입력해주세요. (ex 둘째주, 넷째주 수요일 휴무)"
                    onChange={(e) => {
                      setOperatingTime({
                        ...operatingTime,
                        remark: e.target.value,
                      });
                    }}
                    autoSize
                    value={
                      operatingTime.remark ? operatingTime.remark : undefined
                    }
                    minLength={3}
                  />
                </Form.Item>
              </TimeSelectorBox>
            </Form.Item>
            <Form.Item
              name="images"
              label="업체 사진"
              valuePropName="fileList"
              getValueFromEvent={getImageFileList}
            >
              <Upload
                multiple
                accept="image/*"
                listType="picture-card"
                onPreview={handleClickPreview}
                customRequest={uploadShopPhoto}
              >
                + 추가
              </Upload>
            </Form.Item>
            <Modal
              open={previewOpen}
              title={`업체사진 미리보기`}
              footer={null}
              onCancel={() => setPreviewOpen(false)}
            >
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
            <Divider orientation="left">상세정보</Divider>
            <Form.Item label="닥터차 추천 이유">
              <Checkbox.Group
                style={{ padding: 4 }}
                value={recommendationIds}
                onChange={(e) => {
                  setRecommendationIds(e.map((id) => id.valueOf() as string));
                }}
              >
                <Row gutter={[12, 12]}>
                  {recommendationsData?.companyRecommendations.map(
                    (recommendation, index) => {
                      return (
                        <Col key={index} span={12}>
                          <Checkbox key={index} value={recommendation.id}>
                            <Text>
                              {recommendation.title}
                              <blockquote>
                                {recommendation.description}
                              </blockquote>
                            </Text>
                          </Checkbox>
                        </Col>
                      );
                    }
                  )}
                </Row>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item label="업체 소개" name="bio">
              <Input.TextArea
                size="middle"
                rows={8}
                placeholder="앱에서 보여지는 업체 소개입니다."
                onChange={(e) => form.setFieldValue('bio', e.target.value)}
              />
            </Form.Item>
            <Form.Item label="편의시설 및 서비스">
              <FacilitiesBox>
                {facilitiesData?.companyFacilities.map((facility, index) => {
                  return (
                    <Checkbox
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '0px',
                      }}
                      checked={facilityIds.includes(facility.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFacilityIds([...facilityIds, facility.id]);
                        } else {
                          setFacilityIds(
                            facilityIds.filter(
                              (facilityId) => facilityId !== facility.id
                            )
                          );
                        }
                      }}
                    >
                      {facility.name}
                    </Checkbox>
                  );
                })}
              </FacilitiesBox>
            </Form.Item>
            <Form.Item label="대표자명" name="representativeName">
              <Input
                placeholder="홍길동"
                onChange={(e) =>
                  form.setFieldValue('representativeName', e.target.value)
                }
              />
            </Form.Item>
            <Form.Item label="사업자등록번호" name="registrationNumber">
              <Input
                onChange={(e) =>
                  form.setFieldValue(
                    'registrationNumber',
                    businessNumberFormatter(e.target.value)
                  )
                }
              />
            </Form.Item>
            <Divider orientation="left">상담사 전용</Divider>
            <Form.Item label="업체 메모" name="memo">
              <Input
                placeholder="내부인에게만 보여지는 업체메모 공간입니다."
                onChange={(e) => form.setFieldValue('memo', e.target.value)}
              />
            </Form.Item>
            <Button
              icon={<SaveOutlined />}
              type="primary"
              style={{ width: '100%' }}
              onClick={handleClickSave}
            >
              저장하기
            </Button>
          </Form>
        </>
      )}
    </Container>
  );
};

export default EditCompanyPage;

const Container = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 52px;
  overflow-y: auto;
`;

const TimeSelectorBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CardTitleCheckBox = styled.div`
  display: flex;
  gap: 32px;
`;

const OperatingTimesBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const OperatingTimeRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const FacilitiesBox = styled.div`
  display: flex;
  gap: 16px;
`;
