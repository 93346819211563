import { purple, blue } from '@ant-design/colors';
import { SafetyCertificateTwoTone, TagTwoTone } from '@ant-design/icons';
import { gql } from '@apollo/client';
import {
  Select,
  Input,
  Checkbox,
  Table,
  Tag,
  Typography,
  Tooltip,
  Space,
  Image,
} from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  CompaniesFilter,
  CompanyType,
  useCompaniesCountQuery,
  useCompaniesQuery,
  useCompanySpecialtiesQuery,
  useCompanyTypesQuery,
  CompanySortOrderEnum,
} from 'src/utils/client';
import { companyTypeFormatter, contactFormatter } from 'src/utils/formatter';

gql`
  query companies(
    $filter: CompaniesFilter!
    $offset: Int!
    $limit: Int!
    $sortOrder: CompanySortOrderEnum!
  ) {
    companies(
      filter: $filter
      offset: $offset
      limit: $limit
      sortOrder: $sortOrder
    ) {
      id
      name
      specialties {
        id
        name
      }
      type
      images {
        url
      }
      contact
      extraContact
      address {
        road
        region
      }
      memo
      isPartner
      certificated
      updatedAt
      mentionCount
    }
  }
`;

gql`
  query companyTypes {
    companyTypes
  }
`;

gql`
  query companiesCount($filter: CompaniesFilter!) {
    companiesCount(filter: $filter)
  }
`;

export const LOAD_AMOUNT = 20;

const { Text } = Typography;

const CompanyList: React.FC = () => {
  const navigate = useNavigate();
  const { page } = useParams<{ page: string }>();
  const [searchParams] = useSearchParams();

  const currentFilter = React.useMemo(() => {
    const specialtyIds = searchParams.get('specialtyIds');
    const isPartner = searchParams.get('isPartner');
    const term = searchParams.get('term');
    const address = searchParams.get('address');
    const type = searchParams.get('type') as CompanyType;
    const queryStringFilter: CompaniesFilter = {};

    if (specialtyIds) {
      queryStringFilter.specialtyIds = JSON.parse(specialtyIds);
    }

    if (isPartner) {
      queryStringFilter.isPartner = isPartner === 'true';
    }

    if (term) {
      queryStringFilter.term = term;
    }

    if (type) {
      queryStringFilter.type = type;
    }

    if (address) {
      queryStringFilter.address = address;
    }

    return queryStringFilter;
  }, [searchParams]);

  const pageInfo = React.useMemo(() => {
    const currentPage = page ? Number(page) : 1;

    return {
      current: currentPage,
    };
  }, [page]);

  const { data, loading } = useCompaniesQuery({
    variables: {
      offset: (pageInfo.current - 1) * LOAD_AMOUNT,
      limit: LOAD_AMOUNT,
      filter: {
        ...currentFilter,
      },
      sortOrder: CompanySortOrderEnum.MentionCountDesc,
    },
    fetchPolicy: 'network-only',
  });

  const { data: companyTypesData } = useCompanyTypesQuery();
  const { data: companySpecialtiesData } = useCompanySpecialtiesQuery();
  const { data: companiesCountData } = useCompaniesCountQuery({
    variables: {
      filter: currentFilter,
    },
  });

  const handleSearchFilter = (filter: CompaniesFilter, movePage?: number) => {
    let searchParam = '';

    if (filter.term) {
      searchParam += `term=${filter.term}`;
    }

    if (filter.type) {
      if (searchParam.length > 0) {
        searchParam += '&';
      }
      searchParam += `type=${filter.type}`;
    }

    if (filter.isPartner) {
      if (searchParam.length > 0) {
        searchParam += '&';
      }

      searchParam += `isPartner=${filter.isPartner ? 'true' : 'false'}`;
    }

    if (filter.address) {
      if (searchParam.length > 0) {
        searchParam += '&';
      }
      searchParam += `address=${filter.address}`;
    }

    if (filter.specialtyIds && filter.specialtyIds.length > 0) {
      if (searchParam.length > 0) {
        searchParam += '&';
      }
      searchParam += `specialtyIds=${encodeURIComponent(
        JSON.stringify(filter.specialtyIds)
      )}`;
    }
    let pageInfo = '';

    if (movePage) {
      pageInfo = `/${movePage}`;
    }

    navigate(`/company/list${pageInfo}?${searchParam}`);
  };

  type CompanyTableData = {
    key: string;
    name: string;
    address: string;
    isPartner: boolean;
    images: string[];
    mentionCount: number;
    certificated: boolean;
    type?: string;
    specialties: { id: string; name: string }[];
    contact: string;
    memo?: string | null;
    updatedAt?: number;
  };

  const companyTableData = React.useMemo<CompanyTableData[]>(() => {
    if (!data?.companies) {
      return [];
    }

    return data.companies.map((company) => ({
      key: company.id,
      name: company.name,
      isPartner: company.isPartner,
      mentionCount: company.mentionCount,
      certificated: company.certificated,
      images: company.images.map((image) => image.url),
      address: (company.address.road
        ? company.address.road
        : company.address.region) as string,
      type: companyTypeFormatter(company.type),
      specialties: company.specialties.map((specialty) => ({
        id: specialty.id,
        name: specialty.name,
      })),
      contact: contactFormatter(company.contact),
      memo: company.memo,
      updatedAt: company.updatedAt,
    }));
  }, [data]);

  return (
    <Container>
      <Header>
        <Select
          placeholder="업체종류 선택"
          style={{ width: 160 }}
          value={currentFilter.type ? currentFilter.type : undefined}
          onChange={(value: CompanyType | 'none') => {
            if (value === 'none') {
              handleSearchFilter({ ...currentFilter, type: undefined });
            } else {
              handleSearchFilter({ ...currentFilter, type: value });
            }
          }}
        >
          {companyTypesData?.companyTypes.map((companyType, index) => (
            <Select.Option key={index} value={companyType}>
              {companyTypeFormatter(companyType)}
            </Select.Option>
          ))}
          <Select.Option value={'none'}>선택 안함</Select.Option>
        </Select>
        <Select
          mode="multiple"
          placeholder="전문분야 선택"
          style={{ width: 240 }}
          onChange={(value: string[]) => {
            handleSearchFilter({ ...currentFilter, specialtyIds: value });
          }}
          options={
            companySpecialtiesData &&
            companySpecialtiesData.companySpecialties
              .map((specialty) => ({
                label: specialty.name,
                value: specialty.id,
              }))
              .sort((a, b) => a.label.localeCompare(b.label))
          }
          filterOption={(input, option) => !!option?.label.includes(input)}
        />
        <Input.Search
          placeholder="주소 검색"
          style={{ width: 500 }}
          onSearch={(value) => {
            handleSearchFilter({ ...currentFilter, address: value });
          }}
          defaultValue={
            currentFilter.address ? currentFilter.address : undefined
          }
        />
        <Input.Search
          placeholder="업체명, 주소, 연락처로 검색"
          style={{ width: 500 }}
          onSearch={(value) => {
            handleSearchFilter({ ...currentFilter, term: value });
          }}
          defaultValue={currentFilter.term ? currentFilter.term : undefined}
        />
        <Checkbox
          style={{ marginLeft: '24px' }}
          onChange={(e) => {
            handleSearchFilter({
              ...currentFilter,
              isPartner: e.target.checked,
            });
          }}
          checked={currentFilter.isPartner || false}
        >
          <Text>파트너스 업체만 보기</Text>
        </Checkbox>
      </Header>
      <Table
        loading={loading}
        size="middle"
        bordered
        dataSource={companyTableData}
        pagination={{
          position: ['topLeft', 'bottomLeft'],
          total: companiesCountData?.companiesCount,
          current: pageInfo.current,
          pageSize: LOAD_AMOUNT,
          onChange: (page) => {
            handleSearchFilter(currentFilter, page);
          },
        }}
        sticky
        onRow={(data) => {
          return {
            onClick: () => {
              navigate(`/company/list/detail/${data.key}`);
            },
          };
        }}
      >
        <Table.Column
          title="공유횟수"
          key="mentionCount"
          width={70}
          render={(data: CompanyTableData) => {
            return <Text>{data.mentionCount}</Text>;
          }}
        />
        <Table.Column
          title="업체명"
          key="name"
          width={240}
          render={(data: CompanyTableData) => {
            return (
              <Space>
                {data.isPartner ? (
                  <Tooltip title="파트너">
                    <SafetyCertificateTwoTone twoToneColor={blue[5]} />
                  </Tooltip>
                ) : (
                  <></>
                )}
                {data.certificated ? (
                  <Tooltip title="인증">
                    <TagTwoTone twoToneColor={purple[5]} />
                  </Tooltip>
                ) : (
                  <></>
                )}
                <Text>{data.name}</Text>
              </Space>
            );
          }}
        />
        <Table.Column
          title="주소"
          key="address"
          width={202}
          render={(data: CompanyTableData) => {
            return <Text>{data.address}</Text>;
          }}
        />
        <Table.Column
          title="업체종류"
          key="type"
          width={120}
          render={(data: CompanyTableData) => {
            return <Tag color="geekblue">{data.type}</Tag>;
          }}
        />
        <Table.Column
          title="전문분야"
          key="tags"
          width={180}
          render={(data: CompanyTableData) => {
            return (
              <TagsBox>
                {data.specialties.map((specialty, index) => (
                  <Tag key={index}>{specialty.name}</Tag>
                ))}
              </TagsBox>
            );
          }}
        />
        <Table.Column
          title="메모"
          key="memo"
          render={(data: CompanyTableData) => {
            return <Text>{data.memo}</Text>;
          }}
        />
        <Table.Column
          title="연락처"
          key="contact"
          width={150}
          render={(data: CompanyTableData) => {
            return <Text>{data.contact}</Text>;
          }}
        />

        <Table.Column
          title="사진"
          key="images"
          width={130}
          render={(data: CompanyTableData) => {
            return (
              <Image.PreviewGroup>
                {data.images.map((image, index) => (
                  <Image
                    key={index}
                    src={image + '?w=288&h=288'}
                    width={36}
                    height={36}
                    style={{ objectFit: 'cover' }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                ))}
              </Image.PreviewGroup>
            );
          }}
        />
        <Table.Column
          title="수정일"
          key="updatedAt"
          width={100}
          render={(data: CompanyTableData) => {
            return <Text disabled>{dayjs(data.updatedAt).fromNow()}</Text>;
          }}
        />
      </Table>
    </Container>
  );
};

export default CompanyList;

const Container = styled.div`
  padding: 0px 24px;
  flex: 1;
`;

const Header = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const TagsBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
