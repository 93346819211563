import {
  CheckCircleFilled,
  InfoCircleFilled,
  MenuOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { gql } from '@apollo/client';
import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  Image,
  Menu,
  Modal,
  notification,
  Space,
  Spin,
  Tag,
  theme,
  Typography,
} from 'antd';
import React from 'react';
import styled from 'styled-components';

import CompanyCard from '../cards/CompanyCard';
import ProductCard from '../cards/ProductCard';

import Reply from './Reply';

import { useMe } from 'src/hooks/useMe';
import { CounselStatus, RoleEnum, useCommentQuery } from 'src/utils/client';
import env from 'src/utils/env';
import { fromNow } from 'src/utils/formatter';
import { openCounselWindow } from 'src/utils/providers';

gql`
  query Comment($commentId: ID!) {
    comment(commentId: $commentId) {
      id
      post {
        id
        author {
          id
          avatar {
            url
          }
        }
      }
      author {
        id
        role
        nickname
        avatar {
          url
        }
      }
      replyConnection {
        edges {
          node {
            id
          }
        }
      }
      createdAt
      ... on Comment {
        __typename
        body
        reacted
        reactionCount(filter: {})
        reactionTypes
        products {
          id
        }
        companies {
          id
          name
          images {
            url
          }
          specialties {
            id
            name
          }
        }
        files {
          ... on Image {
            __typename
            url
          }
          ... on Video {
            __typename
            url
          }
        }
      }
      ... on ChatOpenedComment {
        body
        counsel {
          id
          status
        }
      }
      ... on DeletedComment {
        __typename
        deletedAt
      }
    }
  }
`;

type CommentProps = {
  commentId: string;
  setReplyTargetComment?: (reply: {
    id: string;
    nickname: string;
    body: string;
  }) => void;
  deleteComment: (commentId: string) => void;
  onClickOpenReportModal: (reportTarget: {
    target: 'comment' | 'user';
    id: string;
  }) => void;
  readonly?: boolean;
  last?: boolean;
  commentManager?: boolean;
};

const { Text } = Typography;

const Comment: React.FC<CommentProps> = ({
  setReplyTargetComment,
  commentId,
  deleteComment,
  onClickOpenReportModal,
  readonly = false,
  last = false,
  commentManager,
}) => {
  const { data, loading } = useCommentQuery({
    variables: {
      commentId,
    },
  });
  const comment = data?.comment;

  const {
    token: { colorBorderSecondary, colorError, borderRadius, colorPrimary },
  } = theme.useToken();

  const mechanic = comment?.author?.role === RoleEnum.Counselor;
  const { me } = useMe();
  const postWriter =
    comment?.author && comment.author.id === comment.post?.author?.id;

  const menu = React.useMemo(() => {
    return (
      <Menu>
        {me && me.id === comment?.author?.id && (
          <Menu.Item
            style={{ color: colorError }}
            onClick={() => {
              Modal.confirm({
                title: `${
                  comment.author ? comment.author.nickname : '(탈퇴한 유저)'
                }님이 작성한 댓글을 삭제하시겠어요?`,
                content: '삭제시 돌이킬 수 없어요!',
                onOk: () => {
                  deleteComment(comment.id);
                },
              });
            }}
          >
            이 댓글 삭제하기
          </Menu.Item>
        )}
        {me?.id !== comment?.author?.id && (
          <>
            <Menu.Item
              style={{ color: colorError }}
              onClick={() => {
                if (comment?.id) {
                  onClickOpenReportModal({
                    target: 'comment',
                    id: comment.id,
                  });
                }
              }}
            >
              이 댓글 신고하기
            </Menu.Item>
            <Menu.Item
              style={{ color: colorError }}
              onClick={() => {
                if (!comment?.author) {
                  notification.error({
                    message: '존재하지 않는 사용자 입니다.',
                  });
                  return;
                }
                onClickOpenReportModal({
                  target: 'user',
                  id: comment?.author.id,
                });
              }}
            >
              이 사용자 신고하기
            </Menu.Item>
          </>
        )}
      </Menu>
    );
  }, [
    colorError,
    comment?.author,
    comment?.id,
    deleteComment,
    me,
    onClickOpenReportModal,
  ]);

  const isMore = React.useMemo(() => {
    if (comment?.__typename !== 'Comment' || readonly) {
      return false;
    }
    if (comment?.author?.role === RoleEnum.Counselor) {
      return comment.author.id === me?.id;
    }
    return true;
  }, [comment, me, readonly]);

  if (!comment) {
    return null;
  }

  return (
    <Spin spinning={loading}>
      <SContainer
        style={
          readonly
            ? {
                backgroundColor: colorBorderSecondary,
                borderRadius,
              }
            : {}
        }
      >
        {comment.__typename !== 'DeletedComment' && (
          <CommentHeader>
            <Space size={'small'}>
              <Avatar
                src={comment.author?.avatar?.url}
                icon={<UserOutlined />}
              />
              <Text
                strong
                style={
                  mechanic
                    ? {
                        color: colorPrimary,
                      }
                    : {}
                }
              >
                {comment.author ? comment.author.nickname : '(탈퇴한 유저)'}
                {mechanic && (
                  <>
                    {' '}
                    <CheckCircleFilled />
                  </>
                )}
              </Text>
              {postWriter && <Tag color="blue">작성자</Tag>}
              <Divider type="vertical" />
              <Text type="secondary">{fromNow(comment.createdAt)}</Text>
            </Space>
            {isMore && (
              <Dropdown
                overlay={menu}
                trigger={['click']}
                placement="bottomRight"
              >
                <MenuOutlined />
              </Dropdown>
            )}
          </CommentHeader>
        )}
        {comment.__typename === 'Comment' ? (
          <SContent commentManager={commentManager}>{comment.body}</SContent>
        ) : comment.__typename === 'ChatOpenedComment' ? (
          <Space direction="vertical" align="center">
            <SContent commentManager={commentManager}>{comment.body}</SContent>
            <Text disabled>
              *채팅방 입장 버튼은 문의글 작성자에게만 노출됩니다.
            </Text>
            <Button
              disabled={!comment.counsel?.id}
              onClick={() => {
                comment.counsel && openCounselWindow(comment.counsel?.id);
              }}
            >
              {comment.counsel
                ? comment.counsel.status === CounselStatus.Finished
                  ? '채팅 종료됨'
                  : '채팅방 가기'
                : '채팅방ID 참조 실패'}
            </Button>
          </Space>
        ) : comment.__typename === 'DeletedComment' ? (
          <Button disabled icon={<InfoCircleFilled />}>
            삭제된 댓글입니다.
          </Button>
        ) : null}
        {comment.__typename === 'Comment' && comment.files.length > 0 && (
          <div
            style={{
              display: 'flex',
              gap: 8,
            }}
          >
            {comment.files.length ? (
              <Image.PreviewGroup>
                {comment.files
                  .filter((file) => file.__typename === 'Image')
                  .map((file, index) => {
                    return <Image key={index} src={file.url} height={140} />;
                  })}
              </Image.PreviewGroup>
            ) : null}
            <span>
              {comment.files
                .filter((file) => file.__typename === 'Video')
                .map((file, index) => {
                  return (
                    <span key={index}>
                      <video height={140} controls>
                        <source src={file.url} />
                      </video>
                    </span>
                  );
                })}
            </span>
          </div>
        )}
        {comment.__typename === 'Comment' && comment.companies.length > 0 && (
          <CompaniesBox>
            {comment.companies.map((company) => {
              return <CompanyCard key={company.id} companyId={company.id} />;
            })}
          </CompaniesBox>
        )}
        {comment.__typename === 'Comment' && comment.products.length > 0 && (
          <ProductsBox>
            {comment.products.map((product) => {
              return (
                <ProductCard
                  onClick={() => {
                    window.open(`${env.WEB_URL}/product/${product.id}`);
                  }}
                  style={{ width: 400 }}
                  key={product.id}
                  productId={product.id}
                />
              );
            })}
          </ProductsBox>
        )}
        {comment.__typename === 'Comment' &&
          !readonly &&
          setReplyTargetComment && (
            <Space>
              <Button
                size="small"
                onClick={() =>
                  setReplyTargetComment({
                    id: comment.id,
                    nickname: comment.author
                      ? comment.author.nickname
                      : '(탈퇴한 유저)',
                    body: comment.body,
                  })
                }
              >
                답글 쓰기
              </Button>
            </Space>
          )}
        {comment.replyConnection?.edges.map((reply) => (
          <Reply
            key={reply.node.id}
            replyId={reply.node.id}
            deleteComment={deleteComment}
            onClickOpenReportModal={onClickOpenReportModal}
            commentManager={commentManager}
          />
        ))}
        {!last && (
          <Divider style={{ marginTop: '16px', marginBottom: '0px' }} />
        )}
      </SContainer>
    </Spin>
  );
};

export default Comment;

const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  gap: 10px;
`;

const CommentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
`;

const SContent = styled(Text)<{ commentManager?: boolean }>`
  font-size: ${(props) => (props.commentManager ? 18 : 14)}px;
  white-space: pre-line;
  word-break: break-word;
`;

const CompaniesBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ProductsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
