import {
  CheckCircleFilled,
  InfoCircleFilled,
  MenuOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { gql } from '@apollo/client';
import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  Image,
  Menu,
  Modal,
  notification,
  Space,
  Spin,
  Tag,
  theme,
  Typography,
} from 'antd';
import React from 'react';
import styled from 'styled-components';

import CompanyCard from '../cards/CompanyCard';
import ProductCard from '../cards/ProductCard';

import { useMe } from 'src/hooks/useMe';
import { RoleEnum, useReplyQuery } from 'src/utils/client';
import env from 'src/utils/env';
import { fromNow } from 'src/utils/formatter';
import { openCounselWindow } from 'src/utils/providers';

const { Text } = Typography;

gql`
  query Reply($replyId: ID!) {
    comment(commentId: $replyId) {
      id
      post {
        id
        author {
          id
          avatar {
            url
          }
        }
      }
      author {
        id
        role
        nickname
        avatar {
          url
        }
      }
      createdAt
      ... on Comment {
        __typename
        body
        reacted
        reactionCount(filter: {})
        reactionTypes
        companies {
          id
        }
        products {
          id
        }
        files {
          ... on Image {
            __typename
            url
          }
          ... on Video {
            __typename
            url
          }
        }
      }
      ... on ChatOpenedComment {
        counsel {
          id
        }
      }
      ... on DeletedComment {
        __typename
        deletedAt
      }
    }
  }
`;

type ReplyProps = {
  replyId: string;
  deleteComment: (commentId: string) => void;
  onClickOpenReportModal: (reportTarget: {
    target: 'comment' | 'user';
    id: string;
  }) => void;
  commentManager?: boolean;
};

const Reply: React.FC<ReplyProps> = ({
  replyId,
  deleteComment,
  onClickOpenReportModal,
  commentManager,
}) => {
  const { me } = useMe();
  const { data, loading } = useReplyQuery({
    variables: {
      replyId,
    },
  });

  const {
    token: { colorError },
  } = theme.useToken();

  const reply = data?.comment;
  const mechanic = reply?.author?.role === RoleEnum.Counselor;
  const postWriter =
    reply?.author && reply.author.id === reply.post?.author?.id;

  const menu = React.useMemo(() => {
    return (
      <Menu>
        {me && me.id === reply?.author?.id && (
          <Menu.Item
            style={{ color: colorError }}
            onClick={() => {
              Modal.confirm({
                title: `${
                  reply.author ? reply.author.nickname : '(탈퇴한 유저)'
                }님이 작성한 답글을 삭제하시겠어요?`,
                content: '삭제시 돌이킬 수 없어요!',
                onOk: () => {
                  deleteComment(reply.id);
                },
              });
            }}
          >
            이 답글 삭제하기
          </Menu.Item>
        )}

        {me?.id !== reply?.author?.id && (
          <>
            <Menu.Item
              style={{ color: colorError }}
              onClick={() => {
                if (reply) {
                  onClickOpenReportModal({ target: 'comment', id: reply.id });
                }
              }}
            >
              이 답변 신고하기
            </Menu.Item>
            <Menu.Item
              style={{ color: colorError }}
              onClick={() => {
                if (!reply?.author) {
                  notification.error({
                    message: '존재하지 않는 사용자 입니다.',
                  });
                  return;
                }
                onClickOpenReportModal({ target: 'user', id: reply.author.id });
              }}
            >
              이 사용자 신고하기
            </Menu.Item>
          </>
        )}
      </Menu>
    );
  }, [me, reply, colorError, deleteComment, onClickOpenReportModal]);

  const isMore = React.useMemo(() => {
    if (reply?.__typename === 'DeletedComment') {
      return false;
    }
    if (reply?.author?.role === RoleEnum.Counselor) {
      return reply.author.id === me?.id;
    }

    return true;
  }, [reply, me]);

  if (reply?.__typename === 'DeletedComment') {
    return (
      <DeletedCommentContainer>
        <InfoCircleFilled />
        <Typography.Text>삭제된 답글입니다.</Typography.Text>
      </DeletedCommentContainer>
    );
  }

  return (
    <Spin spinning={loading}>
      <SContainer>
        {isMore && (
          <SButton>
            <Dropdown
              overlay={menu}
              trigger={['click']}
              placement="bottomRight"
            >
              <MenuOutlined />
            </Dropdown>
          </SButton>
        )}
        <Space>
          <Avatar src={reply?.author?.avatar?.url} icon={<UserOutlined />} />
          <Text strong>
            {reply?.author ? reply.author.nickname : '(탈퇴한 유저)'}
            {mechanic && (
              <>
                {' '}
                <CheckCircleFilled />
              </>
            )}
          </Text>
          {postWriter && <Tag color="blue">작성자</Tag>}
          <Divider type="vertical" />
          {reply && <Text type="secondary">{fromNow(reply.createdAt)}</Text>}
        </Space>
        {reply?.__typename === 'Comment' ? (
          <SContent commentManager={commentManager}>{reply.body}</SContent>
        ) : reply?.__typename === 'ChatOpenedComment' ? (
          <Space direction="vertical" align="center">
            *채팅방 입장 버튼은 문의글 작성자에게만 노출됩니다.
            <Button
              disabled={!reply.counsel?.id}
              onClick={() => {
                reply.counsel && openCounselWindow(reply.counsel?.id);
              }}
            >
              {reply.counsel ? '채팅방 가기' : '채팅방ID 참조 실패'}
            </Button>
          </Space>
        ) : reply?.__typename === 'DeletedComment' ? (
          <Typography.Text>삭제된 댓글입니다.</Typography.Text>
        ) : null}
        {reply?.__typename === 'Comment' && reply.files.length > 0 && (
          <div
            style={{
              display: 'flex',
              gap: 8,
            }}
          >
            {reply.files.length ? (
              <Image.PreviewGroup>
                {reply.files
                  .filter((file) => file.__typename === 'Image')
                  .map((file, index) => {
                    return <Image key={index} src={file.url} height={140} />;
                  })}
              </Image.PreviewGroup>
            ) : null}
            <span>
              {reply.files
                .filter((file) => file.__typename === 'Video')
                .map((file, index) => {
                  return (
                    <span key={index}>
                      <video height={140} controls>
                        <source src={file.url} />
                      </video>
                    </span>
                  );
                })}
            </span>
          </div>
        )}
        {reply?.__typename === 'Comment' && reply.companies.length > 0 && (
          <CompaniesBox>
            {reply.companies.map((company) => {
              return <CompanyCard key={company.id} companyId={company.id} />;
            })}
          </CompaniesBox>
        )}
        {reply?.__typename === 'Comment' && reply.products.length > 0 && (
          <ProductsBox>
            {reply.products.map((product) => {
              return (
                <ProductCard
                  onClick={() => {
                    window.open(`${env.WEB_URL}/product/${product.id}`);
                  }}
                  style={{ width: 400 }}
                  key={product.id}
                  productId={product.id}
                />
              );
            })}
          </ProductsBox>
        )}
      </SContainer>
    </Spin>
  );
};

export default Reply;

const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0px 4px 36px;
  gap: 8px;
  position: relative;
`;

const SButton = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0 0 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  top: 1px;
  right: 1px;
`;

const SContent = styled(Text)<{ commentManager?: boolean }>`
  font-size: ${(props) => (props.commentManager ? 18 : 14)}px;
  white-space: pre-line;
`;

const CompaniesBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ProductsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DeletedCommentContainer = styled.div`
  margin: 8px 0px 0px 36px;
  padding: 12px 16px;
  display: flex;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
