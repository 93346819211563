import { gql } from '@apollo/client';
import { notification } from 'antd';
import React from 'react';

import AuthPresenter from './AuthPresenter';

import { useMe } from 'src/hooks/useMe';
import { useLoginMutation } from 'src/utils/client';

gql`
  mutation login($email: String!, $password: String!) {
    localLogin(email: $email, password: $password) {
      accessToken
      expiresAt
    }
  }
`;

const AuthContainer: React.FC = () => {
  const [loginMutation] = useLoginMutation();
  const { refetch } = useMe();

  const login = async (email: string, password: string) => {
    const { data } = await loginMutation({
      variables: {
        email,
        password,
      },
      onError: (error) => {
        notification.error({
          message: '로그인 실패',
          description: error.message,
        });
      },
    });

    if (!data || !data.localLogin) {
      notification.error({
        message: '로그인 정보 없음',
      });
      return;
    }

    const accessToken = data.localLogin.accessToken;
    localStorage.setItem('token', accessToken);
    await refetch();
  };
  return <AuthPresenter login={login} />;
};

export default AuthContainer;
