import { CloseOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Button, Modal, notification, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

import PostCard from '../cards/PostCard';
import ReportModal from '../modals/ReportModal';
import UserPostHistoryModal from '../modals/UserPostHistoryModal';

import Comment from './Comment';
import CommentInput, { FileWithPreview } from './CommentInput';

import { useFileUpload } from 'src/hooks/useFileUpload';
import {
  CommentDocument,
  FileInput,
  FileType,
  useCommentManagerQuery,
  useCreateCommentMutation,
  useCreateReplyMutation,
  useDeleteCommentMutation,
} from 'src/utils/client';

type CommentManagerProps = {
  onClose: () => void;
  postIssueId: string;
};

gql`
  query CommentManager($postIssueId: ID!) {
    postIssue(postIssueId: $postIssueId) {
      id
      post {
        id
        commentConnection {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

gql`
  mutation createComment($data: CreateCommentData!, $postId: ID!) {
    createComment(data: $data, postId: $postId) {
      ... on CreateCommentSuccess {
        comment {
          id
        }
      }
      ... on CreateCommentFail {
        error {
          ... on DeletedPostError {
            message
          }
        }
      }
    }
  }
`;

gql`
  mutation createReply($commentId: ID!, $data: CreateCommentData!) {
    createReply(commentId: $commentId, data: $data) {
      ... on CreateReplySuccess {
        reply {
          id
        }
      }
    }
  }
`;

gql`
  mutation deleteComment($commentId: ID!) {
    deleteComment(commentId: $commentId) {
      ... on DeleteCommentSuccess {
        comment {
          id
        }
      }
    }
  }
`;

const CommentManager: React.FC<CommentManagerProps> = ({
  onClose,
  postIssueId,
}) => {
  const { data, refetch } = useCommentManagerQuery({
    variables: {
      postIssueId,
    },
  });

  const [deleteCommentMutation] = useDeleteCommentMutation();
  const [createCommentMutation] = useCreateCommentMutation();
  const [createReplyMutation] = useCreateReplyMutation();

  const repliesRef = React.useRef<HTMLDivElement | null>(null);
  const [reportTarget, setReportTarget] = React.useState<
    { target: 'post' | 'user' | 'comment'; id: string } | undefined
  >(undefined);
  const [postHistoryUser, setPostHistoryUser] = React.useState<
    { id: string; nickname: string } | undefined
  >(undefined);
  const [replyTargetComment, setReplyTargetComment] = React.useState<{
    id: string;
    nickname: string;
    body: string;
  } | null>(null);
  const [comment, setComment] = React.useState<string>(
    sessionStorage.getItem(postIssueId) || ''
  );
  const [sending, setSending] = React.useState(false);
  const { upload } = useFileUpload();

  const handleSendComment = async (
    files: FileWithPreview[],
    companyIds: string[],
    productIds: string[]
  ) => {
    console.log('handleSendComment', files, companyIds, productIds);
    if (!data || comment === '') {
      notification.error({ message: '답변 내용을 입력해주세요!' });
      return false;
    }

    try {
      setSending(true);

      const uploadedFiles = await Promise.all(
        files.map((filePreview) => upload(filePreview.file))
      );
      const fileInput: FileInput[] = uploadedFiles.map((uploadedFile) => ({
        url: uploadedFile.url,
        type: uploadedFile.type === 'image' ? FileType.Image : FileType.Video,
      }));

      if (replyTargetComment) {
        await createReplyMutation({
          variables: {
            commentId: replyTargetComment.id,
            data: {
              body: comment,
              files: fileInput.length > 0 ? fileInput : undefined,
              companyIds,
              productIds,
            },
          },
          refetchQueries: [
            {
              query: CommentDocument,
              variables: {
                commentId: replyTargetComment.id,
              },
            },
          ],
        });
        setReplyTargetComment(null);
      } else {
        await createCommentMutation({
          variables: {
            postId: data.postIssue.post.id,
            data: {
              body: comment,
              files: fileInput.length > 0 ? fileInput : undefined,
              companyIds,
              productIds,
            },
          },
        });
        await refetch({
          postIssueId,
        });
      }

      setComment('');
      sessionStorage.removeItem(postIssueId);
      if (repliesRef.current) {
        repliesRef.current.scrollTop =
          repliesRef.current.scrollHeight - repliesRef.current.clientHeight;
      }
      return true;
    } catch (err) {
      notification.error({ message: '답변 작성중 오류가 발생했습니다!' });
      return false;
    } finally {
      setSending(false);
    }
  };

  const deleteComment = async (commentId: string) => {
    await deleteCommentMutation({
      variables: {
        commentId,
      },
      refetchQueries: [
        {
          query: CommentDocument,
          variables: {
            commentId,
          },
        },
      ],
    });
  };

  const comments = React.useMemo(() => {
    if (!data) {
      return [];
    } else {
      return data.postIssue.post.commentConnection.edges.map(
        (edge) => edge.node
      );
    }
  }, [data]);

  return (
    <SContainer>
      <SHeader>
        <Typography.Text strong>답변 작성</Typography.Text>
        <Button
          type="text"
          icon={<CloseOutlined />}
          onClick={() => onClose()}
        />
      </SHeader>
      <SBody>
        {postIssueId && (
          <PostCard
            opened
            border={false}
            postIssueId={postIssueId}
            onClickOpenReportModal={(target) => {
              setReportTarget(target);
            }}
            onClickOpenUserPostHistoryModal={(userId, nickname) => {
              setPostHistoryUser({ id: userId, nickname });
            }}
            commentManager
          />
        )}
        <SRepliesBox>
          <SRepliesInnerBox ref={repliesRef}>
            {comments.map((comment, index) => (
              <Comment
                key={index}
                commentId={comment.id}
                setReplyTargetComment={setReplyTargetComment}
                deleteComment={deleteComment}
                onClickOpenReportModal={(target) => {
                  setReportTarget(target);
                }}
                last={comments.length === index + 1}
                commentManager
              />
            ))}
          </SRepliesInnerBox>
        </SRepliesBox>
        {data?.postIssue && (
          <CommentInput
            replyTargetComment={replyTargetComment}
            clearReplyAnswerTarget={() => {
              setReplyTargetComment(null);
            }}
            value={comment}
            onChange={(value) => {
              setComment(value);
              sessionStorage.setItem(postIssueId, value);
            }}
            onClickSend={handleSendComment}
            sending={sending}
            postId={data?.postIssue.post.id}
          />
        )}
      </SBody>
      <Modal
        className="no-footer-modal"
        open={reportTarget !== undefined}
        onCancel={(e) => {
          e.stopPropagation();
          setReportTarget(undefined);
        }}
        okText="신고하기"
        cancelText="취소"
        footer={[]}
      >
        <ReportModal
          target={reportTarget?.target}
          id={reportTarget?.id}
          closeModal={() => setReportTarget(undefined)}
        />
      </Modal>
      <Modal
        className="no-footer-modal"
        title={`${postHistoryUser?.nickname}님의 지난 커뮤니티 문의내용`}
        open={postHistoryUser !== undefined}
        onCancel={(e) => {
          e.stopPropagation();
          setPostHistoryUser(undefined);
        }}
        footer={[]}
        width={800}
      >
        {postHistoryUser && (
          <UserPostHistoryModal userId={postHistoryUser.id} />
        )}
      </Modal>
    </SContainer>
  );
};

export default CommentManager;

const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 18px;
`;

const SBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const SRepliesBox = styled.div`
  position: relative;
  flex: 1;
`;

const SRepliesInnerBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
`;
