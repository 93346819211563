import { gql } from '@apollo/client';
import { Avatar, Divider, Typography, Image, theme, Space, Card } from 'antd';
import React from 'react';
import styled from 'styled-components';

import ExtendableComments from '../shared/ExtendableComments';

import { RoleEnum, useReadablePostCardQuery } from 'src/utils/client';
import { fromNow, fuelTypeFormatter } from 'src/utils/formatter';

export type ReadablePostCardProps = {
  postId: string;
  border?: boolean;
  cardRef?: React.RefObject<HTMLDivElement>;
};

const { Text } = Typography;

gql`
  query ReadablePostCard($postId: ID!) {
    post(postId: $postId) {
      id
      author {
        id
        nickname
        avatar {
          url
        }
      }
      counsels {
        id
      }
      issue {
        id
        bookmarked
        status
        lastComment {
          id
          author {
            id
            role
          }
        }
      }
      createdAt
      areas {
        zone {
          district
          neighborhood
          province
        }
      }
      insurance
      vehicle {
        id
        plateNumber
        fullModelName
        mileage
        registeredYear
        fuelType {
          base
          hybrid
        }
      }
      body
      files {
        ... on Image {
          __typename
          url
        }
        ... on Video {
          __typename
          url
        }
      }
      commentCount
      commentConnection {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

const ReadablePostCard: React.FC<ReadablePostCardProps> = ({
  postId,
  cardRef,
}) => {
  const { data } = useReadablePostCardQuery({
    variables: {
      postId,
    },
  });

  const post = data?.post;
  const lastComment = post?.issue?.lastComment;

  const {
    token: { colorPrimary },
  } = theme.useToken();

  const counselorCommented =
    post?.counsels?.length ||
    (lastComment &&
      lastComment.author &&
      lastComment.author.role === RoleEnum.Counselor);
  return (
    <Card ref={cardRef}>
      {post && (
        <SHeader>
          <SInfoBox>
            {post.author ? (
              <Space>
                <Avatar size={24} src={post.author.avatar?.url} />
                <Text>{post.author.nickname}</Text>
              </Space>
            ) : (
              <Text>(탈퇴한 유저)</Text>
            )}

            <Divider type="vertical" />
            <Text type="secondary">{fromNow(post.createdAt)}</Text>
          </SInfoBox>
        </SHeader>
      )}
      {post?.vehicle && (
        <STitleBox>
          {post.vehicle.plateNumber && (
            <>
              <span>{post.vehicle.plateNumber}</span>
              <Divider type="vertical" />
            </>
          )}

          {post.vehicle.fullModelName && (
            <>
              <span>{post.vehicle.fullModelName}</span>
              <Divider type="vertical" />
            </>
          )}

          {post.vehicle.fuelType && (
            <>
              <span>{fuelTypeFormatter(post.vehicle.fuelType)}</span>
              <Divider type="vertical" />
            </>
          )}

          {post.vehicle.registeredYear && (
            <>
              <span>{post.vehicle.registeredYear}</span>
              {post.vehicle.mileage && <Divider type="vertical" />}
            </>
          )}

          {post.vehicle.mileage && (
            <>
              <span>{`${post.vehicle.mileage}km`}</span>
            </>
          )}
        </STitleBox>
      )}
      <SContentBox>{post?.body}</SContentBox>
      {post?.files.length ? (
        <SImagesBox>
          {post.files.map((file, index) => {
            if (file.__typename === 'Image') {
              return (
                <Image key={index} src={file.url} width={80} height={80} />
              );
            } else {
              return (
                <Video key={index} controls preload="metadata">
                  <source src={file.url} />
                </Video>
              );
            }
          })}
        </SImagesBox>
      ) : null}

      <SFeedbackBox>
        <span
          style={
            counselorCommented
              ? { color: colorPrimary, fontWeight: 'bold' }
              : {}
          }
        >
          댓글 {post?.commentCount}
        </span>
      </SFeedbackBox>
      {lastComment && (
        <ExtendableComments
          lastCommentId={lastComment.id}
          commentIds={post.commentConnection.edges.map((edge) => edge.node.id)}
        />
      )}
    </Card>
  );
};

export default ReadablePostCard;

const SHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
`;

const SInfoBox = styled.div`
  display: flex;
  align-items: center;
`;

const STitleBox = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  flex: 0 0 22px;
`;

const SContentBox = styled.div`
  margin-top: 16px;
`;

const SImagesBox = styled.div`
  flex: 0 0 80px;
  display: flex;
  gap: 10px;
  margin-top: 16px;
`;

const Video = styled.video`
  flex: 0 0 80px;
  height: 80px;
`;

const SFeedbackBox = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 8px;
  height: 22px;
  align-items: center;
  margin-bottom: 12px;
`;
