import { gql, useApolloClient } from '@apollo/client';
import axios from 'axios';

import { Query, FileUploadUrlQueryVariables } from 'src/utils/client';
import { readImageFile, readVideoFile } from 'src/utils/file';

const FILE_UPLOAD_URL = gql`
  query fileUploadUrl($file: FileUploadInput!) {
    userContentFileUpload(file: $file) {
      uploadUrl
      fileUrl
    }
  }
`;

type FileData = {
  url: string;
  width: number;
  height: number;
  duration?: number;
  type: 'image' | 'video';
};

export const useFileUpload = () => {
  const client = useApolloClient();

  const upload = async (file: File) => {
    let fileData: FileData;
    if (file.type.startsWith('image'))
      fileData = { ...(await readImageFile(file)), type: 'image' };
    else if (file.type.startsWith('video'))
      fileData = { ...(await readVideoFile(file)), type: 'video' };
    else throw new Error(`Unsupported file type: '${file.type}'`);
    const { type, ...metadata } = fileData;

    const result = await client.query<Query, FileUploadUrlQueryVariables>({
      query: FILE_UPLOAD_URL,
      variables: {
        file: {
          filename: file.name.toLowerCase(),
          mimetype: file.type,
          metadata: {
            width: metadata.width,
            height: metadata.height,
          },
        },
      },
      fetchPolicy: 'network-only',
    });

    if (!result) {
      throw new Error('업로드용 URL을 발급 받지 못했습니다');
    }

    const { uploadUrl, fileUrl } = result.data.userContentFileUpload;
    await axios.put(uploadUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
    });

    return { ...metadata, url: fileUrl, type };
  };

  return {
    upload,
  };
};
