import { Coordinates } from './client';

export const getDistanceBetweenCoordinates = (
  current: Coordinates,
  target: Coordinates
): number => {
  // Calculate distance between two points using the Haversine formula as km
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(target.latitude - current.latitude); // deg2rad below
  const dLon = deg2rad(target.longitude - current.longitude);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(current.latitude)) *
      Math.cos(deg2rad(target.latitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  // Distance in km, rounded to 1 decimals
  return Math.round(R * c * 10) / 10;
};

const deg2rad = (deg: number): number => {
  return deg * (Math.PI / 180);
};
