/* eslint-disable @typescript-eslint/ban-ts-comment */
import { gql } from '@apollo/client';
import {
  Input,
  Radio,
  RadioChangeEvent,
  Pagination,
  Modal,
  Checkbox,
  DatePicker,
  Space,
  Divider,
  Tabs,
} from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import PostCard from '../cards/PostCard';
import ReportModal from '../modals/ReportModal';
import UserPostHistoryModal from '../modals/UserPostHistoryModal';

import {
  PostIssuesOrder,
  PostIssueStatus,
  usePostIssueCountLazyQuery,
} from 'src/utils/client';
import { postsFilterToQueryString, tabToStatus } from 'src/utils/formatter';

gql`
  query postIssueCount($filter: PostIssuesFilter!) {
    postIssueCount(filter: $filter)
  }
`;

type postsProps = {
  openedPostIssueId: string | null;
  onClickCard: (clickedPostId: string) => void;
  postIssueIds: string[];
  onPageChange: (page: number) => void;
  onSearch: (term: string) => void;
  bookmarked?: boolean;
  onChangePageLimit: (limit: number) => void;
  onClose: () => void;
};

export type RangeValue = Parameters<
  NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>['onChange']>
>[0];

const PostIssues: React.FC<postsProps> = ({
  onClickCard,
  openedPostIssueId,
  postIssueIds,
  onPageChange,
  onSearch,
  bookmarked = false,
  onChangePageLimit,
  onClose,
}) => {
  const { page } = useParams<{ page: string | undefined }>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const term = searchParams.get('term');
  const categoryId = searchParams.get('categoryId');
  const from = searchParams.get('from');
  const to = searchParams.get('to');
  const sort = searchParams.get('sort');
  const nickname = searchParams.get('nickname');
  const onlyReplied = searchParams.get('onlyReplied');
  const chatOpened = searchParams.get('chatOpened');
  const status = tabToStatus(tab as string);
  const [termValue, setTermValue] = React.useState<string | undefined>(
    undefined
  );
  const [reportTarget, setReportTarget] = React.useState<
    { target: 'post' | 'user'; id: string } | undefined
  >(undefined);
  const [postHistoryUser, setPostHistoryUser] = React.useState<
    { id: string; nickname: string } | undefined
  >(undefined);

  const [openedTab, setOpenedTab] = React.useState<PostIssueStatus>(status);
  const [todoCount, setTodoCount] = React.useState<number | undefined>(
    undefined
  );
  const [inProgressCount, setInProgressCount] = React.useState<
    number | undefined
  >(undefined);
  const [doneCount, setDoneCount] = React.useState<number | undefined>(
    undefined
  );
  const [bookmarkedCount, setBookmarkedCount] = React.useState<
    number | undefined
  >(undefined);

  const [postIssueCountQuery] = usePostIssueCountLazyQuery({});

  React.useEffect(() => {
    if (term && term !== '') {
      setTermValue(term as string);
    }
  }, [term]);

  React.useEffect(() => {
    setOpenedTab(status);
  }, [status]);

  const onRangeChange = React.useCallback(
    (range: RangeValue) => {
      if (range && range.length === 2) {
        const [from, to] = range;
        const updatedFilter = {
          term: term ? (term as string) : undefined,
          categoryId: categoryId ? (categoryId as string) : undefined,
          createdAtRange:
            from && to
              ? {
                  from: from.valueOf(),
                  to: to.valueOf(),
                }
              : undefined,
          orderBy: sort as PostIssuesOrder,
          nickname: nickname === 'true',
          chatOpened: chatOpened === 'true',
          onlyReplied: onlyReplied === 'true',
          status,
        };

        navigate(
          `${
            bookmarked ? '/community/bookmarks' : '/community/posts'
          }/1?${postsFilterToQueryString(updatedFilter)}`
        );
      }
    },
    [
      term,
      categoryId,
      sort,
      nickname,
      chatOpened,
      onlyReplied,
      status,
      navigate,
      bookmarked,
    ]
  );

  const handleClearSearchTerm = () => {
    const updatedFilter = {
      term: undefined,
      categoryId: categoryId ? (categoryId as string) : undefined,
      createdAtRange:
        from && to
          ? {
              from: Number(from),
              to: Number(to),
            }
          : undefined,
      orderBy: sort as PostIssuesOrder,
      nickname: nickname === 'true',
      chatOpened: chatOpened === 'true',
      onlyReplied: onlyReplied === 'true',
      status,
    };
    navigate(
      `${
        bookmarked ? '/community/bookmarks' : '/community/posts'
      }/1?${postsFilterToQueryString(updatedFilter)}`
    );
  };

  const onCalendarChange = () => {
    const updatedFilter = {
      term: term ? (term as string) : undefined,
      categoryId: categoryId ? (categoryId as string) : undefined,
      orderBy: sort as PostIssuesOrder,
      nickname: nickname === 'true',
      chatOpened: chatOpened === 'true',
      onlyReplied: onlyReplied === 'true',
      status,
    };
    navigate(
      `${
        bookmarked ? '/community/bookmarks' : '/community/posts'
      }/1?${postsFilterToQueryString(updatedFilter)}`
    );
  };

  const handleChangeSortKey = (e: RadioChangeEvent) => {
    const updatedFilter = {
      term: term ? (term as string) : undefined,
      categoryId: categoryId ? (categoryId as string) : undefined,
      createdAtRange:
        from && to
          ? {
              from: Number(from),
              to: Number(to),
            }
          : undefined,
      orderBy: e.target.value as PostIssuesOrder,
      nickname: nickname === 'true',
      chatOpened: chatOpened === 'true',
      onlyReplied: onlyReplied === 'true',
      status,
    };
    navigate(
      `${
        bookmarked ? '/community/bookmarks' : '/community/posts'
      }/1?${postsFilterToQueryString(updatedFilter)}`
    );
  };

  const handleChangeIncludeNickname = (checked: boolean) => {
    const updatedFilter = {
      nickname: checked,
      term: term ? (term as string) : undefined,
      categoryId: categoryId ? (categoryId as string) : undefined,
      createdAtRange:
        from && to
          ? {
              from: Number(from),
              to: Number(to),
            }
          : undefined,
      orderBy: sort as PostIssuesOrder,
      chatOpened: chatOpened === 'true',
      onlyReplied: onlyReplied === 'true',
      status,
    };

    navigate(
      `${
        bookmarked ? '/community/bookmarks' : '/community/posts'
      }/1?${postsFilterToQueryString(updatedFilter)}`
    );
  };

  const handleChangeChatOpened = (checked: boolean) => {
    const updatedFilter = {
      chatOpened: checked,
      nickname: nickname === 'true',
      term: term ? (term as string) : undefined,
      categoryId: categoryId ? (categoryId as string) : undefined,
      createdAtRange:
        from && to
          ? {
              from: Number(from),
              to: Number(to),
            }
          : undefined,
      orderBy: sort as PostIssuesOrder,
      onlyReplied: onlyReplied === 'true',
      status,
    };

    navigate(
      `${
        bookmarked ? '/community/bookmarks' : '/community/posts'
      }/1?${postsFilterToQueryString(updatedFilter)}`
    );
  };

  const handleChangeOnlyReplied = (checked: boolean) => {
    const updatedFilter = {
      nickname: nickname === 'true',
      term: term ? (term as string) : undefined,
      categoryId: categoryId ? (categoryId as string) : undefined,
      createdAtRange:
        from && to
          ? {
              from: Number(from),
              to: Number(to),
            }
          : undefined,
      orderBy: sort as PostIssuesOrder,
      chatOpened: chatOpened === 'true',
      onlyReplied: checked,
      status,
    };

    navigate(
      `${
        bookmarked ? '/community/bookmarks' : '/community/posts'
      }/1?${postsFilterToQueryString(updatedFilter)}`
    );
  };

  const handleChangeTab = React.useCallback(
    (key: string) => {
      onClose();
      const updatedFilter = {
        term: term ? (term as string) : undefined,
        categoryId: categoryId ? (categoryId as string) : undefined,
        createdAtRange:
          from && to
            ? {
                from: Number(from),
                to: Number(to),
              }
            : undefined,
        orderBy: sort as PostIssuesOrder,
        status: key as PostIssueStatus,
      };

      navigate(`/community/posts/1?${postsFilterToQueryString(updatedFilter)}`);
    },
    [categoryId, from, navigate, onClose, sort, term, to]
  );

  React.useEffect(() => {
    const preload = async () => {
      const updatedFilter = {
        term: term ? (term as string) : undefined,
        categoryIds: categoryId ? ([categoryId] as string[]) : undefined,
        chatOpened: chatOpened === 'true',
        nickname: nickname === 'true',
        onlyIReplied: onlyReplied === 'true',
        bookmarked,
        createdAtRange:
          from && to
            ? {
                from: Number(from),
                to: Number(to),
              }
            : undefined,
      };
      if (bookmarked) {
        await postIssueCountQuery({
          variables: {
            filter: {
              ...updatedFilter,
              bookmarked: true,
            },
          },
          onCompleted: (data) => {
            setBookmarkedCount(data.postIssueCount);
          },
        });
      } else {
        await postIssueCountQuery({
          variables: {
            filter: {
              ...updatedFilter,
              status: PostIssueStatus.ToDo,
            },
          },
          onCompleted: (data) => {
            setTodoCount(data.postIssueCount);
          },
        });
        await postIssueCountQuery({
          variables: {
            filter: {
              ...updatedFilter,
              status: PostIssueStatus.InProgress,
            },
          },
          onCompleted: (data) => {
            setInProgressCount(data.postIssueCount);
          },
        });
        await postIssueCountQuery({
          variables: {
            filter: {
              ...updatedFilter,
              status: PostIssueStatus.Done,
            },
          },
          onCompleted: (data) => {
            setDoneCount(data.postIssueCount);
          },
        });
      }
    };

    preload();
  }, [
    term,
    categoryId,
    from,
    to,
    sort,
    status,
    chatOpened,
    nickname,
    onlyReplied,
    bookmarked,
    postIssueCountQuery,
  ]);

  const handleSearch = () => {
    onSearch(termValue ? termValue : '');
  };

  return (
    <SContainer>
      <SHeader>
        <SearchBox>
          <Input.Search
            placeholder="닉네임, 차량번호, 차종, 내용, 제목으로 검색해보세요!"
            style={{
              flex: '0 0 400px',
            }}
            allowClear
            value={termValue}
            onChange={(e) => {
              setTermValue(e.target.value);
              if (e.target.value === '') {
                handleClearSearchTerm();
              }
            }}
            onSearch={handleSearch}
          />
          <DatePicker.RangePicker
            onChange={onRangeChange}
            onCalendarChange={onCalendarChange}
            showTime={false}
            value={
              from && to ? [dayjs(Number(from)), dayjs(Number(to))] : undefined
            }
          />
          <Space split={<Divider type="vertical" />}>
            <Radio.Group
              value={sort ? sort : PostIssuesOrder.CreatedAtDesc}
              onChange={handleChangeSortKey}
            >
              <Radio
                key={PostIssuesOrder.CreatedAtDesc}
                value={PostIssuesOrder.CreatedAtDesc}
              >
                최신순
              </Radio>
              <Radio
                key={PostIssuesOrder.CreatedAtAsc}
                value={PostIssuesOrder.CreatedAtAsc}
              >
                오래된순
              </Radio>
            </Radio.Group>

            <Checkbox
              onChange={(e) => handleChangeIncludeNickname(e.target.checked)}
              checked={nickname === 'true'}
            >
              닉네임 포함
            </Checkbox>
            <Checkbox
              onChange={(e) => handleChangeChatOpened(e.target.checked)}
              checked={chatOpened === 'true'}
            >
              채팅
            </Checkbox>
            <Checkbox
              onChange={(e) => handleChangeOnlyReplied(e.target.checked)}
              checked={onlyReplied === 'true'}
            >
              내가 답변한 게시물
            </Checkbox>
          </Space>
        </SearchBox>
        {!bookmarked && (
          <Tabs
            activeKey={openedTab}
            onChange={handleChangeTab}
            destroyInactiveTabPane
          >
            <Tabs.TabPane
              tab={`미답변${todoCount ? '(' + todoCount + ')' : ''}`}
              key={PostIssueStatus.ToDo}
            ></Tabs.TabPane>
            <Tabs.TabPane
              tab={`답변 진행중${
                inProgressCount ? '(' + inProgressCount + ')' : ''
              }`}
              key={PostIssueStatus.InProgress}
            ></Tabs.TabPane>
            <Tabs.TabPane
              tab={`답변완료${doneCount ? '(' + doneCount + ')' : ''}`}
              key={PostIssueStatus.Done}
            ></Tabs.TabPane>
          </Tabs>
        )}
      </SHeader>
      <Pagination
        current={page ? Number(page) : 1}
        total={
          bookmarked
            ? bookmarkedCount
            : openedTab === PostIssueStatus.ToDo
            ? todoCount
            : openedTab === PostIssueStatus.InProgress
            ? inProgressCount
            : doneCount
        }
        style={{
          padding: 12,
          borderBottom: '1px solid #e8e8e8',
        }}
        size="small"
        onChange={(page) => onPageChange(page)}
        onShowSizeChange={(_, limit) => onChangePageLimit(limit)}
      />
      <SPostCardsBox>
        {postIssueIds.map((postIssueId) => (
          <PostCard
            key={postIssueId}
            postIssueId={postIssueId}
            opened={openedPostIssueId === postIssueId}
            onClick={() => onClickCard(postIssueId)}
            onClickOpenReportModal={(target) => {
              setReportTarget(target);
            }}
            onClickOpenUserPostHistoryModal={(userId, nickname) => {
              setPostHistoryUser({ id: userId, nickname });
            }}
            lastCommentShow
          />
        ))}
      </SPostCardsBox>
      <Modal
        className="no-footer-modal"
        open={reportTarget !== undefined}
        onCancel={(e) => {
          e.stopPropagation();
          setReportTarget(undefined);
        }}
        okText="신고하기"
        cancelText="취소"
        footer={[]}
      >
        <ReportModal
          target={reportTarget?.target}
          id={reportTarget?.id}
          closeModal={() => setReportTarget(undefined)}
        />
      </Modal>
      <Modal
        className="no-footer-modal"
        title={`${postHistoryUser?.nickname}님의 지난 커뮤니티 문의내용`}
        open={postHistoryUser !== undefined}
        onCancel={(e) => {
          e.stopPropagation();
          setPostHistoryUser(undefined);
        }}
        footer={[]}
        width={596}
      >
        {postHistoryUser && (
          <UserPostHistoryModal userId={postHistoryUser.id} />
        )}
      </Modal>
    </SContainer>
  );
};

export default PostIssues;

const SContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const SHeader = styled.div`
  padding: 8px 0px 0px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
`;

const SearchBox = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 8px;
  flex-wrap: wrap;
  align-items: center;
`;

const SPostCardsBox = styled.div`
  flex: 1;
  padding: 20px;
  padding-bottom: 40px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
