import { gql } from '@apollo/client';
import { notification } from 'antd';
import React from 'react';

import ReportModalPresenter from './ReportModalPresenter';

import {
  useReportCommunityCommentMutation,
  useReportCommunityPostMutation,
  useReportCommunityUserMutation,
} from 'src/utils/client';

type ReportModalContainerProps = {
  target?: 'post' | 'user' | 'comment';
  id?: string;
  closeModal: () => void;
};

gql`
  mutation reportCommunityPost($data: ReportCommunityPostData!, $postId: ID!) {
    reportCommunityPost(postId: $postId, data: $data) {
      ... on ReportCommunityPostSuccess {
        report {
          id
        }
      }
    }
  }
`;

gql`
  mutation reportCommunityComment(
    $data: ReportCommunityCommentData!
    $commentId: ID!
  ) {
    reportCommunityComment(commentId: $commentId, data: $data) {
      ... on ReportCommunityCommentSuccess {
        report {
          id
        }
      }
    }
  }
`;

gql`
  mutation reportCommunityUser($data: ReportCommunityUserData!, $userId: ID!) {
    reportCommunityUser(userId: $userId, data: $data) {
      ... on ReportCommunityUserSuccess {
        report {
          id
        }
      }
    }
  }
`;

const ReportModalContainer: React.FC<ReportModalContainerProps> = (props) => {
  const [reportPostMutation, reportPostResult] =
    useReportCommunityPostMutation();
  const [reportUserMutation, reportUserResult] =
    useReportCommunityUserMutation();
  const [reportCommentMutation, reportCommentResult] =
    useReportCommunityCommentMutation();

  const reportPost = async (postId: string, reason: string) => {
    try {
      const { data } = await reportPostMutation({
        variables: {
          postId,
          data: {
            reason,
          },
        },
      });

      if (
        data?.reportCommunityPost.__typename === 'ReportCommunityPostSuccess'
      ) {
        notification.success({
          message: '정상적으로 게시물 신고가 접수되었습니다.',
        });
      }
    } catch (err) {
      console.error(err);
      notification.error({ message: '신고접수중 오류가 발생했습니다.' });
    } finally {
      props.closeModal();
    }
  };

  const reportUser = async (userId: string, reason: string) => {
    try {
      const { data } = await reportUserMutation({
        variables: {
          userId,
          data: {
            reason,
          },
        },
      });

      if (
        data?.reportCommunityUser.__typename === 'ReportCommunityUserSuccess'
      ) {
        notification.success({
          message: '정상적으로 사용자 신고가 접수되었습니다.',
        });
      }
    } catch (err) {
      console.error(err);
      notification.error({ message: '신고접수중 오류가 발생했습니다.' });
    } finally {
      props.closeModal();
    }
  };

  const reportComment = async (commentId: string, reason: string) => {
    try {
      const { data } = await reportCommentMutation({
        variables: {
          commentId,
          data: {
            reason,
          },
        },
      });

      if (
        data?.reportCommunityComment.__typename ===
        'ReportCommunityCommentSuccess'
      ) {
        notification.success({
          message: '정상적으로 댓글 신고가 접수되었습니다.',
        });
      }
    } catch (err) {
      console.error(err);
      notification.error({ message: '신고접수중 오류가 발생했습니다.' });
    } finally {
      props.closeModal();
    }
  };

  return (
    <ReportModalPresenter
      loading={
        reportPostResult.loading ||
        reportUserResult.loading ||
        reportCommentResult.loading
      }
      reportPost={reportPost}
      reportUser={reportUser}
      reportComment={reportComment}
      {...props}
    />
  );
};

export default ReportModalContainer;
