import { CloseOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Button, Card, Tag, Typography } from 'antd';
import React from 'react';

import { useProductCardQuery } from 'src/utils/client';
import { productQualityLevelFormatter } from 'src/utils/formatter';

gql`
  query ProductCard($productId: ID!) {
    product(productId: $productId) {
      id
      intro
      qualityLevel
      company {
        id
        name
        images {
          url
        }
        address {
          region
          road
          coordinates {
            latitude
            longitude
          }
        }
      }
    }
  }
`;

type ProductCardProps = {
  productId: string;
  onClick?: () => void;
  onClose?: () => void;
  style?: React.CSSProperties;
};

const { Text } = Typography;

const ProductCard = ({
  productId,
  onClick,
  onClose,
  style,
}: ProductCardProps) => {
  const { data, loading } = useProductCardQuery({
    variables: {
      productId,
    },
  });
  const badge = productQualityLevelFormatter(data?.product?.qualityLevel);
  return (
    <Card
      onClick={onClick}
      loading={loading}
      hoverable={!!onClick}
      style={{
        cursor: 'pointer',
        ...style,
      }}
      size="small"
    >
      <div style={{ display: 'flex', gap: 6 }}>
        <div style={{ flex: 1 }}>
          <Tag color={'red'}>견적무료</Tag>
          <Tag color={badge?.color}>{badge?.text}</Tag>
          <div style={{ marginTop: 4 }}>
            <Text strong>{data?.product?.intro}</Text> -{' '}
            {data?.product?.company?.name}
          </div>
          <Text disabled>
            {data?.product?.company?.address.road ||
              data?.product?.company?.address.region}
          </Text>
        </div>
        {onClose && (
          <Button
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
            icon={<CloseOutlined />}
          />
        )}
      </div>
    </Card>
  );
};

export default ProductCard;
