import { ApolloProvider, gql } from '@apollo/client';
import { ConfigProvider, notification, theme } from 'antd';
import kr from 'antd/lib/locale/ko_KR';
import React from 'react';

import initClient from './apollo';
import { generateCache } from './cache';
import { useRefreshAccessTokenMutation } from './client';
import env from './env';

type GlobalProviderProps = {
  children: React.ReactNode;
};

gql`
  mutation refreshAccessToken {
    refreshAccessToken {
      accessToken
      expiresAt
    }
  }
`;

const { darkAlgorithm, defaultAlgorithm } = theme;

export const openCounselWindow = (counselId: string) => {
  if (!env.COUNSEL_CONSOLE_URL) {
    notification.error({
      message: '상담사 콘솔 URL이 지정되지 않았습니다!',
    });
    return;
  }
  window.open(
    `${env.COUNSEL_CONSOLE_URL}/chat/${counselId}`,
    counselId,
    'width=1450, height=1000'
  );
};

const ThemeContext = React.createContext({
  dark: false,
  toggleTheme: () => {
    alert('setDark is not defined: dark status: ');
  },
});

export const useDarkMode = () => React.useContext(ThemeContext);

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const apolloClient = initClient(generateCache());

  const [dark, setDark] = React.useState(
    localStorage.getItem('dark') === 'true'
  );

  const toggleTheme = () => {
    const newDark = !dark;
    localStorage.setItem('dark', newDark.toString());
    setDark(newDark);
  };

  return (
    <ThemeContext.Provider value={{ dark, toggleTheme }}>
      <ApolloProvider client={apolloClient}>
        <ConfigProvider
          theme={{
            token: {
              fontFamily:
                "Pretentard, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
            },
            algorithm: dark ? darkAlgorithm : defaultAlgorithm,
          }}
          locale={kr}
        >
          {children}
          <TokenRefreshProvider />
        </ConfigProvider>
      </ApolloProvider>
    </ThemeContext.Provider>
  );
};

const TokenRefreshProvider = () => {
  const [refreshAccessTokenMutation] = useRefreshAccessTokenMutation();
  const [tokenExpiresAt, setTokenExpiresAt] = React.useState(0);
  const updateAccessToken = React.useCallback(async () => {
    try {
      const accessToken = localStorage.getItem('token');
      if (!accessToken) {
        return;
      }
      const { data } = await refreshAccessTokenMutation();
      if (!data) {
        return;
      }
      localStorage.setItem('token', data.refreshAccessToken.accessToken);
      setTokenExpiresAt(data.refreshAccessToken.expiresAt);
    } catch (err) {
      console.error(err);
    }
  }, [refreshAccessTokenMutation]);

  React.useEffect(() => {
    // 토큰 체커 등록
    const interval = setInterval(async () => {
      if (Date.now() < tokenExpiresAt) {
        await updateAccessToken();
      }
    }, 60000);
    return () => clearInterval(interval);
  }, [updateAccessToken, tokenExpiresAt]);

  React.useEffect(() => {
    // 최초 1회 토큰 리프레시
    updateAccessToken();
    const tab = window.top;

    if (!tab) {
      return;
    }
    // 관리자콘솔이 포커스 될때 토큰 리프레시 ()
    tab.onfocus = async () => {
      await updateAccessToken();
    };
  }, [updateAccessToken]);

  return <></>;
};
