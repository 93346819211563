import { Layout } from 'antd';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Navigation from './components/templates/Navigation';
import Bookmarks from './pages/Community/Bookmarks';
import Posts from './pages/Community/PostIssueTabs';
import CompanyCreate from './pages/Company/Create';
import CompanyDetail from './pages/Company/Detail';
import CompanyEdit from './pages/Company/Edit';
import CompanyList from './pages/Company/List';
import CompanyMembers from './pages/Company/Members';

const Router = () => {
  return (
    <Layout style={{ height: '100vh', overflow: 'hidden', display: 'flex' }}>
      <Navigation />
      <Layout style={{ flex: 1, overflowY: 'auto', display: 'flex' }}>
        <Routes>
          <Route path="/community/posts/:page?" element={<Posts />} />
          <Route path="/community/bookmarks/:page?" element={<Bookmarks />} />
          <Route path="/company/create" element={<CompanyCreate />} />
          <Route path="/company/list/:page?" element={<CompanyList />} />
          <Route
            path="/company/list/detail/:companyId?"
            element={<CompanyDetail />}
          />
          <Route
            path="/company/list/detail/edit/:companyId?"
            element={<CompanyEdit />}
          />
          <Route
            path="/company/list/detail/members/:companyId?"
            element={<CompanyMembers />}
          />
          <Route
            path="/"
            element={<Navigate to="/community/posts" replace />}
          />
          <Route path="*" element={<Layout>Not Found</Layout>} />
        </Routes>
      </Layout>
    </Layout>
  );
};

export default Router;
