import {
  DeleteOutlined,
  EditOutlined,
  HomeOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { gql, Reference } from '@apollo/client';
import {
  Button,
  Modal,
  notification,
  Tag,
  Typography,
  Descriptions,
  Space,
  Avatar,
  Image,
} from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useCompanyQuery, useDeleteCompanyMutation } from 'src/utils/client';
import env from 'src/utils/env';
import {
  businessNumberFormatter,
  companyTypeFormatter,
  contactFormatter,
  timeRangeFormatter,
} from 'src/utils/formatter';
gql`
  query places($first: Int!, $term: String!) {
    places(first: $first, term: $term) {
      contact
      coordinates {
        latitude
        longitude
      }
      name
      regionAddress
      roadAddress
    }
  }
`;

gql`
  query companyRecommendations {
    companyRecommendations {
      id
      title
      description
    }
  }
`;

gql`
  query companyFacilities {
    companyFacilities {
      id
      name
    }
  }
`;

gql`
  query companySpecialties {
    companySpecialties {
      id
      name
    }
  }
`;

gql`
  query company($companyId: ID!) {
    company(companyId: $companyId) {
      id
      name
      type
      operatingTime {
        hoursByDay {
          MON {
            from {
              hour
              minute
              second
            }
            to {
              hour
              minute
              second
            }
          }
          TUE {
            from {
              hour
              minute
              second
            }
            to {
              hour
              minute
              second
            }
          }
          WED {
            from {
              hour
              minute
              second
            }
            to {
              hour
              minute
              second
            }
          }
          TUR {
            from {
              hour
              minute
              second
            }
            to {
              hour
              minute
              second
            }
          }
          FRI {
            from {
              hour
              minute
              second
            }
            to {
              hour
              minute
              second
            }
          }
          SAT {
            from {
              hour
              minute
              second
            }
            to {
              hour
              minute
              second
            }
          }
          SUN {
            from {
              hour
              minute
              second
            }
            to {
              hour
              minute
              second
            }
          }
        }
        remark
        closesOnHoliday
      }
      address {
        road
        region
        detail
        coordinates {
          latitude
          longitude
        }
      }
      specialties {
        id
        name
      }
      contact
      certificated
      extraContact
      images {
        url
      }
      recommendations {
        id
        title
        description
      }
      bio
      facilities {
        id
        name
      }
      businessRepresentativeName
      businessRegistrationNumber
      memo
      isPartner
      createdAt
      handleNationality
      lastEditedAt
      lastEditor {
        id
        email
        phone
        profile {
          avatarImage {
            url
          }
          nickname
        }
      }
      members {
        id
        account {
          id
          phone
          email
          profile {
            nickname
            avatarImage {
              url
            }
          }
        }
        role
      }
    }
  }
`;

gql`
  mutation deleteCompany($companyId: ID!) {
    deleteCompany(companyId: $companyId) {
      ... on DeleteCompanySuccess {
        company {
          id
        }
      }
    }
  }
`;

const { Title, Text } = Typography;

const CompanyDetail: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const navigate = useNavigate();
  const { data } = useCompanyQuery({
    variables: {
      // @ts-ignore apollo client bug
      companyId,
    },
    skip: !companyId,
  });

  const [deleteCompanyMutation] = useDeleteCompanyMutation({
    update(cache, result) {
      if (result.data?.deleteCompany.__typename === 'DeleteCompanySuccess') {
        const companyId = result.data.deleteCompany.company.id;
        cache.modify({
          fields: {
            companies(existingCompanyRefs, { readField }) {
              return existingCompanyRefs.filter(
                (companyRef: Reference) =>
                  companyId !== readField('id', companyRef)
              );
            },
          },
        });
      }
    },
  });

  const handleClickDelete = () => {
    Modal.warning({
      title: '정비소를 삭제하시겠어요?',
      content: '작성 내용은 저장되지 않습니다.',
      type: 'warn',
      onOk: async () => {
        if (!companyId) {
          return;
        }
        const { data } = await deleteCompanyMutation({
          variables: {
            companyId,
          },
        });
        if (data?.deleteCompany.__typename === 'DeleteCompanySuccess') {
          notification.success({
            message: '정비소가 성공적으로 삭제되었습니다.',
          });
          navigate('/company/list');
        }
      },
      closable: true,
    });
  };

  const dayoff = React.useMemo(() => {
    if (!data?.company?.operatingTime) {
      return '';
    }

    let result = '';

    if (!data.company.operatingTime.hoursByDay.MON) {
      result += '월요일, ';
    }

    if (!data.company.operatingTime.hoursByDay.TUE) {
      result += '화요일, ';
    }

    if (!data.company.operatingTime.hoursByDay.WED) {
      result += '수요일, ';
    }

    if (!data.company.operatingTime.hoursByDay.TUR) {
      result += '목요일, ';
    }

    if (!data.company.operatingTime.hoursByDay.FRI) {
      result += '금요일, ';
    }

    if (!data.company.operatingTime.hoursByDay.SAT) {
      result += '토요일, ';
    }

    if (!data.company.operatingTime.hoursByDay.SUN) {
      result += '일요일, ';
    }

    if (data.company.operatingTime.closesOnHoliday) {
      result += '공휴일, ';
    }

    return `${result.slice(0, -2)} 휴무`;
  }, [data]);

  return (
    <Container>
      {data?.company && (
        <Space style={{ width: '100%' }} direction="vertical" size="large">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <TitleBox>
              <Title style={{ margin: 0 }} level={3}>
                {data.company.name}
              </Title>
              <a
                href={`${env.WEB_URL}/company/${companyId}`}
                target="_blank"
                rel="noreferrer"
              >
                <HomeOutlined style={{ fontSize: '24px' }} />
              </a>
            </TitleBox>
            <ActionsBox>
              <Button
                danger
                icon={<DeleteOutlined />}
                size="large"
                onClick={handleClickDelete}
              >
                업체 삭제하기
              </Button>
              <Button
                icon={<EditOutlined />}
                size="large"
                onClick={() =>
                  navigate(`/company/list/detail/edit/${companyId}`)
                }
              >
                업체 편집하기
              </Button>

              <Button
                icon={<UsergroupAddOutlined />}
                size="large"
                onClick={() =>
                  navigate(`/company/list/detail/members/${companyId}`)
                }
              >
                업체 구성원 편집하기
              </Button>
            </ActionsBox>
          </div>
          <Descriptions
            layout="horizontal"
            title="기본정보"
            bordered
            column={{ xxl: 3, xl: 2, sm: 1 }}
          >
            <Descriptions.Item label="업체명">
              <div>
                {data.company.isPartner && <Tag color="blue">파트너</Tag>}
                {data.company.certificated && <Tag color="purple">인증</Tag>}
                <Text copyable>{data.company.name}</Text>
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="도로명 주소">
              {data.company.address.road && (
                <Text copyable>{data.company.address.road}</Text>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="업체ID">
              <Text copyable>{data.company.id}</Text>
            </Descriptions.Item>
            <Descriptions.Item label="연락처">
              {data.company.contact && (
                <div>
                  <Text copyable>{contactFormatter(data.company.contact)}</Text>
                </div>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="추가 연락처">
              {data.company.extraContact && (
                <Text>{contactFormatter(data.company.extraContact)}</Text>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="업체 유형">
              <Tag>{companyTypeFormatter(data.company.type)}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="전문 분야">
              {data.company.specialties.length > 0 && (
                <TagsBox>
                  {data.company.specialties.map((specialty) => (
                    <Tag key={specialty.name}>{specialty.name}</Tag>
                  ))}
                </TagsBox>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="영업 시간">
              {data.company.operatingTime && (
                <TimeTableBox>
                  {data.company.operatingTime.hoursByDay.MON && (
                    <span>{`월: ${timeRangeFormatter(
                      data.company.operatingTime.hoursByDay.MON
                    )}`}</span>
                  )}
                  {data.company.operatingTime.hoursByDay.TUE && (
                    <span>{`화: ${timeRangeFormatter(
                      data.company.operatingTime.hoursByDay.TUE
                    )}`}</span>
                  )}
                  {data.company.operatingTime.hoursByDay.WED && (
                    <span>{`수: ${timeRangeFormatter(
                      data.company.operatingTime.hoursByDay.WED
                    )}`}</span>
                  )}
                  {data.company.operatingTime.hoursByDay.TUR && (
                    <span>{`목: ${timeRangeFormatter(
                      data.company.operatingTime.hoursByDay.TUR
                    )}`}</span>
                  )}
                  {data.company.operatingTime.hoursByDay.FRI && (
                    <span>{`금: ${timeRangeFormatter(
                      data.company.operatingTime.hoursByDay.FRI
                    )}`}</span>
                  )}
                  {data.company.operatingTime.hoursByDay.SAT && (
                    <span>{`토: ${timeRangeFormatter(
                      data.company.operatingTime.hoursByDay.SAT
                    )}`}</span>
                  )}
                  {data.company.operatingTime.hoursByDay.SUN && (
                    <span>{`일: ${timeRangeFormatter(
                      data.company.operatingTime.hoursByDay.SUN
                    )}`}</span>
                  )}

                  {dayoff.length > 0 ? <span>{dayoff}</span> : null}
                </TimeTableBox>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="업체 사진">
              {data.company.images.length > 0 && (
                <Image.PreviewGroup>
                  {data.company.images.map((image, index) => (
                    <Image
                      key={index}
                      width={100}
                      height={100}
                      style={{
                        objectFit: 'cover',
                      }}
                      src={image.url}
                    />
                  ))}
                </Image.PreviewGroup>
              )}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            layout="horizontal"
            title="상세정보"
            bordered
            column={{ xxl: 3, xl: 2, sm: 1 }}
          >
            <Descriptions.Item span={3} label="닥터차 추천이유">
              {data.company.recommendations.length > 0 && (
                <ul>
                  {data.company.recommendations.map((recommendation, index) => (
                    <li key={index}>{recommendation.description}</li>
                  ))}
                </ul>
              )}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="업체 소개">
              {data.company.bio && (
                <CompanyDescriptionText>
                  {data.company.bio}
                </CompanyDescriptionText>
              )}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="편의시설 및 서비스">
              {data.company.facilities.length > 0 && (
                <TimeTableBox style={{ paddingTop: '6px' }}>
                  {data.company.facilities.map((facility, index) => (
                    <span key={index}>{facility.name}</span>
                  ))}
                </TimeTableBox>
              )}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="구성원 목록">
              <Space direction="vertical">
                {data.company.members?.map((member) => (
                  <Space key={member.id}>
                    <Avatar
                      icon={<UserOutlined />}
                      src={member.account?.profile.avatarImage?.url?.concat(
                        '?',
                        'w=144'
                      )}
                    />
                    <div>
                      <div>
                        <Text strong>{member.account?.profile.nickname}</Text>
                      </div>
                      <Space>
                        <Text copyable={!!member.account?.phone}>
                          {member.account?.phone || '(휴대폰 미등록)'}
                        </Text>
                        <Text copyable={!!member.account?.email}>
                          {member.account?.email || '(이메일 미등록)'}
                        </Text>
                      </Space>
                    </div>
                  </Space>
                ))}
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label="대표자명">
              {data.company.businessRepresentativeName && (
                <Text>{data.company.businessRepresentativeName}</Text>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="사업자등록번호">
              {data.company.businessRegistrationNumber && (
                <Text copyable>
                  {businessNumberFormatter(
                    data.company.businessRegistrationNumber
                  )}
                </Text>
              )}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            layout="horizontal"
            title="메카닉 전용 정보"
            bordered
            column={1}
          >
            <Descriptions.Item label="메모">
              {data.company.memo && <Text>{data.company.memo}</Text>}
            </Descriptions.Item>
            <Descriptions.Item label="등록일">
              {dayjs(data.company.createdAt).format('YYYY. MM. DD A hh:mm')}
            </Descriptions.Item>
            <Descriptions.Item label="마지막 편집일">
              {data.company.lastEditedAt
                ? dayjs(data.company.lastEditedAt).format(
                    'YYYY. MM. DD A hh:mm'
                  )
                : '-'}
            </Descriptions.Item>
            <Descriptions.Item label="마지막 편집자">
              {data.company.lastEditor ? (
                <Space>
                  <Avatar
                    icon={<UserOutlined />}
                    src={data.company.lastEditor?.profile.avatarImage?.url?.concat(
                      '?',
                      'w=144'
                    )}
                  />
                  <div>
                    <div>
                      <Text strong>
                        {data.company.lastEditor?.profile.nickname}
                      </Text>
                    </div>
                    <Space>
                      <Text copyable={!!data.company.lastEditor.phone}>
                        {data.company.lastEditor?.phone || '(번호없음)'}
                      </Text>
                      <Text copyable={!!data.company.lastEditor.email}>
                        {data.company.lastEditor?.email || '(이메일 없음)'}
                      </Text>
                    </Space>
                  </div>
                </Space>
              ) : (
                '-'
              )}
            </Descriptions.Item>
          </Descriptions>
        </Space>
      )}
    </Container>
  );
};

export default CompanyDetail;

const Container = styled.div`
  padding: 24px;
  flex: 1;
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

const ActionsBox = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

const TimeTableBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const CompanyDescriptionText = styled.div`
  white-space: pre-line;
`;

const TagsBox = styled.div`
  display: flex;
  gap: 6px;
`;
