import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Typography, notification } from 'antd';
import React from 'react';
import styled from 'styled-components';

import app from '../../../package.json';
import { ReactComponent as LobbyImage } from '../../assets/image/loginlobby.svg';

import logo from 'src/assets/image/logo.png';

interface AuthFormInputs {
  email: string;
  password: string;
}
type AuthHomePresenterProps = {
  login: (email: string, password: string) => Promise<void>;
};

const { Title, Text } = Typography;

const AuthHomePresenter: React.FC<AuthHomePresenterProps> = ({ login }) => {
  const [form] = Form.useForm<AuthFormInputs>();

  const onLogin = async ({ email, password }: AuthFormInputs) => {
    try {
      await login(email, password);
    } catch (error: any) {
      notification.error({
        message: '로그인 실패',
        description: error?.message,
      });
    }
  };

  return (
    <SContainer>
      <SLogin>
        <SBody>
          <Text disabled>Ver {app.version}</Text>
          <SLogo src={logo} alt="닥터차 로고" />
          <Title style={{ marginBottom: 40 }}>
            닥터차
            <br />
            커뮤니티 콘솔
          </Title>
          <Form form={form} onFinish={onLogin}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: '이메일 주소를 입력해주세요!' },
              ]}
            >
              <Input
                type="email"
                placeholder="이메일 주소"
                prefix={<UserOutlined style={{ color: '#1890FF' }} />}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: '비밀번호를 입력해주세요!' }]}
            >
              <Input
                type="password"
                placeholder="비밀번호"
                prefix={<LockOutlined style={{ color: '#1890FF' }} />}
              />
            </Form.Item>
            <Form.Item>
              <Button block type="primary" htmlType="submit">
                로그인
              </Button>
            </Form.Item>
          </Form>
        </SBody>
        <SFooter>
          Copyright ©{new Date().getFullYear()} Produced by Autopedia
        </SFooter>
      </SLogin>
      <LobbyImage
        style={{
          flex: 1,
          objectFit: 'fill',
          height: '100%',
        }}
      />
    </SContainer>
  );
};

const SContainer = styled.div`
  height: 100vh;
  display: flex;
`;
const SLogin = styled.div`
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 35px;
`;

const SBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const SLogo = styled.img`
  width: 87px;
  height: 48px;
  margin-top: 80px;
  margin-bottom: 30px;
`;
const SFooter = styled.span`
  align-self: center;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 50px;
`;

export default AuthHomePresenter;
