import React from 'react';
import { createRoot } from 'react-dom/client';

import './index.css';
import App from './App';

import { GlobalProvider } from 'src/utils/providers';

const container = document.getElementById('root');
if (container) {
  createRoot(container).render(
    <GlobalProvider>
      <App />
    </GlobalProvider>
  );
}
