import {
  CrownOutlined,
  LeftOutlined,
  PlusOutlined,
  SearchOutlined,
  SwapOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { gql } from '@apollo/client';
import {
  Avatar,
  Button,
  Divider,
  Input,
  List,
  Modal,
  notification,
  Popconfirm,
  Space,
  Tag,
  Typography,
} from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  CompanyMemberDocument,
  CompanyMemberRoleEnum,
  useAccountsLazyQuery,
  useAddMemberToCompanyMutation,
  useChangeCompanyMemberRoleMutation,
  useCompanyMemberQuery,
  useMakeBizAccountMutation,
  useRemoveMemberFromCompanyMutation,
} from 'src/utils/client';

gql`
  query CompanyMember($companyId: ID!) {
    company(companyId: $companyId) {
      id
      members {
        id
        account {
          id
          email
          phone
          profile {
            nickname
            avatarImage {
              url
            }
          }
        }
        role
      }
    }
  }

  query accounts($filter: AccountsFilter!, $limit: Int!, $offset: Int!) {
    accounts(filter: $filter, limit: $limit, offset: $offset) {
      id
      email
      phone
      profile {
        nickname
        avatarImage {
          url
        }
      }
    }
  }

  mutation makeBizAccount($accountId: ID!) {
    makeBizAccount(accountId: $accountId) {
      ... on MakeBizAccountSuccess {
        account {
          id
        }
      }
      ... on MakeBizAccountFail {
        error {
          message
        }
      }
    }
  }

  mutation addMemberToCompany(
    $accountId: ID!
    $companyId: ID!
    $role: CompanyMemberRoleEnum!
  ) {
    addMemberToCompany(
      accountId: $accountId
      companyId: $companyId
      role: $role
    ) {
      ... on AddMemberToCompanyFail {
        error {
          message
        }
      }
      ... on AddMemberToCompanySuccess {
        member {
          id
        }
      }
    }
  }

  mutation ChangeCompanyMemberRole(
    $memberId: ID!
    $role: CompanyMemberRoleEnum!
  ) {
    changeCompanyMemberRole(memberId: $memberId, role: $role) {
      ... on ChangeCompanyMemberRoleFail {
        error {
          message
        }
      }
      ... on ChangeCompanyMemberRoleSuccess {
        member {
          id
          role
        }
      }
    }
  }

  mutation removeMemberFromCompany($memberId: ID!) {
    removeMemberFromCompany(memberId: $memberId) {
      ... on RemoveMemberFromCompanyFail {
        error {
          message
        }
      }
      ... on RemoveMemberFromCompanySuccess {
        member {
          id
        }
      }
    }
  }
`;

const { Text } = Typography;

const MembersPage = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { data, loading } = useCompanyMemberQuery({
    variables: {
      // @ts-ignore apollo client bug
      companyId,
    },
    skip: !companyId,
  });
  const [accountsQuery, { data: accountsData, loading: accountsLoading }] =
    useAccountsLazyQuery();

  const [makeBizAccount] = useMakeBizAccountMutation();
  const [addMemberToCompany] = useAddMemberToCompanyMutation();
  const [changeCompanyMemberRole] = useChangeCompanyMemberRoleMutation();
  const [removeMemberFromCompany] = useRemoveMemberFromCompanyMutation();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [searchEmail, setSearchEmail] = React.useState('');
  const [searchNickname, setSearchNickname] = React.useState('');
  const [searchPhone, setSearchPhone] = React.useState('');
  const navigate = useNavigate();

  return (
    <Container>
      <List
        loading={loading}
        dataSource={data?.company?.members}
        header={
          <Space>
            <Button
              size="large"
              icon={<LeftOutlined />}
              type="text"
              onClick={() => {
                navigate(-1);
              }}
            />
            <Text strong>업체 구성원 관리</Text>
          </Space>
        }
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                type="ghost"
                key={item.id}
                icon={<SwapOutlined />}
                onClick={() => {
                  changeCompanyMemberRole({
                    variables: {
                      memberId: item.id,
                      role:
                        item.role === CompanyMemberRoleEnum.Manager
                          ? CompanyMemberRoleEnum.Staff
                          : CompanyMemberRoleEnum.Manager,
                    },
                    onError: (error) => {
                      notification.error({
                        message: '멤버 권한 변경 실패',
                        description: error.message,
                      });
                    },
                    onCompleted: (data) => {
                      if (
                        data.changeCompanyMemberRole.__typename ===
                        'ChangeCompanyMemberRoleSuccess'
                      ) {
                        notification.success({
                          message: '멤버 권한 변경 성공',
                        });
                      } else if (
                        data.changeCompanyMemberRole.__typename ===
                        'ChangeCompanyMemberRoleFail'
                      ) {
                        notification.error({
                          message: '멤버 권한 변경 실패',
                          description:
                            data.changeCompanyMemberRole.error.message,
                        });
                      }
                    },
                  });
                }}
              >
                {item.role === CompanyMemberRoleEnum.Manager
                  ? CompanyMemberRoleEnum.Staff
                  : CompanyMemberRoleEnum.Manager}
                로 변경
              </Button>,
              <Popconfirm
                placement="right"
                key={item.id}
                title="정말로 구성원을 제거하시겠습니까?"
                okButtonProps={{ danger: true }}
                onConfirm={() => {
                  removeMemberFromCompany({
                    variables: {
                      memberId: item.id,
                    },
                    onError: (error) => {
                      notification.error({
                        message: '멤버 제거 실패',
                        description: error.message,
                      });
                    },
                    onCompleted: (data) => {
                      if (
                        data.removeMemberFromCompany.__typename ===
                        'RemoveMemberFromCompanySuccess'
                      ) {
                        notification.success({
                          message: '멤버 제거 성공',
                        });
                      } else if (
                        data.removeMemberFromCompany.__typename ===
                        'RemoveMemberFromCompanyFail'
                      ) {
                        notification.error({
                          message: '멤버 제거 실패',
                          description: JSON.stringify(
                            data.removeMemberFromCompany.error
                          ),
                        });
                      }
                    },
                    update: (cache) => {
                      cache.modify({
                        id: cache.identify({
                          __typename: 'Company',
                          id: companyId,
                        }),
                        fields: {
                          members(existingMembers = []) {
                            return existingMembers.filter(
                              (ref: any) =>
                                ref.__ref !== `CompanyMember:${item.id}`
                            );
                          },
                        },
                      });
                    },
                  });
                }}
              >
                <Button type="ghost" danger key={item.id}>
                  제거
                </Button>
              </Popconfirm>,
            ]}
          >
            <List.Item.Meta
              title={
                <Space>
                  <Tag
                    color={
                      item.role === CompanyMemberRoleEnum.Manager
                        ? 'blue'
                        : undefined
                    }
                  >
                    {item.role}
                  </Tag>
                  {item.account?.profile?.nickname}{' '}
                  <Text disabled copyable>
                    {item.account?.id}
                  </Text>
                </Space>
              }
              description={
                <Space>
                  <Text copyable> {item.account?.email}</Text>{' '}
                  <Text copyable>{item.account?.phone}</Text>
                </Space>
              }
              avatar={
                <Avatar
                  size={48}
                  icon={<UserOutlined />}
                  src={
                    item.account?.profile?.avatarImage?.url
                      ? item.account.profile.avatarImage.url + '?w=144&h=144'
                      : undefined
                  }
                />
              }
            />
          </List.Item>
        )}
        footer={
          <Button
            size="large"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsModalVisible(true);
            }}
          >
            멤버 추가
          </Button>
        }
      />
      <Modal
        width={800}
        title="계정 찾기"
        open={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        <Typography.Paragraph>
          이메일 또는 닉네임 또는 전화번호로 계정을 찾으세요 (셋 중 하나라도
          만족하면 조회됩니다)
        </Typography.Paragraph>
        <List
          style={{ minHeight: 240 }}
          loading={accountsLoading}
          dataSource={accountsData?.accounts}
          header={
            <Input.Group
              style={{ display: 'flex', flexDirection: 'row' }}
              compact
            >
              <Input
                style={{ flex: 3 }}
                value={searchEmail}
                onChange={(e) => {
                  setSearchEmail(e.target.value);
                }}
                placeholder="example@autopediacar.com"
              />
              <Input
                style={{ flex: 2 }}
                placeholder="멋쟁이 닥터차"
                value={searchNickname}
                onChange={(e) => {
                  setSearchNickname(e.target.value);
                }}
              />
              <Input
                style={{ flex: 2 }}
                placeholder="01092231793"
                value={searchPhone}
                onChange={(e) => {
                  setSearchPhone(e.target.value);
                }}
              />
              <Button
                icon={<SearchOutlined />}
                onClick={() => {
                  accountsQuery({
                    variables: {
                      filter: {
                        email: searchEmail || undefined,
                        nickname: searchNickname || undefined,
                        phone: searchPhone || undefined,
                      },
                      limit: 10,
                      offset: 0,
                    },
                    onError: (e) => {
                      notification.error({
                        message: '계정 찾기 실패',
                        description: e.message,
                      });
                    },
                  });
                }}
              >
                검색
              </Button>
            </Input.Group>
          }
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  key={item.id}
                  type="ghost"
                  onClick={async () => {
                    if (!companyId) {
                      notification.error({
                        message: '업체 아이디가 없습니다',
                      });
                      return;
                    }
                    await makeBizAccount({
                      variables: {
                        accountId: item.id,
                      },
                      onError: (error) => {
                        notification.error({
                          message: '비즈 계정으로 전환 실패',
                          description: error.message,
                        });
                      },
                    });
                    await addMemberToCompany({
                      variables: {
                        accountId: item.id,
                        companyId,
                        role: CompanyMemberRoleEnum.Staff,
                      },
                      onError: (error) => {
                        notification.error({
                          message: '업체에 일반 직원 추가 실패',
                          description: error.message,
                        });
                      },
                      onCompleted: (data) => {
                        if (
                          data.addMemberToCompany.__typename ===
                          'AddMemberToCompanyFail'
                        ) {
                          notification.error({
                            message: '업체에 일반 직원 추가 실패',
                            description: data.addMemberToCompany.error.message,
                          });
                        } else if (
                          data.addMemberToCompany.__typename ===
                          'AddMemberToCompanySuccess'
                        ) {
                          notification.success({
                            message: '업체에 일반 직원 추가 성공',
                            description: `${item.email} 님을 업체에 일반 직원으로 추가했습니다.`,
                          });
                          setIsModalVisible(false);
                        }
                      },
                      refetchQueries: [
                        {
                          query: CompanyMemberDocument,
                          variables: {
                            companyId,
                          },
                        },
                      ],
                    });
                  }}
                >
                  스태프로 추가
                </Button>,
                <Button
                  key={item.id}
                  type="ghost"
                  icon={<CrownOutlined />}
                  onClick={async () => {
                    if (!companyId) {
                      notification.error({
                        message: '업체 아이디가 없습니다',
                      });
                      return;
                    }
                    await makeBizAccount({
                      variables: {
                        accountId: item.id,
                      },
                      onError: (error) => {
                        notification.error({
                          message: '비즈 계정으로 전환 실패',
                          description: error.message,
                        });
                      },
                    });
                    await addMemberToCompany({
                      variables: {
                        accountId: item.id,
                        companyId,
                        role: CompanyMemberRoleEnum.Manager,
                      },
                      onError: (error) => {
                        notification.error({
                          message: '업체에 매니저 추가 실패',
                          description: error.message,
                        });
                      },
                      onCompleted: (data) => {
                        if (
                          data.addMemberToCompany.__typename ===
                          'AddMemberToCompanyFail'
                        ) {
                          notification.error({
                            message: '업체에 매니저 추가 실패',
                            description: data.addMemberToCompany.error.message,
                          });
                        } else if (
                          data.addMemberToCompany.__typename ===
                          'AddMemberToCompanySuccess'
                        ) {
                          notification.success({
                            message: '업체에 매니저 추가 성공',
                            description: `${item.email} 님을 업체에 매니저로 추가했습니다.`,
                          });
                          setIsModalVisible(false);
                        }
                      },
                      refetchQueries: [
                        {
                          query: CompanyMemberDocument,
                          variables: {
                            companyId,
                          },
                        },
                      ],
                    });
                  }}
                >
                  매니저로 추가
                </Button>,
              ]}
            >
              <List.Item.Meta
                title={
                  <Space>
                    {item.profile?.nickname}
                    <Text disabled copyable>
                      {item.id}
                    </Text>
                  </Space>
                }
                description={
                  <Space split={<Divider type="vertical" />} size="small">
                    <span>{item.email}</span>
                    <span>{item.phone}</span>
                  </Space>
                }
                avatar={
                  <Avatar
                    icon={<UserOutlined />}
                    src={
                      item.profile?.avatarImage?.url
                        ? item.profile.avatarImage.url + '?w=144&h=144'
                        : undefined
                    }
                  />
                }
              />
            </List.Item>
          )}
        />
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  padding: 24px;
  flex: 1;
  overflow-y: auto;
`;

export default MembersPage;
