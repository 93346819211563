import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import CommentManager from 'src/components/shared/CommentManager';
import PostIssues from 'src/components/tabs/PostIssues';
import { PostIssuesOrder, usePostIssuesQuery } from 'src/utils/client';
import { postsFilterToQueryString } from 'src/utils/formatter';

const Bookmarks = () => {
  const { page } = useParams<{ page: string | undefined }>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const term = searchParams.get('term');
  const categoryId = searchParams.get('categoryId');
  const from = searchParams.get('from');
  const to = searchParams.get('to');
  const sort = searchParams.get('sort');
  const [openedPostId, setOpenedPostId] = React.useState<string | null>(null);
  const [pageLimit, setPageLimit] = React.useState(10);

  const offset = page ? (Number(page) - 1) * pageLimit : 0;

  const { data } = usePostIssuesQuery({
    variables: {
      limit: pageLimit,
      filter: {
        bookmarked: true,
        term: term ? (term as string) : undefined,
        categoryIds: categoryId ? ([categoryId] as string[]) : undefined,
        createdAtRange:
          from && to
            ? {
                from: Number(from),
                to: Number(to),
              }
            : undefined,
      },
      offset,
      orderBy: sort ? (sort as PostIssuesOrder) : PostIssuesOrder.CreatedAtDesc,
    },
    skip: !page,
  });

  React.useEffect(() => {
    if (!page) {
      navigate('/community/bookmarks/1');
    }
  }, [navigate, page]);

  const handleClickCard = (clickedPostId: string) => {
    if (clickedPostId === openedPostId) {
      setOpenedPostId(null);
    } else {
      setOpenedPostId(clickedPostId);
    }
  };

  const handleChangePage = (page: number) => {
    let url = `/community/bookmarks/${page}`;

    const updatedFilter = {
      term: term ? (term as string) : undefined,
      categoryId: categoryId ? (categoryId as string) : undefined,
      createdAtRange:
        from && to
          ? {
              from: Number(from),
              to: Number(to),
            }
          : undefined,
      orderBy: sort as PostIssuesOrder,
    };

    url += `?${postsFilterToQueryString(updatedFilter)}`;
    navigate(url);
  };

  const handleSearchTerm = (termInput: string) => {
    const updatedFilter = {
      term: termInput,
      categoryId: categoryId ? (categoryId as string) : undefined,
      createdAtRange:
        from && to
          ? {
              from: Number(from),
              to: Number(to),
            }
          : undefined,
      orderBy: sort as PostIssuesOrder,
    };

    navigate(
      `/community/bookmarks/1?${postsFilterToQueryString(updatedFilter)}`
    );
  };

  const postIssueIds = data?.postIssues.map((postIssue) => postIssue.id) || [];

  const handleClose = () => {
    setOpenedPostId(null);
  };

  return (
    <Container>
      <ListBox replyOpened={openedPostId !== null}>
        <PostIssues
          key={'bookmarks'}
          bookmarked
          postIssueIds={postIssueIds}
          openedPostIssueId={openedPostId}
          onClickCard={handleClickCard}
          onPageChange={handleChangePage}
          onSearch={handleSearchTerm}
          onChangePageLimit={setPageLimit}
          onClose={handleClose}
        />
      </ListBox>
      {openedPostId && (
        <ReplyBox>
          <CommentManager
            key={openedPostId}
            onClose={handleClose}
            postIssueId={openedPostId}
          />
        </ReplyBox>
      )}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  overflow-y: hidden;
  display: flex;
`;

const ListBox = styled.div<{ replyOpened: boolean }>`
  flex: 0 0 ${(props) => (props.replyOpened ? '50%' : '100%')};
  transition: flex 500ms;
  display: flex;
`;

const ReplyBox = styled.div`
  flex: 0 0 50%;
  z-index: 99;
`;

export default Bookmarks;
