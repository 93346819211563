import { gql } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import ReadablePostCard from '../cards/ReadablePostCard';

import { useUserQuery } from 'src/utils/client';

gql`
  query user($userId: ID!, $before: String, $last: Int = 20) {
    user(userId: $userId) {
      id
      postConnection(before: $before, last: $last) {
        edges {
          cursor
          node {
            id
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  }
`;

type UserPostHistoryModalProps = {
  userId: string;
};

const UserPostHistoryModal: React.FC<UserPostHistoryModalProps> = ({
  userId,
}) => {
  const { data } = useUserQuery({
    variables: {
      userId,
    },
  });

  const postIds =
    data?.user?.postConnection?.edges?.map((edge) => edge.node.id) || [];

  return (
    <Container>
      {postIds.map((postId) => {
        return <ReadablePostCard key={postId} postId={postId} />;
      })}
    </Container>
  );
};

export default UserPostHistoryModal;

const Container = styled.div`
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  gap: 12px;
`;
